import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/outline';
import ReactSelect from 'react-select'; // Import ReactSelect
import { ToastContainer } from 'react-toastify';
import { toast } from 'react-toastify'; // Moved up
import 'react-toastify/dist/ReactToastify.css'; // Moved down

const Postalcode = () => {
  const [postalcodeName, setPostalcodeName] = useState('');
  const [cityId, setcityId] = useState('');
  const [citys, setcitys] = useState([]);
  const [stateId, setstateId] = useState('');
  const [stateName, setStateName] = useState('');
  const [countries, setcountries] = useState([]);
  const [filteredcitys, setFilteredcitys] = useState([]);
  const [Postalcodes, setPostalcodes] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [showEditPopup, setShowEditPopup] = useState(false);
  const [editItemId, setEditItemId] = useState(null);
  const [editItemName, setEditItemName] = useState('');
  const itemsPerPage = 5;
  const [key, setKey] = useState(0);
  const navigate = useNavigate();
  const token = localStorage.getItem('token');
  const config=require('../../config')
  useEffect(() => {
    fetchcitys(); // Fetch initial data when component mounts
    fetchPostalcodes(); 
  }, []);

  const fetchcitys = async () => {
    try {
      const response = await fetch(config.backendDomain+'/city', {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const data = await response.json();
      setcitys(data);
      setFilteredcitys(data);
      
      // Fetch countries based on citys
      const stateResponse = await fetch(config.backendDomain+'/state', {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const stateData = await stateResponse.json();
      setcountries(stateData);
    } catch (error) {
      console.error('Error fetching citys and countries:', error);
    }
  };

  const handlecitySearch = (searchTerm) => {
    const filtered = citys.filter((city) =>
      city.city_name.toLowerCase().startsWith(searchTerm.toLowerCase())
    );
    setFilteredcitys(filtered);
  };

  const fetchPostalcodes = async () => {
    try {
      const response = await fetch(config.backendDomain+'/Postalcode', {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const data = await response.json();
      // Sort the data by created_date in descending order
      const sortedData = data.sort((a, b) => new Date(b.created_date) - new Date(a.created_date));
      setPostalcodes(sortedData);
    } catch (error) {
      console.error('Error fetching Postalcodes:', error);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Validate that stateName is not empty
  if (!stateName) {
    showAlert('state name is required');
    return;
  }

  // Validate postalcode format (numeric and 6 digits)
  if (!/^\d{6}$/.test(postalcodeName)) {
    showAlert('Postal code must be numeric and have exactly 6 digits');
    return;
  }

  console.log('Request Body:', JSON.stringify({ postalcodeName, cityId, stateId, stateName }));

    try {
      const response = await fetch(config.backendDomain+'/Postalcode', {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ postalcodeName, cityId, stateId, stateName }), 
      });
      const data = await response.json();

      if (response.ok) {
        toast.success(data.message);
        setPostalcodeName('');
        setcityId('');
        setstateId('');
        setStateName('');
        fetchPostalcodes(); 
        // setKey((prevKey) => prevKey + 1);
        // Fetch latest data after successful submission
      } else {
        handleErrorResponse(response.status, data.message);
      }
    } catch (error) {
      console.error('Error creating Postalcode:', error);
      handleErrorResponse(500, 'An error occurred.'); // Internal server error
    }
  };

  const handleEdit = async (id, newName) => {
    try {
      const response = await fetch(config.backendDomain+`/Postalcode/${id}`, {
        method: 'PUT',
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ postalcodeName: newName }),
      });
      const data = await response.json();

      if (response.ok) {
        toast.success(data.message);
        fetchPostalcodes(); // Fetch latest data after successful edit
        handleCloseEditPopup();
      } else {
        handleErrorResponse(response.status, data.message);
      }
    } catch (error) {
      console.error('Error updating Postalcode:', error);
      handleErrorResponse(500, 'An error occurred.'); // Internal server error
    }
  };

  const handleDelete = async (id) => {
    try {
      const response = await fetch(config.backendDomain+`/Postalcode/${id}`, {
        method: 'DELETE',
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const data = await response.json();

      if (response.ok) {
        toast.success(data.message);
        fetchPostalcodes(); // Fetch latest data after successful deletion
      } else if (response.status === 400) {
        showAlert(data.message); // Show associated data error message
      } else {
        handleErrorResponse(response.status, data.message);
      }
    } catch (error) {
      console.error('Error deleting Postalcode:', error);
      handleErrorResponse(500, 'An error occurred.'); // Internal server error
    }
  };

  const handleErrorResponse = (status, message) => {
    if (status === 403) {
      showAlert('You are not authorized.');
    } else if (status === 400) {
      showAlert(message || 'Postalcode with the same name already exists');
    } else {
      showAlert('An error occurred.');
    }
  };

  const showAlert = (msg) => {
    toast.info(msg);
  };
  

  const handleOpenEditPopup = (id, newName) => {
    setEditItemId(id);
    setEditItemName(newName);
    setShowEditPopup(true);
  };

  const handleCloseEditPopup = () => {
    setEditItemId(null);
    setEditItemName('');
    setShowEditPopup(false);
  };

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = Postalcodes.slice(indexOfFirstItem, indexOfLastItem);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  return (
    <div key={key} className="relative bg-gray-100 animate__animated animate__fadeIn">
      <ToastContainer className="mt-14" /> 
      <div className="container mx-auto py-8 mt-0">
        <div className="relative bg-blue-50">
          <h2 className="shadow-lg text-xl h-10 font-bold bg-blue-500 text-white mb-6 pl-3 pt-1">Postalcode Gateway</h2>
          <form onSubmit={handleSubmit}>
            <div className="mb-4">
              <input
                type="text"
                id="postalcodeName"
                pattern="[a-zA-Z0-9 ]+"
                title="Please enter only letters, numbers, and spaces"
                className="shadow-lg w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                placeholder="Enter Postalcode Name"
                value={postalcodeName}
                onChange={(e) => setPostalcodeName(e.target.value)}
                required
              />
            </div>
            <div className="mb-4">
            <ReactSelect
                id="cityId"
                className=" shadow-lg text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                options={filteredcitys.map((city) => ({
                  value: city.city_id,
                  label: city.city_name,
                }))}
                value={filteredcitys.find((city) => city.city_name === cityId)}
                onChange={(selectedOption) => {
                  const selectedcity = citys.find((city) => city.city_id === selectedOption.value);
                  if (selectedcity) {
                    setcityId(selectedOption.value);
                    setstateId(selectedcity.state_id);
                    setStateName(selectedcity.state_name);
                  }
                }}
                placeholder="Select city"
              />
            </div>
            {cityId && ( // Render state select only if city is selected
              <div className="mb-4">
                <input
                  type="text"
                  id="stateName"
                  className="shadow-lg w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  placeholder="Select State"
                  value={stateName}
                  readOnly // Make the input read-only
                />
              </div>
            )}
            <button
              className="bg-blue-500 hover:bg-blue-700 focus:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
              type="submit"
            >
              Create Postalcode
            </button>
          </form>
          <table className="min-w-full divide-y divide-gray-200 mt-6">
            <thead className="bg-gray-200">
              <tr>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">ID</th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Pincode </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">city </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">State </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Region </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Zone </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Country </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Created Date</th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Modified Date</th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Actions</th>
              </tr>
            </thead>
            <tbody className="bg-white divide-y divide-gray-200">
              {currentItems.map((postalcode) => (
                <tr key={postalcode.postalcode_id}>
                  <td className="px-6 py-4 whitespace-nowrap text-xs">{postalcode.postalcode_id}</td>
                  <td className="px-6 py-4 whitespace-nowrap text-xs">{postalcode.postalcode_name}</td>
                  <td className="px-6 py-4 whitespace-nowrap text-xs">{postalcode.city_name}</td>
                  <td className="px-6 py-4 whitespace-nowrap text-xs">{postalcode.state_name}</td>
                  <td className="px-6 py-4 whitespace-nowrap text-xs">{postalcode.region_name}</td>
                  <td className="px-6 py-4 whitespace-nowrap text-xs">{postalcode.zone_name}</td>
                  <td className="px-6 py-4 whitespace-nowrap text-xs">{postalcode.country_name}</td>
                  <td className="px-6 py-4 whitespace-nowrap text-xs">{postalcode.created_date}</td>
                  <td className="px-6 py-4 whitespace-nowrap text-xs">{postalcode.modified_date}</td>
                  <td className="px-6 py-4 whitespace-nowrap text-xs">
                    <button
                      className="bg-yellow-500 hover:bg-yellow-700 text-white font-bold py-1 px-2 rounded focus:outline-none focus:shadow-outline mr-2"
                      onClick={() => handleOpenEditPopup(postalcode.postalcode_id, postalcode.postalcode_name)} 
                    >
                      Edit
                    </button>
                    <button
                      className="bg-red-500 hover:bg-red-700 text-white font-bold py-1 px-2 rounded focus:outline-none focus:shadow-outline"
                      onClick={() => handleDelete(postalcode.postalcode_id)}
                    >
                      Delete
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          <div className="flex justify-start items-center mt-4">
            <div>
              {currentPage > 1 && (
                <button
                  className="mr-2 bg-blue-500 hover:bg-blue-600 text-white font-bold py-1 px-2 rounded focus:outline-none focus:shadow-outline"
                  onClick={() => handlePageChange(currentPage - 1)}
                >
                  <ChevronLeftIcon className="h-5 w-5" />
                </button>
              )}
            </div>
            <div className="flex space-x-2">
              {[1, 2, 3, 4].map((page) => (
                <button
                  key={page}
                  className={`bg-blue-500 hover:bg-blue-600 text-white font-bold py-1 px-2 rounded focus:outline-none focus:shadow-outline ${
                    currentPage === page ? 'bg-orange-400' : ''
                  }`}
                  onClick={() => handlePageChange(page)}
                >
                  {page}
                </button>
              ))}
            </div>
            <div>
              {currentPage < Math.ceil(Postalcodes.length / itemsPerPage) && (
                <button
                  className="ml-2 bg-blue-500 hover:bg-blue-600 text-white font-bold py-1 px-2 rounded focus:outline-none focus:shadow-outline"
                  onClick={() => handlePageChange(currentPage + 1)}
                >
                  <ChevronRightIcon className="h-5 w-5" />
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
  
      {/* Edit Popup */}
      {showEditPopup && (
        <div className="fixed z-10 inset-0 overflow-y-auto flex items-center justify-center">
          <div className="fixed inset-0 bg-gray-500 opacity-75"></div>
          <div className="relative bg-white w-96 rounded-lg p-8">
            <h2 className="text-lg font-bold mb-4">Edit Postalcode Name</h2>
            <input
              type="text"
              value={editItemName}
              onChange={(e) => setEditItemName(e.target.value)}
              className="shadow-lg w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              placeholder="Enter New Postalcode Name"
            />
            <div className="mt-4 flex justify-end">
              <button
                onClick={() => handleEdit(editItemId, editItemName)}
                className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline mr-2"
              >
                Save Changes
              </button>
              <button
                onClick={handleCloseEditPopup}
                className="bg-gray-400 hover:bg-gray-600 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default Postalcode;
