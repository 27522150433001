import React, { useEffect, useState } from "react";
import { auth, provider } from "../App/config";
import { signInWithPopup } from "firebase/auth";
import { useNavigate, Navigate } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGoogle } from '@fortawesome/free-brands-svg-icons';
const config = require('../config')


function SignIn() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [value, setValue] = useState('');
  const navigate = useNavigate();

  const handleClick = () => {
    signInWithPopup(auth, provider).then((data) => {
      setValue(data.user.email);
      localStorage.setItem("email", data.user.email);
      checkEmailExistence(data.user.email);
    });
  };


  useEffect(() => {
    setValue(localStorage.getItem('email'));
  }, []);

  const checkEmailExistence = (email) => {

    fetch(config.backendDomain + "/googleloginbe", {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify({ email })
    })
      .then(response => response.json())
      .then(data => {
        if (data.message === 'true') {
          localStorage.setItem('token', data.token);
          // agar google email hai to redirect karega
          navigate('/dashboard');
        } else {
          // agar nahi to alert dega backend se
          alert(data.message);
        }
      })
      .catch(error => {
        console.error('Error:', error);

      });
  };

  const handleLogin = (e) => {
    e.preventDefault();
    console.log('data:', email)

    // Validation for empty fields
    if (!email || !password) {
      alert("Please fill the details in all input fields.");
      return;
    }

    fetch(config.backendDomain + "/loginbe", {
      method: "POST",
      headers: {
        'Content-type': "application/json"
      },
      body: JSON.stringify({ email, password })
    })
      .then(response => response.json())
      .then(data => {
        if (data.message === 'true') {
          localStorage.setItem('token', data.token);
          // email is valid, redirect
          navigate('/dashboard');
        } else {
          // invalid credentials
          alert(data.message);
        }
      })
      .catch(error => {
        console.error('Error:', error);
      });
  };


  return (
    <div className="container mx-auto p-8 bg-gradient-to-r from-blue-800 to-green-300">
      <nav className="fixed top-0 left-0 right-0 bg-white border-b-2 border-gray-200 shadow-lg z-50 py-4 hidden md:block">
        <div className="container mx-auto flex justify-between items-center px-8">
          <Link to="/">
            <img src="logo.png" alt="Logo.png" className="h-16 ml-8 " />
          </Link>
        </div>
      </nav>
      <div className="min-h-screen bg-gradient-to-r from-blue-800 to-green-300 flex items-center justify-center px-4 sm:px-0">
        <div className="max-w-xl w-full lg:flex shadow-lg mx-auto my-4 lg:my-8">
          <div className="lg:w-1/2 bg-white p-8 flex flex-col justify-center items-center">
            <h2 className="text-3xl font-bold text-gray-800 mb-4">Welcome!</h2>
            <p className="text-gray-600">Unlock efficiency with every login. Welcome to seamless Saas logistics management</p>
            <p className="mt-4 text-sm text-gray-600">Don't have an account? <a href="/SignupPage" className="font-medium text-indigo-600 hover:text-indigo-500">Register here</a></p>
          </div>
          <div className="lg:w-1/2 bg-indigo-100 p-8">
            <div className="mb-8">
              <h2 className="text-2xl font-bold mb-4">Login</h2>
              <form onSubmit={handleLogin} className="space-y-4">
                <div>
                  <label htmlFor="username" className="block text-sm font-medium text-gray-700">Username</label>
                  <input type="text" id="username" name="username" value={email} onChange={(e) => setEmail(e.target.value)} className="appearance-none rounded-none relative block w-full px-3 py-2 bg-white border border-gray-300 placeholder-gray-500 text-gray-900 rounded-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm" placeholder="Username" />
                </div>
                <div>
                  <label htmlFor="password" className="block text-sm font-medium text-gray-700">Password</label>
                  <input type="password" id="password" name="password" value={password} onChange={(e) => setPassword(e.target.value)} className="appearance-none rounded-none relative block w-full px-3 py-2 bg-white border border-gray-300 placeholder-gray-500 text-gray-900 rounded-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm" placeholder="Password" />
                </div>
                <div>
                  <button type="submit" className="w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">Log in</button>
                </div>
              </form>
            </div>
            <div>


              <button onClick={handleClick} className="w-full flex justify-center py-2 px-4 border border-gray-300 shadow-sm bg-white text-sm font-medium rounded-md text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500">
                <div className="flex items-center justify-center">
                  <FontAwesomeIcon icon={faGoogle} className="w-5 h-5 mr-2 text-red-500" />
                  Sign in with Google
                </div>
              </button>

            </div>
          </div>
        </div>
      </div>
    </div>
  );
}



export default SignIn;