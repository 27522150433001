import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/outline';
import ReactSelect from 'react-select'; // Import ReactSelect
import Loader from './Loader'; 
import { ToastContainer } from 'react-toastify';
import { toast } from 'react-toastify'; // Moved up
import 'react-toastify/dist/ReactToastify.css'; // Moved down
const config=require('../../config')
const TeamUser = () => {
  const [loading, setLoading] = useState(true);
  const [teamuserName, setTeamuserName] = useState('');
  const [teamusers, setTeamusers] = useState([]);
  const [branches, setBranches] = useState([]);
  const [filteredBranches, setFilteredBranches] = useState([]);
  const [parentBranchId, setParentBranchId] = useState('');  
  const [parentBranch, setParentBranch] = useState('');
  const [roles, setroles] = useState([]);
  const [filteredroles, setFilteredroles] = useState([]);
  const [roleId, setroleId] = useState('');  
  const [roleName, setRoleName] = useState('');
  const [comment, setComment] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [showEditPopup, setShowEditPopup] = useState(false);
  const [editItemId, setEditItemId] = useState(null);
  const [editItemName, setEditItemName] = useState('');
  const itemsPerPage = 5;
  const [key, setKey] = useState(0);
  const navigate = useNavigate();
  const token = localStorage.getItem('token');


  useEffect(() => {
    fetchBranches(); 
    fetchroles();
    fetchteamusers(); 
  }, []);

  const fetchBranches = async () => {
    try {
      const response = await fetch(config.backendDomain+'/parentbranch', {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const data = await response.json();
      setBranches(data);
      setFilteredBranches(data); // Initialize filtered data with all branches
    } catch (error) {
      console.error('Error fetching branches:', error);
    }
  };

  const handleBranchSearch = (searchTerm) => {
    const filtered = branches.filter((branch) =>
      branch.branch_name.toLowerCase().startsWith(searchTerm.toLowerCase())
    );
    console.log('Filtered Branches:', filtered); // Add this line for debugging
    setFilteredBranches(filtered);
  };

  const fetchroles = async () => {
    try {
      const response = await fetch(config.backendDomain+'/rolesearch', {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const data = await response.json();
      setroles(data);
      setFilteredroles(data);
    } catch (error) {
      console.error('Error fetching roles:', error);
    }
  };

  const handleroleSearch = (searchTerm) => {
    const filtered = roles.filter((role) =>
      role.role_name.toLowerCase().startsWith(searchTerm.toLowerCase())
    );
    setFilteredroles(filtered);
  };

  const fetchteamusers = async () => {
    try {
      setLoading(true);
      const response = await fetch(config.backendDomain+'/teamsearch', {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const data = await response.json();
      // Sort the data by created_date in descending order
      const sortedData = data.sort((a, b) => new Date(b.created_date) - new Date(a.created_date));
      setTeamusers(sortedData);
      setLoading(false); 
    } catch (error) {
      console.error('Error fetching Teams:', error);
      setLoading(false); 
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

  console.log('Request Body:', JSON.stringify({teamuserName, parentBranchId, parentBranch, roleId, roleName, comment}));

    try {
      setLoading(true);
      const response = await fetch(config.backendDomain+'/teams', {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({teamuserName, parentBranchId, parentBranch, roleId, roleName, comment}), 
      });
      const data = await response.json();

      if (response.ok) {
        toast.success(data.message);
        setTeamuserName('');         
        setroleId('');
        setRoleName('');
        setComment(''); 
        fetchteamusers();
        setParentBranchId('');
        setParentBranch('');
        setLoading(false); 
        // setKey((prevKey) => prevKey + 2);
        
        // Fetch latest data after successful submission
      } else {
        handleErrorResponse(response.status, data.message);
      }
    } catch (error) {
      console.error('Error creating Branch:', error);
      handleErrorResponse(500, 'An error occurred.'); // Internal server error
    }
  };

  const handleEdit = async (id, newName) => {
    try {
      const response = await fetch(config.backendDomain+`/teams/${id}`, {
        method: 'PUT',
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ branchName: newName }),
      });
      const data = await response.json();

      if (response.ok) {
        toast.success(data.message);
        fetchteamusers(); // Fetch latest data after successful edit
        handleCloseEditPopup();
      } else {
        handleErrorResponse(response.status, data.message);
      }
    } catch (error) {
      console.error('Error updating Branch:', error);
      handleErrorResponse(500, 'An error occurred.'); // Internal server error
    }
  };

  const handleDelete = async (id) => {
    try {
      const response = await fetch(config.backendDomain+`/teams/${id}`, {
        method: 'DELETE',
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const data = await response.json();

      if (response.ok) {
        toast.success(data.message);
        fetchteamusers(); // Fetch latest data after successful deletion
      } else {
        handleErrorResponse(response.status, data.message);
      }
    } catch (error) {
      console.error('Error deleting Branch:', error);
      handleErrorResponse(500, 'An error occurred.'); // Internal server error
    }
  };

  const handleErrorResponse = (status, message) => {
    if (status === 403) {
      showAlert('You are not authorized.');
    } else if (status === 400) {
      showAlert(message || 'Branch with the same name already exists');
    } else {
      showAlert('An error occurred.');
    }
  };

  const showAlert = (msg) => {
    toast.info(msg);
  };
  

  const handleOpenEditPopup = (id, newName) => {
    setEditItemId(id);
    setEditItemName(newName);
    setShowEditPopup(true);
  };

  const handleCloseEditPopup = () => {
    setEditItemId(null);
    setEditItemName('');
    setShowEditPopup(false);
  };

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = teamusers.slice(indexOfFirstItem, indexOfLastItem);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  return (
    <div key={key} className="relative bg-gray-100 animate__animated animate__fadeIn">
         
   {loading && <Loader />}
   
      <div className="container mx-auto py-8 mt-0">
      <ToastContainer className="mt-14"/> 
      
        <div className="relative bg-blue-50">
        <h2 className="w-[1245px] ml-0 shadow-lg text-xl h-10 font-bold bg-blue-500 text-white mb-6 pl-3 pt-1 ">Invite Team Member</h2>
          <form onSubmit={handleSubmit}>
                       
           <div className="mb-4">
            <input
              type="email"
              id="email"
              className="shadow-lg w-[1245px] ml-0 py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              placeholder="Enter Email"
              value={teamuserName}
              onChange={(e) => setTeamuserName(e.target.value)}
              required
            />
          </div>

           <div className="mb-4">
            <ReactSelect
              id="parentBranchId"
              className=" shadow-lg text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              options={filteredBranches.map((branch) => ({
                value: branch.branch_id,
                label: branch.branch_name,
              }))}
              value={filteredBranches.find((branch) => branch.branch_name === parentBranchId)}
              onChange={(selectedOption) => {
                const selectedBranch = branches.find((branch) => branch.branch_id === selectedOption.value);
                if (selectedBranch) {
                  setParentBranchId(selectedOption.value);
                  setParentBranch(selectedOption.label);
                }
              }}
              placeholder="Select Parent Branch"
            />
            </div>
          
             <div className="mb-4">
            <ReactSelect
                id="roleId"
                className=" shadow-lg text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                options={filteredroles.map((role) => ({
                  value: role.role_id,
                  label: role.role_name,
                }))}
                value={filteredroles.find((role) => role.role_name === roleId)}
                onChange={(selectedOption) => {
                  const selectedrole = roles.find((role) => role.role_id === selectedOption.value);
                  if (selectedrole) {
                    setroleId(selectedOption.value);
                    setRoleName(selectedOption.label);
                  }
                }}
                placeholder="Select Role"
              />
            </div>
            <div className="mb-6">
                <textarea
                  id="comment"
                  className="border rounded shadow-lg w-[1245px] ml-0 py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  rows="4"
                  placeholder="Enter your thoughts..."
                  value={comment}
                  onChange={(e) => setComment(e.target.value)}
                ></textarea>
              </div>
            <button
              className=" w-[150px] ml-0 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
              type="submit"
            >
              Create Team
            </button>
          </form>
          <table className="min-w-full divide-y divide-gray-200 mt-6">
            <thead className="bg-gray-200">
              <tr>
                <th className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">ID</th>
                <th className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">teamuser Name </th>
                <th className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Branch</th>
                <th className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Role </th>
                <th className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Created Date</th>
                <th className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Modified Date</th>
                <th className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Actions</th>
              </tr>
            </thead>
            <tbody className="bg-white divide-y divide-gray-200">
              {currentItems.map((teamuser) => (
                <tr key={teamuser.teamuser_id}>
                  <td className="px-4 py-4 whitespace-nowrap text-xs">{teamuser.teamuser_id}</td>
                  <td className="px-4 py-4 whitespace-nowrap text-xs">{teamuser.teamusername}</td>
                  <td className="px-4 py-4 whitespace-nowrap text-xs">{teamuser.parentbranch_name}</td>
                  <td className="px-4 py-4 whitespace-nowrap text-xs">{teamuser.role_name}</td>
                  <td className="px-4 py-4 whitespace-nowrap text-xs">{teamuser.created_at}</td>
                  <td className="px-4 py-4 whitespace-nowrap text-xs">{teamuser.modified_at}</td>
                  <td className="px-4 py-4 whitespace-nowrap text-xs">
                    <button
                      className="bg-yellow-500 hover:bg-yellow-700 text-white font-bold py-1 px-2 rounded focus:outline-none focus:shadow-outline mr-2"
                      onClick={() => handleOpenEditPopup(teamuser.teamuser_id, teamuser.teamuser_name)} 
                    >
                      Edit
                    </button>
                    <button
                      className="bg-red-500 hover:bg-red-700 text-white font-bold py-1 px-2 rounded focus:outline-none focus:shadow-outline"
                      onClick={() => handleDelete(teamuser.teamuser_id)}
                    >
                      Delete
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          <div className="flex justify-start items-center mt-4">
            <div>
              {currentPage > 1 && (
                <button
                  className="mr-2 bg-blue-500 hover:bg-blue-600 text-white font-bold py-1 px-2 rounded focus:outline-none focus:shadow-outline"
                  onClick={() => handlePageChange(currentPage - 1)}
                >
                  <ChevronLeftIcon className="h-5 w-5" />
                </button>
              )}
            </div>
            <div className="flex space-x-2">
              {[1, 2, 3, 4].map((page) => (
                <button
                  key={page}
                  className={`bg-blue-500 hover:bg-blue-600 text-white font-bold py-1 px-2 rounded focus:outline-none focus:shadow-outline ${
                    currentPage === page ? 'bg-orange-400' : ''
                  }`}
                  onClick={() => handlePageChange(page)}
                >
                  {page}
                </button>
              ))}
            </div>
            <div>
              {currentPage < Math.ceil(teamusers.length / itemsPerPage) && (
                <button
                  className="ml-2 bg-blue-500 hover:bg-blue-600 text-white font-bold py-1 px-2 rounded focus:outline-none focus:shadow-outline"
                  onClick={() => handlePageChange(currentPage + 1)}
                >
                  <ChevronRightIcon className="h-5 w-5" />
                </button>
              )}
            </div>
          </div>
        </div>
        </div>
   
  
      {/* Edit Popup */}
      {showEditPopup && (
        <div className="fixed z-10 inset-0 overflow-y-auto flex items-center justify-center">
          <div className="fixed inset-0 bg-gray-500 opacity-75"></div>
          <div className="relative bg-white w-96 rounded-lg p-8">
            <h2 className="text-lg font-bold mb-4">Edit teamuser Name</h2>
            <input
              type="text"
              value={editItemName}
              onChange={(e) => setEditItemName(e.target.value)}
              className="shadow-lg w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              placeholder="Enter New teamuser Name"
            />
            <div className="mt-4 flex justify-end">
              <button
                onClick={() => handleEdit(editItemId, editItemName)}
                className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline mr-2"
              >
                Save Changes
              </button>
              <button
                onClick={handleCloseEditPopup}
                className="bg-gray-400 hover:bg-gray-600 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
  
}



export default TeamUser;
