import React from "react";
import { Link } from 'react-router-dom';

function Aboutus() {
  return (
    <div className="container mx-auto p-8 bg-gradient-to-r from-blue-800 to-green-300">
      <nav className="fixed top-0 left-0 right-0 bg-white border-b-2 border-gray-200 shadow-lg z-50 py-4 hidden md:block">
        <div className="container mx-auto flex justify-between items-center px-8">
          <Link to="/">
            <img src="logo.png" alt="Logo.png" className="h-16 ml-8 " />
          </Link>
          <div className="hidden md:flex space-x-4">
            <button className="bg-blue-600 hover:bg-red-600 text-white font-semibold py-2 px-4 rounded focus:outline-none">
              <Link to="/signin">Login</Link>
            </button>
            <button className="bg-green-600 hover:bg-red-600 text-white font-semibold py-2 px-4 rounded focus:outline-none">
              <Link to="/SignupPage">Get Started</Link>
            </button>
          </div>
        </div>
      </nav>
      {/* Page Header */}
      <h1 className="text-5xl mt-24 font-bold text-center text-white mb-8">
        About Us
      </h1>

      {/* Intro Section */}
      <div className="text-center">
        <p className="text-lg text-white mb-4">
          Welcome to <span className="font-semibold text-white">NavDoot</span>—your trusted partner in supply chain management solutions. We empower businesses with tools to streamline logistics, optimize operations, and drive growth.
        </p>
        <p className="text-lg text-white mb-8">
          Our journey began with a mission to revolutionize how businesses approach supply chains. Today, we’re proud to offer innovative, user-friendly solutions that make a difference.
        </p>
      </div>

      {/* Our Mission Section */}
      <div className="bg-blue-50 rounded-lg p-6 mb-8 shadow-md transition-transform hover:scale-[1.02] duration-300">
        <h2 className="text-3xl font-bold text-blue-800 mb-4 text-center">
          Our Mission
        </h2>
        <p className="text-gray-700 text-lg">
          At NavDoot, our mission is simple: to transform supply chain management into a seamless, efficient, and transparent process. We aim to empower businesses of all sizes with solutions that:
        </p>
        <ul className="list-disc list-inside mt-4 text-gray-700 text-lg">
          <li>Improve operational efficiency</li>
          <li>Enhance real-time visibility</li>
          <li>Reduce logistics costs</li>
          <li>Ensure customer satisfaction</li>
        </ul>
      </div>

      {/* Our Values Section */}
      <div className="grid grid-cols-1 md:grid-cols-3 gap-6 mb-12">
        {[
          { title: "Innovation", icon: "🚀", description: "Driving progress through cutting-edge technology and creativity." },
          { title: "Integrity", icon: "🤝", description: "Committed to transparency and ethical practices in everything we do." },
          { title: "Customer Focus", icon: "🌟", description: "Putting customer needs and satisfaction at the heart of our solutions." },
        ].map((value, index) => (
          <div
            key={index}
            className="bg-white shadow-lg rounded-lg p-6 text-center hover:bg-blue-100 transition-all duration-300"
          >
            <div className="text-4xl mb-4">{value.icon}</div>
            <h3 className="text-2xl font-semibold text-blue-800 mb-2">
              {value.title}
            </h3>
            <p className="text-gray-700">{value.description}</p>
          </div>
        ))}
      </div>

      {/* Call-to-Action Section */}
      <div className="bg-gradient-to-r from-blue-500 to-blue-700 text-white p-8 rounded-lg shadow-md text-center">
        <h2 className="text-3xl font-bold mb-4">
          Join Us on the Journey
        </h2>
        <p className="text-lg mb-6">
          Ready to transform your supply chain? Discover how NavDoot can help your business reach new heights.
        </p>
        <a
          href="#"
          className="bg-white text-blue-700 px-6 py-3 rounded-full font-semibold shadow hover:bg-gray-200 transition-all duration-300"
        >
          Learn More
        </a>
      </div>

      {/* Footer Section */}
      <div className="text-center mt-12">
        <p className="text-gray-600">
          © {new Date().getFullYear()} NavDoot. All Rights Reserved.
        </p>
      </div>
    </div>
  );
}

export default Aboutus;
