import React from 'react';
import Carousel from 'react-elastic-carousel';

const Testimonials = () => {
  const testimonials = [
    {
      id: 1,
      name: 'Sarah T',
      position: 'Operations Director.',
      text: '"Real-time tracking and seamless integration have drastically improved our delivery performance.',
      rating: 5,
    },
    {
      id: 2,
      name: 'Jane Smith',
      position: 'Logistics Coordinator!',
      text: 'This platform has transformed our logistics operations, making them more efficient and cost-effective',
      rating: 4,
    },
    {
      id: 3,
      name: 'Emily W',
      position: 'Freight Manager!',
      text: 'The software has simplified our logistics, saving us time and improving accuracy across the board..',
      rating: 3,
    },
    {
      id: 4,
      name: 'John D',
      position: 'Distribution Manager!',
      text: 'Our entire supply chain is more agile and streamlined thanks to this powerful tool. We are more transparent"',
      rating: 5,
    },
    {
      id: 5,
      name: 'Nina G',
      position: 'Warehouse Supervisor!',
      text: 'Thanks to this SaaS application, we have reduced operational costs and improved efficiency using this platform" ',
      rating: 5,
    },
    {
        id: 6,
        name: 'Michael R',
        position: 'Head of Procurementt!',
        text: 'This solution provides us with full visibility into our logistics, boosting productivity and customer satisfaction',
        rating: 5,
      },
      {
        id: 7,
        name: 'Rachel P.',
        position: 'Supply Chain Analyst!',
        text: 'Our team now operates more smoothly, with fewer delays and better coordination across the board',
        rating: 5,
      },
      {
        id: 8,
        name: 'Chris A',
        position: 'Fleet Manager!',
        text: 'The platform’s automation and analytics features have made our logistics process more reliable and scalable.',
        rating: 5,
      },
      {
        id: 9,
        name: 'Olivia H',
        position: 'Customer Success Manager!',
        text: 'With this application, we’ve significantly reduced delivery times and improved route optimization with more efficiency',
        rating: 5,
      },
  ];

  const carouselSettings = {
    itemsToShow: 3, 
    enableAutoPlay: true, 
    autoPlaySpeed: 3000, 
    itemPosition: 'center', 
    itemPadding: [0, 20], 
  };

  return (
     <div className="max-w-8xl mx-auto h-96 bg-gradient-to-r from-blue-800 to-green-300 hidden md:block">
      <div className="text-center ">
        <br />
        <h2 className="text-3xl mb-12 text-white">Transforming Businesses with Our Solution!</h2>
      </div>
      <div className="ml-8 mr-8">
        <Carousel {...carouselSettings}>
          {testimonials.map((testimonial) => (
            <div key={testimonial.id} className="bg-white shadow-md rounded-lg p-6">
              <div className="mb-4">
                <h3 className="text-lg font-semibold text-gray-900">{testimonial.name}</h3>
                <p className="text-gray-600">{testimonial.position}</p>
              </div>
              <p className="text-gray-700 mb-4">{testimonial.text}</p>
              <div className="flex items-center">
                {/* Star rating icons */}
                {[...Array(testimonial.rating)].map((_, index) => (
                  <svg key={index} className="w-6 h-6 fill-current text-yellow-500 mr-1" viewBox="0 0 24 24">
                    <path d="M12 2L15.09 8.26L22 9.27L17 14.14L18.18 21L12 17.77L5.82 21L7 14.14L2 9.27L8.91 8.26L12 2M12 5.81L10.26 9.09L6.5 10.09L9.21 12.72L8.46 16.89L12 14.82L15.54 16.88L14.79 12.71L17.5 10.08L13.74 9.08L12 5.81Z" />
                  </svg>
                ))}
                {/* Additional stars/icons as needed */}
              </div>
            </div>
          ))}
        </Carousel>
      </div>
    </div>
  );
};

export default Testimonials;
