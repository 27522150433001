import React from 'react';

const Reason = () => {
  const reasons = [
    { icon: '⭐️', text: 'Easy to Use: NavDoot offers an intuitive and user-friendly interface...' },
    { icon: '🚀', text: 'Scalable Solutions: With NavDoot, scalability is not a concern...' },
    { icon: '📊', text: 'Real-time Insights: Gain valuable insights into your operations with NavDoots...' },
    { icon: '🔗', text: 'Seamless Integration: NavDoot seamlessly integrates with your existing systems...' },
    { icon: '💬', text: '24/7 Customer Support: At NavDoot, we prioritize customer satisfaction...' },
    { icon: '💵', text: 'Cost-effective Pricing: NavDoot offers cost-effective pricing plans tailored...' },
  ];

  return (
    <div className="max-w-6xl mx-auto px-4 py-8 hidden md:block">
      <h2 className="text-3xl font-bold mb-8 text-center text-white">Why Choose NavDoot?</h2>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
        {reasons.map((reason, index) => (
          <div key={index} className="flex items-center bg-gray-100 rounded-lg p-4 hover: shadow-lg transition duration-300 ease-in-out transform hover:scale-105">
            <span className="text-2xl mr-2">{reason.icon}</span>
            <span className="text-lg">{reason.text}</span>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Reason;
