import React from 'react';
import { Link } from "react-router-dom";

const ERPAccounting = () => {
  return (
    <div className="bg-gray-50 min-h-screen">
      {/* Header Section */}
      <nav className="sticky top-0 bg-white shadow-lg z-50 border-b-2 border-gray-200">
        <div className="container mx-auto flex items-center justify-between px-6 py-4">
         <h1 className="text-2xl font-bold text-blue-800">
        <Link to="/">
          <img src="logo.png" alt="Logo.png" className="h-16 ml-8 " />
          </Link>
          </h1>
          <ul className="flex space-x-6">
            <li className="hover:text-green-400">
              <a href="#overview" className="transition duration-300">Overview</a>
            </li>
            <li className="hover:text-green-400">
              <a href="#modules" className="transition duration-300">Modules</a>
            </li>
            <li className="hover:text-green-400">
              <a href="#testimonials" className="transition duration-300">Testimonials</a>
            </li>
            <li className="hover:text-green-400">
              <a href="#pricing" className="transition duration-300">Pricing</a>
            </li>
          </ul>
          </div>
        </nav>
    

      {/* Hero Section with More White Space */}
      <section id="overview" className="h-screen flex items-center justify-center bg-white">
        <div className="text-center max-w-2xl px-6 py-12">
          <h2 className="text-5xl font-extrabold mb-6 text-blue-800 animate-fade-in-down">Simplify Your Accounting</h2>
          <p className="text-lg mb-8 text-gray-700">
            Discover a comprehensive ERP Accounting solution designed to streamline your financial processes, improve accuracy, and save valuable time.
          </p>
          <button className="bg-green-400 hover:bg-green-500 text-blue-900 px-6 py-3 rounded shadow-lg transition duration-300 transform hover:scale-105">
            Learn More
          </button>
        </div>
      </section>

      {/* Modules Section with White Background */}
      <section id="modules" className="py-16 bg-white">
        <div className="max-w-7xl mx-auto text-center">
          <h3 className="text-4xl font-bold mb-12 text-blue-800">Core Modules</h3>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
            <div className="bg-green-300 text-blue-900 p-6 rounded-lg shadow-lg hover:scale-105 transition duration-300">
              <h4 className="text-2xl font-bold mb-4">Accounts Payable</h4>
              <p>Track and manage outgoing payments efficiently with automated workflows.</p>
            </div>
            <div className="bg-green-300 text-blue-900 p-6 rounded-lg shadow-lg hover:scale-105 transition duration-300">
              <h4 className="text-2xl font-bold mb-4">Accounts Receivable</h4>
              <p>Streamline invoicing and monitor incoming payments with ease.</p>
            </div>
            <div className="bg-green-300 text-blue-900 p-6 rounded-lg shadow-lg hover:scale-105 transition duration-300">
              <h4 className="text-2xl font-bold mb-4">Financial Reporting</h4>
              <p>Generate real-time reports to gain actionable insights into your financial health.</p>
            </div>
          </div>
        </div>
      </section>

      {/* Testimonials Section with White Background */}
      <section id="testimonials" className="py-16 bg-white">
        <div className="max-w-7xl mx-auto text-center">
          <h3 className="text-4xl font-bold mb-12 text-blue-800">What Our Clients Say</h3>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
            <blockquote className="p-6 bg-blue-800 text-green-300 rounded-lg shadow-lg">
              <p>"This ERP Accounting software has saved us countless hours of manual work. Highly intuitive and reliable."</p>
              <cite>- Sarah Wilson, CFO of FinTech</cite>
            </blockquote>
            <blockquote className="p-6 bg-blue-800 text-green-300 rounded-lg shadow-lg">
              <p>"A must-have for businesses looking to stay ahead in their financial management game!"</p>
              <cite>- Michael Brown, Small Business Owner</cite>
            </blockquote>
          </div>
        </div>
      </section>

      {/* Pricing Section with More White Space */}
      <section id="pricing" className="py-16 bg-white">
        <div className="max-w-7xl mx-auto text-center">
          <h3 className="text-4xl font-bold mb-12 text-blue-800">Pricing Plans</h3>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
            <div className="bg-blue-900 text-green-300 p-6 rounded-lg shadow-lg hover:scale-105 transition duration-300">
              <h4 className="text-2xl font-bold mb-4">Basic</h4>
              <p>$15/month - Perfect for startups</p>
            </div>
            <div className="bg-blue-900 text-green-300 p-6 rounded-lg shadow-lg hover:scale-105 transition duration-300">
              <h4 className="text-2xl font-bold mb-4">Professional</h4>
              <p>$40/month - For growing companies</p>
            </div>
            <div className="bg-blue-900 text-green-300 p-6 rounded-lg shadow-lg hover:scale-105 transition duration-300">
              <h4 className="text-2xl font-bold mb-4">Enterprise</h4>
              <p>$80/month - Advanced solutions for enterprises</p>
            </div>
          </div>
        </div>
      </section>

      {/* Footer with White Background */}
      <footer className="py-6 bg-white text-center">
        <p className="text-blue-800">&copy; 2024 NavDoot ERP Accounting, All rights reserved.</p>
      </footer>
    </div>
  );
};

export default ERPAccounting;
