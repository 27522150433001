import React, { useState, useEffect } from 'react';
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/outline';
import ReactSelect from 'react-select'; // Import ReactSelect
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { ToastContainer } from 'react-toastify';
import { toast } from 'react-toastify'; // Moved up
import 'react-toastify/dist/ReactToastify.css'; // Moved down
const config=require('../../config')

const StationerySalesClient = () => {
  const [billName, setBillName] = useState('');
  const [Bills, setBills] = useState([]);
  const [customers, setCustomers] = useState([]);
  const [filteredCustomers, setFilteredCustomers] = useState([]);
  const [customerId, setCustomerId] = useState('');  
  const [CustomerName, setCustomerName] = useState('');
  const [products, setProducts] = useState([]);
  const [filteredProducts, setFilteredProducts] = useState([]);
  const [productId, setProductId] = useState('');  
  const [ProductName, setProductName] = useState('');
  const [startDate, setStartDate] = useState(null);
  const [Qty, setQty] = useState('');
  const [Rate, setRate] = useState('');
  const [startFrom, setStartFrom] = useState('');
  const [EndStock, setEndStock] = useState('');
  const [Amount, setAmount] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [showEditPopup, setShowEditPopup] = useState(false);
  const [editItemId, setEditItemId] = useState(null);
  const [editItemName, setEditItemName] = useState('');
  const itemsPerPage = 5;
  const [key, setKey] = useState(0);
  const token = localStorage.getItem('token');


  useEffect(() => {
    fetchCustomers(); 
    fetchProducts();
    fetchBills();
  }, []);


  const fetchCustomers = async () => {
    try {
      const response = await fetch(config.backendDomain+'/customersearch', {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const data = await response.json();
      setCustomers(data);
      setFilteredCustomers(data); // Initialize filtered data with all branches
    } catch (error) {
      console.error('Error fetching Customers:', error);
    }
  };

  const handleCustomerSearch = (searchTerm) => {
    const filtered = customers.filter((customers) =>
      customers.customers_name.toLowerCase().startsWith(searchTerm.toLowerCase())
    );
    console.log('Filtered customers:', filtered); // Add this line for debugging
    setFilteredCustomers(filtered);
  };


  const fetchProducts = async () => {
    try {
      const response = await fetch(config.backendDomain+'/productsearch', {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const data = await response.json();
      setProducts(data);
      setFilteredProducts(data); // Initialize filtered data with all branches
    } catch (error) {
      console.error('Error fetching Products:', error);
    }
  };

  const handleProductSearch = (searchTerm) => {
    const filtered = products.filter((products) =>
      products.products_name.toLowerCase().startsWith(searchTerm.toLowerCase())
    );
    console.log('Filtered Products:', filtered); // Add this line for debugging
    setFilteredProducts(filtered);
  };

  const fetchBills = async () => {
    try {
      const response = await fetch(config.backendDomain+'/stationerysaleclient', {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const data = await response.json();
      // Sort the data by created_date in descending order
      const sortedData = data.sort((a, b) => new Date(b.created_date) - new Date(a.created_date));
      setBills(sortedData);
    } catch (error) {
      console.error('Error fetching Stationery:', error);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

     // Calculate EndStock by adding StartFrom and Qty
  const endStockValue = Number(startFrom) + Number(Qty);
  const netAmount = Number(Rate) * Number(Qty);
    
    try {
      const response = await fetch(config.backendDomain+'/stationerysaleclient', {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ billName, customerId, CustomerName, productId, ProductName, Qty, Rate, startFrom, EndStock: endStockValue, startDate, Amount: netAmount }), 
      });
      const data = await response.json();

      if (response.ok) {
        toast.success(data.message);
        setBillName('');
        setCustomerId('');
        setCustomerName('');
        setProductId('');       
        setProductName('');
        setQty('');
        setRate('');
        setStartFrom('');
        setEndStock('');
        setStartDate('');  
        setAmount('');     
        // setKey((prevKey) => prevKey + 2);
        fetchBills();
        
        // Fetch latest data after successful submission
      } else {
        handleErrorResponse(response.status, data.message);
      }
    } catch (error) {
      console.error('Error in purchase:', error);
      handleErrorResponse(500, 'An error occurred.'); // Internal server error
    }
  };

  const handleEdit = async (id, newName) => {
    try {
      const response = await fetch(config.backendDomain+`/stationerysaleclient/${id}`, {
        method: 'PUT',
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ Customer: newName }),
      });
      const data = await response.json();

      if (response.ok) {
        toast.success(data.message);
        fetchBills(); // Fetch latest data after successful edit
        handleCloseEditPopup();
      } else {
        handleErrorResponse(response.status, data.message);
      }
    } catch (error) {
      console.error('Error updating Customer:', error);
      handleErrorResponse(500, 'An error occurred.'); // Internal server error
    }
  };

  const handleDelete = async (id) => {
    try {
      const response = await fetch(config.backendDomain+`/stationerysaleclient/${id}`, {
        method: 'DELETE',
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const data = await response.json();

      if (response.ok) {
        toast.success(data.message);
        fetchBills(); // Fetch latest data after successful deletion
      } else {
        handleErrorResponse(response.status, data.message);
      }
    } catch (error) {
      console.error('Error deleting Customer:', error);
      handleErrorResponse(500, 'An error occurred.'); // Internal server error
    }
  };

  const handleErrorResponse = (status, message) => {
    if (status === 403) {
      showAlert('You are not authorized.');
    } else if (status === 400) {
      showAlert(message || 'Purchase with the same entry already exists');
    } else {
      showAlert('An error occurred.');
    }
  };

  const showAlert = (msg) => {
    toast.info(msg);
  };

  const handleOpenEditPopup = (id, newName) => {
    setEditItemId(id);
    setEditItemName(newName);
    setShowEditPopup(true);
  };

  const handleCloseEditPopup = () => {
    setEditItemId(null);
    setEditItemName('');
    setShowEditPopup(false);
  };

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = Bills.slice(indexOfFirstItem, indexOfLastItem);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  return (
    <div key={key} className="relative bg-gray-100 animate__animated animate__fadeIn">
      <ToastContainer className="mt-14" /> 
    <div className="relative bg-blue-50">
    <h2 className="shadow-lg text-xl h-10 font-bold bg-blue-500 text-white mb-6 pl-3 pt-1 ">Stock of Stationery Purchase</h2>

        <form onSubmit={handleSubmit} className="grid grid-cols-2 gap-4">
        <div className="mb-4">
            <input
              type="text"
              id="bill"
              pattern="[a-zA-Z0-9/-]{1,32}"
              title="Please enter a valid bill number without special character"
              className=" shadow-lg w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              placeholder="Enter Voucher Number"
              value={billName}
              onChange={(e) => setBillName(e.target.value)}
              required
            />
          </div>

          <div className="mb-4">
          <ReactSelect
              id="customerId"
              className=" shadow-lg text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              options={filteredCustomers.map((customer) => ({
                value: customer.customer_id,
                label: customer.customer_name,
              }))}
              value={filteredCustomers.find((customer) => customer.customer_name === customerId)}
              onChange={(selectedOption) => {
                const selectedCustomer = customers.find((customer) => customer.customer_id === selectedOption.value);
                if (selectedCustomer) {
                  setCustomerId(selectedOption.value);
                  setCustomerName(selectedOption.label);
                }
              }}
              placeholder="Select Customer"
            />
          </div>

          <div className="mb-4">
          <ReactSelect
              id="productId"
              className=" shadow-lg text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              options={filteredProducts.map((product) => ({
                value: product.product_id,
                label: product.product_name,
              }))}
              value={filteredProducts.find((product) => product.product_name === productId)}
              onChange={(selectedOption) => {
                const selectedProduct = products.find((product) => product.product_id === selectedOption.value);
                if (selectedProduct) {
                  setProductId(selectedOption.value);
                  setProductName(selectedOption.label);
                }
              }}
              placeholder="Select Product"
            />
          </div>
          <div className="mb-4">
            <input
              type="text"
              id="Qty"
              pattern="[0-9]{1,8}"
              title="Please enter a number with 1 to 8 digits"
              className=" shadow-lg w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              placeholder="Enter Quantity"
              value={Qty}
              onChange={(e) => setQty(e.target.value)}
              required
            />
          </div>
          <div className="mb-4">
            <input
              type="text"
              id="Rate"
              pattern="^(?:\d*\.\d{1,2}|\d+)$"
              title="Please enter a valid amount (e.g., 100, 100.00, 100.5)"
              className=" shadow-lg w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              placeholder="Rate"
              value={Rate}
              onChange={(e) => setRate(e.target.value)}
              required
            />
          </div>
          <div className="mb-4">
            <input
              type="text"
              id="StartFrom"
              pattern="[0-9]{1,20}"
              title="Please enter a number with 1 to 8 digits"
              className=" shadow-lg w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              placeholder="Stock Starting Number"
              value={startFrom}
              onChange={(e) => setStartFrom(e.target.value)}
              required
            />
          </div>
          <div className="mb-4">
            <input
              type="text"
              id="EndStock"
              className="shadow-lg w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              placeholder="Stock End Number"
              value={Number(startFrom) + Number(Qty)-1} 
              readOnly // Make the input read-only
              required
            />
          </div>

          <div className="mb-4 ">
            <DatePicker
                selected={startDate}
                onChange={(date) => setStartDate(date)}
                dateFormat="MM/dd/yyyy"
                placeholderText="Select Issue Date"
                className=" shadow-lg w-[615px] py-2 px-3 pl-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                required
            />
          </div>

          <div className="mb-4">
            <input
              type="text"
              id="Amount"
              className="shadow-lg w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              placeholder="Invoice Amount"
              value={Number(Rate) * Number(Qty)} 
              readOnly // Make the input read-only
              required
            />
          </div>
                    
          <div className="flex-center">
            <button type="submit" className="bg-blue-500 hover:bg-blue-700 text-white font-semibold py-2 px-4 rounded focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50">Submit</button>
          </div>
        </form>
        <table className="min-w-full divide-y divide-gray-200 mt-6">
            <thead className="bg-gray-200">
              <tr>
                <th className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Invoice No.</th>
                <th className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Customer</th>
                <th className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Product </th>
                <th className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Qty</th>
                <th className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Start From</th>
                <th className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">To end </th>
                <th className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Amount </th>
                <th className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Issue Date </th>
                <th className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Created Date</th>
                <th className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Actions</th>
              </tr>
            </thead>
            <tbody className="bg-white divide-y divide-gray-200">
              {currentItems.map((stationerysaleclient) => (
                <tr key={stationerysaleclient.stationerysaleclient_id}>
                  <td className="px-4 py-4 whitespace-nowrap text-xs">{stationerysaleclient.bill_name}</td>
                  <td className="px-4 py-4 whitespace-nowrap text-xs">{stationerysaleclient.customer_name}</td>
                  <td className="px-4 py-4 whitespace-nowrap text-xs">{stationerysaleclient.product_name}</td>
                  <td className="px-4 py-4 whitespace-nowrap text-xs">{stationerysaleclient.qty}</td>
                  <td className="px-4 py-4 whitespace-nowrap text-xs">{stationerysaleclient.start_from}</td>
                  <td className="px-4 py-4 whitespace-nowrap text-xs">{stationerysaleclient.end_to}</td>
                  <td className="px-4 py-4 whitespace-nowrap text-xs">{stationerysaleclient.amount}</td>
                  <td className="px-4 py-4 whitespace-nowrap text-xs">{stationerysaleclient.issue_date}</td>
                  <td className="px-4 py-4 whitespace-nowrap text-xs">{stationerysaleclient.created_date}</td>
                  <td className="px-4 py-4 whitespace-nowrap text-xs">
                    <button
                      className="bg-yellow-500 hover:bg-yellow-700 text-white font-bold py-1 px-2 rounded focus:outline-none focus:shadow-outline mr-2"
                      onClick={() => handleOpenEditPopup(stationerysaleclient.bill_name, stationerysaleclient.bill_name)} 
                    >
                      Edit
                    </button>
                    <button
                      className="bg-red-500 hover:bg-red-700 text-white font-bold py-1 px-2 rounded focus:outline-none focus:shadow-outline"
                      onClick={() => handleDelete(stationerysaleclient.bill_name)}
                    >
                      Delete
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          <div className="flex justify-start items-center mt-4">
            <div>
              {currentPage > 1 && (
                <button
                  className="mr-2 bg-blue-500 hover:bg-blue-600 text-white font-bold py-1 px-2 rounded focus:outline-none focus:shadow-outline"
                  onClick={() => handlePageChange(currentPage - 1)}
                >
                  <ChevronLeftIcon className="h-5 w-5" />
                </button>
              )}
            </div>
            <div className="flex space-x-2">
              {[1, 2, 3, 4].map((page) => (
                <button
                  key={page}
                  className={`bg-blue-500 hover:bg-blue-600 text-white font-bold py-1 px-2 rounded focus:outline-none focus:shadow-outline ${
                    currentPage === page ? 'bg-orange-400' : ''
                  }`}
                  onClick={() => handlePageChange(page)}
                >
                  {page}
                </button>
              ))}
            </div>
            <div>
              {currentPage < Math.ceil(Bills.length / itemsPerPage) && (
                <button
                  className="ml-2 bg-blue-500 hover:bg-blue-600 text-white font-bold py-1 px-2 rounded focus:outline-none focus:shadow-outline"
                  onClick={() => handlePageChange(currentPage + 1)}
                >
                  <ChevronRightIcon className="h-5 w-5" />
                </button>
              )}
            </div>
          </div>
        </div>
  
      {/* Edit Popup */}
      {showEditPopup && (
        <div className="fixed z-10 inset-0 overflow-y-auto flex items-center justify-center">
          <div className="fixed inset-0 bg-gray-500 opacity-75"></div>
          <div className="relative bg-white w-96 rounded-lg p-8">
            <h2 className="text-lg font-bold mb-4">Edit Bill Number</h2>
            <input
              type="text"
              value={editItemName}
              onChange={(e) => setEditItemName(e.target.value)}
              className="shadow-lg w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              placeholder="Enter New Customer Name"
            />
            <div className="mt-4 flex justify-end">
              <button
                onClick={() => handleEdit(editItemId, editItemName)}
                className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline mr-2"
              >
                Save Changes
              </button>
              <button
                onClick={handleCloseEditPopup}
                className="bg-gray-400 hover:bg-gray-600 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default StationerySalesClient;
