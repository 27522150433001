import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { FaUser, FaChartLine, FaShieldAlt, FaCheck, FaTimes } from 'react-icons/fa'; // Import icons from React Icons library
import UserProfile from '../Authentication/UserProfile';
const config = require('../config')

const SubscriptionPage = () => {
  const navigate = useNavigate();
  const [isAnnual, setIsAnnual] = useState(false);


  const handlePayment = async (subscriptionType) => {
    try {
      // Retrieve JWT token from localStorage
      const token = localStorage.getItem('token');

      // Fetch the payment order from your backend server
      const response = await fetch(config.backendDomain + '/payment', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}` // Include JWT token in request headers
        },
        body: JSON.stringify({ subscriptionType }), // Pass subscription type to backend
      });

      const data = await response.json();

      // Check if Razorpay is available
      if (window.Razorpay) {
        // Proceed with payment initialization
        const options = {
          key: 'rzp_test_BewAhm6VpojR01', // Use your Razorpay key here
          amount: data.amount,
          currency: 'INR',
          name: 'NavDoot',
          description: `Payment for ${subscriptionType} Subscription`,
          order_id: data.id,
          handler: function (response) {
            console.log(response);
            // Handle success callback
            // Redirect user to success page or perform other actions
            navigate('/Dashboard');
          },
          prefill: {
            name: 'Customer Name',
            email: 'customer@example.com',
            contact: '9999999999',
          },
          notes: {
            address: 'Customer Address',
          },
          theme: {
            color: '#F37254',
          },
        };
        const paymentObject = new window.Razorpay(options);
        paymentObject.open();
      } else {
        console.error('Razorpay SDK is not loaded');
      }
    } catch (error) {
      console.error('Error handling payment:', error);
    }

  };


  return (
    <div className="container mx-auto p-8 bg-gradient-to-r from-blue-800 to-green-300">
      {/* Navbar */}
      <nav className="fixed top-0 left-0 right-0 bg-white border-b-2 border-gray-200 shadow-lg z-50 py-4 hidden md:block">
        <div className="container mx-auto flex justify-between items-center px-8">

          <Link to="/">
            <img src="logo.png" alt="Logo.png" className="h-16 ml-8 hidden md:block" />
          </Link>
        </div>
        <div className="absolute top-2 right-0 mt-2 mr-8">
          <UserProfile />
        </div>
      </nav>

      <div className="relative bg-gradient-to-r from-blue-800 to-green-300">
        <div className="flex justify-center items-center h-screen">
          <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
            {/* Basic Plan */}
            <div className="bg-white rounded-lg shadow-md p-6 animate__animated animate__fadeIn hover: shadow-lg transition duration-300 ease-in-out transform hover:scale-105">
              <h2 className="text-lg font-semibold mb-4 text-blue-500 flex items-center">
                <FaUser className="w-8 h-8 mr-2" /> Basic Plan
              </h2>
              <div className="text-gray-700 mb-4">
                <ul className="pl-4 list-disc">
                  <li><FaCheck className="text-green-500 mr-2 inline-block" /> Access to all features for small businesses.</li>
                  <li><FaCheck className="text-green-500 mr-2 inline-block" /> 5 Users</li>
                  <li><FaTimes className="text-red-500 mr-2 inline-block" /> Mobile App Access</li>
                  <li><FaCheck className="text-green-500 mr-2 inline-block" /> Basic Reporting</li>
                  <li><FaCheck className="text-green-500 mr-2 inline-block" /> Email Support</li>
                  <li><FaCheck className="text-green-500 mr-2 inline-block" /> 7-days free trial</li>
                  <li><FaCheck className="text-green-500 mr-2 inline-block" /> API Integration</li>
                  <li><FaTimes className="text-red-500 mr-2 inline-block" /> Custom Templates</li>
                  <li><FaTimes className="text-red-500 mr-2 inline-block" /> Priority Support</li>
                  <li><FaCheck className="text-green-500 mr-2 inline-block" /> Customizable Reporting</li>
                  <li><FaCheck className="text-green-500 mr-2 inline-block" /> 24/7 Support</li>
                  <li><FaTimes className="text-red-500 mr-2 inline-block" /> Dedicated Account Manager</li>
                  <li><FaTimes className="text-red-500 mr-2 inline-block" /> Priority Bug Fixes</li>
                  <li><FaCheck className="text-green-500 mr-2 inline-block" /> Online Training</li>
                  <li><FaTimes className="text-red-500 mr-2 inline-block" /> Custom Development</li>
                </ul>
              </div>
              <div className="flex items-center justify-between mt-4">
                <div>
                  <button
                    className={`text-sm font-semibold mr-2 focus:outline-none ${isAnnual ? 'text-gray-600' : 'text-blue-500'}`}
                    onClick={() => setIsAnnual(false)}
                  >
                    Monthly
                  </button>
                  <button
                    className={`text-sm font-semibold focus:outline-none ${isAnnual ? 'text-blue-500' : 'text-gray-600'}`}
                    onClick={() => setIsAnnual(true)}
                  >
                    Annual
                  </button>
                </div>
                <button
                  className="bg-blue-500 text-white py-2 px-2 rounded hover:bg-blue-600 focus:outline-none"
                  onClick={() => handlePayment('Basic', isAnnual ? 199.99 : 19.99)}
                >
                  Subscribe Now (${isAnnual ? '199.99/year' : '19.99/month'})
                </button>
              </div>
            </div>

            {/* Regular Plan */}
            <div className="bg-white rounded-lg shadow-md p-6 animate__animated animate__fadeIn hover: shadow-lg transition duration-300 ease-in-out transform hover:scale-105">
              <h2 className="text-lg font-semibold mb-4 text-green-500 flex items-center">
                <FaChartLine className="w-8 h-8 mr-2" /> Regular Plan
              </h2>
              <div className="text-gray-700 mb-4">
                <ul className="pl-4 list-disc">
                  <li><FaCheck className="text-green-500 mr-2 inline-block" /> Access to features for medium businesses.</li>
                  <li><FaCheck className="text-green-500 mr-2 inline-block" /> 10 Users</li>
                  <li><FaCheck className="text-green-500 mr-2 inline-block" /> Mobile App Access</li>
                  <li><FaCheck className="text-green-500 mr-2 inline-block" /> Basic Reporting</li>
                  <li><FaCheck className="text-green-500 mr-2 inline-block" /> Email Support</li>
                  <li><FaCheck className="text-green-500 mr-2 inline-block" /> 15-days free trial</li>
                  <li><FaCheck className="text-green-500 mr-2 inline-block" /> API Integration</li>
                  <li><FaTimes className="text-red-500 mr-2 inline-block" /> Custom Templates</li>
                  <li><FaCheck className="text-green-500 mr-2 inline-block" /> Priority Support</li>
                  <li><FaCheck className="text-green-500 mr-2 inline-block" /> Customizable Reporting</li>
                  <li><FaCheck className="text-green-500 mr-2 inline-block" /> 24/7 Support</li>
                  <li><FaTimes className="text-red-500 mr-2 inline-block" /> Dedicated Account Manager</li>
                  <li><FaTimes className="text-red-500 mr-2 inline-block" /> Priority Bug Fixes</li>
                  <li><FaCheck className="text-green-500 mr-2 inline-block" /> Online Training</li>
                  <li><FaCheck className="text-green-500 mr-2 inline-block" /> Custom Development</li>
                </ul>
              </div>
              <div className="flex items-center justify-between mt-4">
                <div>
                  <button
                    className={`text-sm font-semibold mr-2 focus:outline-none ${isAnnual ? 'text-gray-600' : 'text-green-500'}`}
                    onClick={() => setIsAnnual(false)}
                  >
                    Monthly
                  </button>
                  <button
                    className={`text-sm font-semibold mr-2 focus:outline-none ${isAnnual ? 'text-green-500' : 'text-gray-600'}`}
                    onClick={() => setIsAnnual(true)}
                  >
                    Annual
                  </button>
                </div>
                <button
                  className="bg-green-500 text-white py-2 px-2 rounded hover:bg-green-600 focus:outline-none"
                  onClick={() => handlePayment('Regular', isAnnual ? 299.99 : 29.99)}
                >
                  Subscribe Now (${isAnnual ? '299.99/year' : '29.99/month'})
                </button>
              </div>
            </div>

            {/* Premium Plan */}
            <div className="bg-white rounded-lg shadow-md p-6 animate__animated animate__fadeIn hover: shadow-lg transition duration-300 ease-in-out transform hover:scale-105">
              <h2 className="text-lg font-semibold mb-4 text-yellow-500 flex items-center">
                <FaShieldAlt className="w-8 h-8 mr-2" /> Premium Plan
              </h2>
              <div className="text-gray-700 mb-4">
                <ul className="pl-4 list-disc">
                  <li><FaCheck className="text-green-500 mr-2 inline-block" /> Access to all features for large businesses.</li>
                  <li><FaCheck className="text-green-500 mr-2 inline-block" /> Unlimited Users</li>
                  <li><FaCheck className="text-green-500 mr-2 inline-block" /> Mobile App Access</li>
                  <li><FaCheck className="text-green-500 mr-2 inline-block" /> Basic Reporting</li>
                  <li><FaCheck className="text-green-500 mr-2 inline-block" /> Email Support</li>
                  <li><FaCheck className="text-green-500 mr-2 inline-block" /> 30-days free trial</li>
                  <li><FaCheck className="text-green-500 mr-2 inline-block" /> API Integration</li>
                  <li><FaCheck className="text-green-500 mr-2 inline-block" /> Custom Templates</li>
                  <li><FaCheck className="text-green-500 mr-2 inline-block" /> Priority Support</li>
                  <li><FaCheck className="text-green-500 mr-2 inline-block" /> Customizable Reporting</li>
                  <li><FaCheck className="text-green-500 mr-2 inline-block" /> 24/7 Support</li>
                  <li><FaCheck className="text-green-500 mr-2 inline-block" /> Dedicated Account Manager</li>
                  <li><FaCheck className="text-green-500 mr-2 inline-block" /> Priority Bug Fixes</li>
                  <li><FaCheck className="text-green-500 mr-2 inline-block" /> Online Training</li>
                  <li><FaCheck className="text-green-500 mr-2 inline-block" /> Custom Development</li>
                </ul>
              </div>
              <div className="flex items-center justify-between mt-4">
                <div>
                  <button
                    className={`text-sm font-semibold mr-2 focus:outline-none ${isAnnual ? 'text-gray-600' : 'text-yellow-500'}`}
                    onClick={() => setIsAnnual(false)}
                  >
                    Monthly
                  </button>
                  <button
                    className={`text-sm font-semibold focus:outline-none ${isAnnual ? 'text-yellow-500' : 'text-gray-600'}`}
                    onClick={() => setIsAnnual(true)}
                  >
                    Annual
                  </button>
                </div>
                <button
                  className="bg-yellow-500 text-white py-2 px-2 rounded hover:bg-yellow-600 focus:outline-none"
                  onClick={() => handlePayment('Premium', isAnnual ? 999.99 : 99.99)}
                >
                  Subscribe Now (${isAnnual ? '999.99/year' : '99.99/month'})
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SubscriptionPage;