import React from 'react';
import { Link } from 'react-router-dom';
import { Button } from '@material-ui/core';
import dashboardImage from '../Assets/material-dashboard-ui.png';

const Hero = () => {
  return (
    <div className="bg-gray-100 items-center justify-center rounded-lg shadow-lg hidden md:block">
      <div className="container mx-auto flex flex-col md:flex-row items-center justify-between px-8 py-8 md:py-24">
        <div className="md:w-1/2">
          <h1 className="text-4xl text-gray-600 font-bold mb-6 md:mb-8">NavDoot
            <span className="text-gradient bg-gradient-to-r from-blue-800 to-green-500 bg-clip-text text-transparent"> Dashboard UI</span> </h1>
          <p className="text-lg text-gray-700 mb-6 md:mb-8">Experience the power of a beautifully designed Material Dashboard UI. Enhance your productivity and create stunning applications with ease.</p>
          <Button variant="contained" color="primary" component={Link} to="/dashboard">Explore Dashboard</Button>
        </div>
        <div className="md:w-1/2 flex justify-center">
          <img src={dashboardImage} alt="Material Dashboard UI" className="w-full md:w-3/4 rounded-lg shadow-lg animate__animated animate__fadeIn hover: shadow-lg transition duration-300 ease-in-out transform hover:scale-105" />
        </div>
      </div>
    </div>
  );
};

export default Hero;
