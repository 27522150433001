import React, { useState, useEffect } from "react";
import ReactSelect from "react-select";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { ToastContainer } from "react-toastify";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
const config = require("../../config");
const CreateInvoice = () => {
  const [selectedCustomer, setSelectedCustomer] = useState();
  // const [customer, setcustomer] = useState([]);
  const [filteredcustomer, setFilteredcustomer] = useState([]);
  const [bookingEntry, setBookingEntry] = useState([]);
  const [selectedBookingEntry,setSelectedBookingEntry]=useState([])

  // const [InvoiceNumber, setInvoiceNumber] = useState("");
  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);
  // const [cities, setcities] = useState([]);
  // const [totalSum, setTotalSum] = useState(0);
  const token = localStorage.getItem("token");
  useEffect(() => {
    fetchcustomer();
  }, []);
  const table = {
    "WayBill Id": "waybill_id",
    "Origin":"origincity_name",
    "Destination":"destcity_name",
    "Actual Weight": "actual_weight",
    "Mode":"transportmode_name",
    "Pcs":"packets",
    "Weight":"actual_weight",

    "Booking Date": "booking_date",
    "Booking Amount": "booking_amount",
  
  };
  const fetchcustomer = async () => {
    try {
      const response = await fetch(config.backendDomain + "/customer", {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const data = await response.json();
      // setcustomer(data);
      setFilteredcustomer(data);
    } catch (error) {
      console.error("Error fetching Customer:", error);
    }
  };

  function setBookingValue(data){
    setBookingEntry(data);
    let initalValue=0
    setSelectedBookingEntry(data.map((d)=>(d.waybill_id)))
    // for(let i=0;i<data.length;i++){
    //   initalValue+=data[i].booking_amount
    // }
    // setTotalSum(initalValue)
  }
  


  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch(config.backendDomain + "/invoice_process", {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          customerId: selectedCustomer.value,
          startDate: fromDate,
          endDate: toDate,
        }),
      });
      const data = await response.json();

      if (response.ok) {
        toast.success(data.message);
        setBookingValue(data.data)
       

      } else {
        handleErrorResponse(response.status, data.message);
      }
    } catch (error) {
      console.error("Error creating Invoice:", error);
      handleErrorResponse(500, "An error occurred.");
    }
  };

  const handleErrorResponse = (status, message) => {
    if (status === 403) {
      showAlert("You are not authorized.");
    } else if (status === 400) {
      showAlert(message || "Invoice with the same name already exists");
    } else {
      showAlert("An error occurred.");
    }
  };

  const showAlert = (msg) => {
    toast.info(msg);
  };

  const TableRow = React.memo(
    ({ count, checkboxes, onCheckboxChange, rowIndex }) => {
      return (
        <tr>
          <td style={{"text-align":"center"}}>
            <input
              className="px-6 py-4 whitespace-nowrap"
              type="checkbox"
              checked={selectedBookingEntry.includes(rowIndex)}
              onChange={()=>onCheckboxChange(rowIndex)}
            />
          </td>
          <td style={{"text-align":"center"}}className="px-6 py-4 whitespace-nowrap" >
              {count}

            </td>
          {Object.values(table).map((value, colIndex) => (
            <td  className="px-6 py-4 whitespace-nowrap" key={colIndex}>
              {checkboxes[value]}

            </td>
          ))}
        </tr>
      );
    }
  );


  const sum = bookingEntry
  .filter(row => selectedBookingEntry.includes(row.waybill_id))
  .reduce((acc, row) => acc + row.booking_amount, 0);

  const handleCheckboxChange = (id) => {
    console.log('s',selectedBookingEntry)
    setSelectedBookingEntry(prevSelectedRows => {
      if (prevSelectedRows.includes(id)) {
        // Deselect the row if it was already selected
        return prevSelectedRows.filter(rowId => rowId !== id);
      } else {
        // Select the row if it wasn't already selected
        return [...prevSelectedRows, id];
      }
    });
    console.log('s',selectedBookingEntry)
  }
 async function createInvoice(){
    try {
      const response = await fetch(config.backendDomain + "/invoice", {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          customerId: selectedCustomer.value,
          bookingIds: selectedBookingEntry,
          totalAmount: sum,
        }),
      });
      const data = await response.json();

      if (response.ok) {
        setBookingEntry([])
        toast.success(data.message);     

      } else {
        handleErrorResponse(response.status, data.message);
      }
    } catch (error) {
      console.error("Error creating Invoice:", error);
      handleErrorResponse(500, "An error occurred.");
    }
  }
  return (
    <div className="relative bg-blue-50 animate__animated animate__fadeIn">
      <ToastContainer className="mt-14" />
      <div className="relative bg-blue-50">
        <h2 className="shadow-lg text-xl h-10 font-bold bg-blue-500 text-white mb-6 pl-3 pt-1">
          Create Invoice Gateway
        </h2>
      </div>
      <form onSubmit={handleSubmit} className="grid grid-cols-1 gap-2">
        <div className="mb-2">
          <ReactSelect
            id="customerId"
            options={filteredcustomer.map((customer) => ({
              value: customer.customer_id,
              label: customer.customer_name,
            }))}
            value={selectedCustomer}
            onChange={setSelectedCustomer}
            placeholder="Select Customer / Clients"
          />
        </div>

        {/* <div className="mb-2">
            <input
              type="Number"
              id="bill"
              className=" shadow-lg w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              placeholder="Invoice Number"
              value={InvoiceNumber}
              onChange={(e) => setInvoiceNumber(e.target.value)}
              required
            />
            </div> */}
        <div className="mb-2">
          <DatePicker
            selected={fromDate}
            onChange={(date) => setFromDate(date)}
            dateFormat="dd/MM/yyyy"
            placeholderText="From Date"
            className="shadow-lg w-[612px]  py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            required
          />
        </div>
        <div className="mb-2">
          <DatePicker
            selected={toDate}
            onChange={(date) => setToDate(date)}
            dateFormat="dd/MM/yyyy"
            placeholderText="To Date"
            className="shadow-lg w-[612px]  py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            required
          />
        </div>
        <div className="col-span-2 mb-2">
          <button
            className="bg-blue-500 hover:bg-blue-700 focus:bg-blue-700 text-white font-bold py-2 px-4 h-10 pt-1.5 w-40 rounded focus:outline-none focus:shadow-outline"
            type="submit"
          >
            Process 
          </button>
        </div>
      </form>
      <table className="min-w-full divide-y divide-gray-200 mt-4">
        <thead className="bg-gray-200">
          <tr>
            <th className="px-6 py-3 text-left  font-medium text-gray-500 uppercase tracking-wider"></th>
            <th className="px-6 py-3 text-left  font-medium text-gray-500 uppercase tracking-wider"> Sr. No.</th>
            {Object.keys(table).map((e) => (
              <th className="px-6 py-3 text-left  font-medium text-gray-500 uppercase tracking-wider">
                {e}
              </th>
            ))}
          </tr>
        </thead>
        {bookingEntry &&
          bookingEntry.map((row, index) => (
            <TableRow
              key={index}
              count={index+1}
              rowIndex={row.waybill_id}
              checkboxes={row}

               onCheckboxChange={handleCheckboxChange}
            />
          ))}
          <br/>
          {sum>0&& <tr>
          <td className="px-6 py-4 whitespace-nowrap"></td>
          <td className="px-6 py-4 whitespace-nowrap"></td>
          <td className="px-6 py-4 whitespace-nowrap"></td>
          <td className="px-6 py-4 whitespace-nowrap"></td>
          <td className="px-6 py-4 whitespace-nowrap"></td>
          <td className="px-6 py-4 whitespace-nowrap"></td>
          <td className="px-6 py-4 whitespace-nowrap"></td>
          <td className="px-6 py-4 whitespace-nowrap"></td>
          <td className="px-6 py-4 whitespace-nowrap"></td>
          <td className="px-6 py-4 whitespace-nowrap">Total</td>
          <td className="px-6 py-4 whitespace-nowrap">{sum}</td>
        </tr>}
       

      </table>
      { sum>0&&
        <button
            className='bg-blue-500 hover:bg-blue-700 focus:bg-blue-700 text-white font-bold py-2 px-4 h-10 pt-1.5 w-40 rounded focus:outline-none focus:shadow-outline'
            onClick={createInvoice} type='button'>
            Create Invoice
          </button>
      }
       
    </div>
  );
};

export default CreateInvoice;
