import React, { useState, useEffect } from 'react';
import ReactSelect from 'react-select'; 
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import 'tailwindcss/tailwind.css';
import { ToastContainer } from 'react-toastify';
import { toast } from 'react-toastify'; // Moved up
import 'react-toastify/dist/ReactToastify.css'; // Moved down
const config=require('../../config')
const Manifest = () => {
  const [inscanName, setInscanName] = useState('');
  const [manifests, setManifests] = useState([]);
  const [filteredManifests, setFilteredManifests] = useState([]);
  const [selectedManifests, setSelectedManifests] = useState([]);
  const [enteredManifest, setEnteredManifest] = useState('');
  const [manifestDetails, setManifestDetails] = useState(null);
  const [branches, setBranches] = useState([]);
  const [filteredBranches, setFilteredBranches] = useState([]);
  const [parentBranchId, setParentBranchId] = useState('');  
  const [parentBranch, setParentBranch] = useState('');
  const [bookingDate, setBookingDate] = useState(null);
  const [key, setKey] = useState(0);
  const token = localStorage.getItem('token');
 

  useEffect(() => {
  
    fetchInscan();
    fetchBranches();    
    fetchManifests(); 
  }, []);

  
  const fetchManifests = async () => {
    try {
      const response = await fetch(config.backendDomain+'/manifest', {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const data = await response.json();
      setManifests(data);
      setFilteredManifests(data);
    } catch (error) {
      console.error('Error fetching Manifests:', error);
    }
  };

  const handleManifestSearch = (searchTerm) => {
    const filtered = manifests.filter((manifest) =>
      manifest.manifest_number.toLowerCase().startsWith(searchTerm.toLowerCase())
    );
    console.log('Filtered manifest:', filtered);
    setFilteredManifests(filtered);
  };

  const fetchBranches = async () => {
    try {
      const response = await fetch(config.backendDomain+'/parentbranch', {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const data = await response.json();
      setBranches(data);
      setFilteredBranches(data);
    } catch (error) {
      console.error('Error fetching branches:', error);
    }
  };

  const fetchInscan = async () => {
    try {
      const response = await fetch(config.backendDomain+'/inscan', {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const data = await response.json();
      const sortedData = data.sort((a, b) => new Date(b.created_date) - new Date(a.created_date));
  
      // Generate the next inscan name
      if (sortedData.length > 0) {
        const lastInscan = sortedData[0];
        const lastInscanNumber = parseInt(lastInscan.inscan_name.replace('IN', ''), 10);
        const nextInscanNumber = lastInscanNumber + 1;
        const nextInscanName = `IN${nextInscanNumber}`;
        setInscanName(nextInscanName);
      } else {
        setInscanName('IN1');
      }
    } catch (error) {
      console.error('Error fetching Inscans:', error);
    }
  };
  

  const handleManifestChange = (selectedOption) => {
    const selectedManifest = manifests.find((manifest) => manifest.manifest_id === selectedOption.value);
    if (selectedManifest) {
      setEnteredManifest(selectedOption.label);
      setManifestDetails(selectedManifest);
    }
  };

  const addManifest = () => {
    if (manifestDetails) {
      // Check if the manifest is already added
      if (selectedManifests.some(manifest => manifest.manifest_id === manifestDetails.manifest_id)) {
        showAlert("This manifest is already added.");
      } else {
        setSelectedManifests((prevManifests) => [...prevManifests, manifestDetails]);
        setEnteredManifest('');
        setManifestDetails(null);
      }
    }
  };
  

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await fetch(config.backendDomain+'/inscan', {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({inscanName, bookingDate, parentBranchId, parentBranch, manifests: selectedManifests}), 
      });
      const data = await response.json();

      if (response.ok) {
        toast.success(data.message);
        setInscanName('');
        setSelectedManifests([]);
        setParentBranchId('');
        setParentBranch('');
        setBookingDate('');    
        fetchInscan();                       
        // setKey((prevKey) => prevKey + 2);
        
      } else {
        handleErrorResponse(response.status, data.message);
      }
    } catch (error) {
      console.error('Error creating manifest:', error);
      handleErrorResponse(500, 'An error occurred.');
    }
  };

  const handleErrorResponse = (status, message) => {
    if (status === 403) {
      showAlert('You are not authorized.');
    } else if (status === 400) {
      showAlert(message || 'Manifest with the same name already exists');
    } else {
      showAlert('An error occurred.');
    }
  };

  const showAlert = (msg) => {
    toast.info(msg);
  };
  

  const handleRemoveManifest = (manifestIdToRemove) => {
    setSelectedManifests(selectedManifests.filter(manifest => manifest.manifest_id !== manifestIdToRemove));
  };

  const handleEditManifest = (manifestIdToEdit) => {
    const manifestToEdit = selectedManifests.find(manifest => manifest.manifest_id === manifestIdToEdit);
    if (manifestToEdit) {
      setEnteredManifest(manifestToEdit.manifest_number);
      setManifestDetails(manifestToEdit);
      handleRemoveManifest(manifestIdToEdit);
    }
  };

  return (
    <div key={key} className="relative bg-gray-100 animate__animated animate__fadeIn">
       <ToastContainer className="mt-14" /> 
      <div className="container mx-auto py-8 mt-0">
        <div className="relative bg-blue-50">
          <h2 className="shadow-lg text-xl h-10 font-bold bg-blue-500 text-white mb-6 pl-3 pt-1">Inscan Gateway</h2>
          <form onSubmit={handleSubmit} className="grid grid-cols-3 gap-4">

          <div className="mb-2">
              <input
                type="text"
                id="manifestName"
                className="shadow-lg w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                placeholder="Enter Manifest Number"
                value={inscanName}
                onChange={(e) => setInscanName(e.target.value)}
                readOnly
                required
              />
            </div>

            <div className="mb-2">
              <DatePicker
                selected={bookingDate}
                onChange={(date) => setBookingDate(date)}
                dateFormat="MM/dd/yyyy"
                placeholderText="Select Manifest Date"
                className="shadow-lg w-[404px]  py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                required
              />
            </div>            

            <div className="col-span-1 mb-4">
              <ReactSelect
                id="parentBranchId"
                options={filteredBranches.map((branch) => ({
                  value: branch.branch_id,
                  label: branch.branch_name,
                }))}
                value={filteredBranches.find((branch) => branch.branch_name === parentBranchId)}
                onChange={(selectedOption) => {
                  const selectedBranch = branches.find((branch) => branch.branch_id === selectedOption.value);
                  if (selectedBranch) {
                    setParentBranchId(selectedOption.value);
                    setParentBranch(selectedOption.label);
                  }
                }}
                placeholder="Select Branch"
              />
            </div>

            <div className="mb-2">
              <ReactSelect
                id="manifestId"
                options={filteredManifests.map((manifest) => ({
                  value: manifest.waybill_id,
                  label: manifest.waybill_number,
                }))}
                value={filteredManifests.find((manifest) => manifest.waybill_id === enteredManifest)}
                onChange={handleManifestChange}
                placeholder="Select Manifests"
              />
            </div> 

            <div className="mb-2">
              <button
                type="button"
                className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 h-10 pt-1.5 w-40 ml-2 rounded focus:outline-none focus:shadow-outline"
                onClick={addManifest}
              >
                Add Manifest
              </button>
            <button
              className="bg-blue-500 ml-2 hover:bg-blue-700 focus:bg-blue-700 text-white font-bold py-2 px-4 h-10 pt-1.5 w-40 rounded focus:outline-none focus:shadow-outline"
              type="submit"
            >
              Create Manifest
            </button>
            </div>
          </form>

          {/* Display selected Manifests in a table */}
          <div className="mt-4">
            <h6 className="shadow-lg text-xs h-8 font-bold bg-blue-100 text-gray-400 mb-6 pl-3 pt-2">SELECTED ManifestS:</h6>
            <table className="min-w-full divide-y divide-gray-200 mt-6">
            <thead className="bg-gray-200">
                <tr className="bg-gray-200 text-gray-600 uppercase text-sm leading-normal">
                  <th className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Manifested Date</th>
                  <th className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">From Branch</th>
                  <th className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Waybill</th>
                  <th className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Packets</th>
                  <th className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Act.Weight</th>
                  <th className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Cha.Weight</th>
                  <th className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Actions</th>
                </tr>
              </thead>
              <tbody className="text-gray-600 text-sm font-light">
                {selectedManifests.map((manifest) => (
                  <tr key={manifest.manifest_id} className="border-b border-gray-200 hover:bg-gray-100">
                    <td className="px-4 py-4 whitespace-nowrap text-xs">{manifest.manifest_date}</td>
                    <td className="px-4 py-4 whitespace-nowrap text-xs">{manifest.parent_branch}</td>
                    <td className="px-4 py-4 whitespace-nowrap text-xs">{manifest.waybill_number}</td>
                    <td className="px-4 py-4 whitespace-nowrap text-xs">{manifest.packets}</td>
                    <td className="px-4 py-4 whitespace-nowrap text-xs">{manifest.actual_weight}</td>
                    <td className="px-4 py-4 whitespace-nowrap text-xs">{manifest.volumetric_weight}</td>
                    <td className="px-4 py-4 whitespace-nowrap text-xs">
                      <button
                        className="bg-yellow-500 hover:bg-yellow-700 text-white font-bold py-1 px-2 rounded mr-2"
                        onClick={() => handleEditManifest(manifest.manifest_id)}
                      >
                        Select
                      </button>
                      <button
                        className="bg-red-500 hover:bg-red-700 text-white font-bold py-1 px-2 rounded"
                        onClick={() => handleRemoveManifest(manifest.manifest_id)}
                      >
                        Delete
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Manifest;
