import React from "react";
import { Link } from 'react-router-dom';
import UserProfile from "../Authentication/UserProfile";

function Products() {
  return (
    <div className="bg-gray-100 min-h-screen flex flex-col">    
      <nav className="fixed top-0 left-0 right-0 bg-white h-16 border-b-2 border-gray-200 shadow-lg z-50 ">
    <div className="flex items-center justify-between">
      <div className="flex items-center">
      <Link to="/">
        <img src="logo.png" alt="Logo.png" className="h-16 ml-8 " />
        </Link>
        <div className="absolute top-0 right-0 mt-2 mr-8">
            <UserProfile />
          </div>
        </div></div></nav>
      
      <div className="bg-gray-100 min-h-screen flex flex-col">    
        <div className="bg-gradient-to-r from-blue-800 to-green-300 p-4 mt-16 text-white text-center">
          <h1 className="text-3xl font-bold">Welcome to NavDoot !</h1>
          <p className="text-lg">"Empowering logistics through SaaS: Seamlessly manage your supply chain with innovative software solutions."</p>
        </div>
            
        <div className="flex-grow flex items-center justify-center">
          <div className="max-w-6xl grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
        
          <div className="bg-white shadow-lg rounded-lg p-6 transform transition duration-300 hover:scale-105 flex flex-col justify-between h-full">
              <div>
                <h2 className="text-xl font-bold mb-4">CRM</h2>
                <p className="text-gray-700">Customer Tracking and management.</p>
                <p className="text-gray-700">Workflow Automation to Automate repetitive tasks</p>
                <p className="text-gray-700">Compliance and Regulatory Support.</p>
                <p className="text-gray-700">Employee management tasks</p>
                
              </div>
              <div className="mt-4 flex justify-center"></div>
                <Link to="/crm" className="inline-block bg-red-500 hover:bg-blue-600 text-white font-semibold py-2 px-16 rounded focus:outline-none">
                  Try Now
                </Link>
              </div>
            

         
            <div className="bg-white shadow-lg rounded-lg p-6 transform transition duration-300 hover:scale-105 flex flex-col justify-between h-full">
              <div>
              <h2 className="text-xl font-bold mb-4">Shipment Management</h2>
                <p className="text-gray-700">Order tracking and status updates</p>
                <p className="text-gray-700">Process orders from multiple channels (online store, marketplace, etc.)</p>
                <p className="text-gray-700">Automated order routing and assignment</p>
              </div>
              <div className="mt-auto flex justify-center"></div>
              <Link to="/Dashboard" className="mt-4 inline-block bg-green-500 hover:bg-blue-600 text-white font-semibold py-2 px-16 rounded focus:outline-none">
                Try Now
              </Link>
            </div>

            <div className="bg-white shadow-lg rounded-lg p-6 transform transition duration-300 hover:scale-105 flex flex-col justify-between h-full">
              <div>
              <h2 className="text-xl font-bold mb-4">ERP Accounting</h2>
              <p className="text-gray-700">Create Vouchers.</p>
              <p className="text-gray-700">Maintain Income and Expenditures.</p>
              <p className="text-gray-700">Balance Sheet and P&L account.</p>
              <p className="text-gray-700">Tax Management.</p>
              </div>
              <div className="mt-auto flex justify-center"></div>
              <Link to="/Accounting" className="mt-4 inline-block bg-yellow-500 hover:bg-blue-600 text-white font-semibold py-2 px-16 rounded focus:outline-none">
                Try Now
              </Link>
            </div>
         
            <div className="bg-white shadow-lg rounded-lg p-6 transform transition duration-300 hover:scale-105 flex flex-col justify-between h-full">
              <div>
              <h2 className="text-xl font-bold mb-4">Warehouse Management</h2>
              <p className="text-gray-700">Inventory management with barcode scanning.</p>
              <p className="text-gray-700">Warehouse layout optimization.</p>
              <p className="text-gray-700">Pick, pack, and ship functionalities.</p>
              </div>
              <div className="mt-auto flex justify-center"></div>
              <Link to="/warehouse-management" className="mt-4 inline-block bg-blue-400 hover:bg-blue-600 text-white font-semibold py-2 px-16 rounded focus:outline-none">
                Try Now
              </Link>
            </div>
          </div>
        </div>
      </div>
      
      <footer className="bg-blue-500 p-4 text-white text-center">
        &copy; 2024 My SaaS App. All rights reserved.
      </footer>
    </div>
  );
};

export default Products;
