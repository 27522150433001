import React, { useEffect, useRef } from 'react';
import Chart from 'chart.js/auto'; // Import Chart.js

const PurchaseDashboard = () => {
  // Sample data for the charts
  const lineChartData = {
    labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July'],
    datasets: [
      {
        label: 'Total Purchases',
        data: [100, 150, 100, 350, 300, 250, 400],
        fill: false,
        borderColor: 'rgb(75, 192, 192)',
        tension: 0.1,
      },
    ],
  };

  const barChartData = {
    labels: ['Product A', 'Product B', 'Product C', 'Product D', 'Product E'],
    datasets: [
      {
        label: 'Product Purchases',
        data: [500, 700, 300, 400, 600],
        backgroundColor: ['rgb(255, 99, 132)', 'rgb(54, 162, 235)', 'rgb(255, 205, 86)', 'rgb(75, 192, 192)', 'rgb(153, 102, 255)'],
        borderWidth: 1,
      },
    ],
  };

  const pieChartData = {
    labels: ['Product A', 'Product B', 'Product C'],
    datasets: [
      {
        label: 'Product Share',
        data: [30, 20, 50],
        backgroundColor: [], // Dynamic colors will be generated
        hoverOffset: 4,
      },
    ],
  };

  // Generate dynamic colors
  const dynamicColors = () => {
    const colors = [];
    for (let i = 0; i < pieChartData.labels.length; i++) {
      const color = `rgb(${Math.floor(Math.random() * 256)}, ${Math.floor(Math.random() * 256)}, ${Math.floor(Math.random() * 256)})`;
      colors.push(color);
    }
    return colors;
  };

  pieChartData.datasets[0].backgroundColor = dynamicColors(); // Assign dynamic colors to pie chart

  // Refs to store chart instances
  const lineChartRef = useRef(null);
  const barChartRef = useRef(null);
  const pieChartRef = useRef(null);

  useEffect(() => {
    if (lineChartRef.current) {
      // Destroy the previous chart instance if it exists
      lineChartRef.current.destroy();
    }
    if (barChartRef.current) {
      // Destroy the previous chart instance if it exists
      barChartRef.current.destroy();
    }
    if (pieChartRef.current) {
      // Destroy the previous chart instance if it exists
      pieChartRef.current.destroy();
    }

    // Initialize new chart instances
    const lineCtx = document.getElementById('lineChart').getContext('2d');
    const barCtx = document.getElementById('barChart').getContext('2d');
    const pieCtx = document.getElementById('pieChart').getContext('2d');

    lineChartRef.current = new Chart(lineCtx, {
      type: 'line',
      data: lineChartData,
    });

    barChartRef.current = new Chart(barCtx, {
      type: 'bar',
      data: barChartData,
      options: {
        scales: {
          y: {
            beginAtZero: true,
          },
        },
      },
    });

    pieChartRef.current = new Chart(pieCtx, {
      type: 'pie',
      data: pieChartData,
    });
  }, [lineChartData, barChartData, pieChartData]);

  return (
    <div className="container mx-auto px-4 py-8">
      {/* Cards displaying numbers */}
      <div className="grid grid-cols-1 md:grid-cols-4 gap-4 mb-6">
        <div className="bg-green-100 p-4 rounded-lg shadow-md">
          <h3 className="text-lg font-semibold">Total Purchases</h3>
          <p className="text-3xl font-bold text-green-500">450</p>
        </div>
        <div className="bg-blue-100 p-4 rounded-lg shadow-md">
          <h3 className="text-lg font-semibold">Net Profit</h3>
          <p className="text-3xl font-bold text-blue-500">$1200</p>
        </div>
        <div className="bg-purple-100 p-4 rounded-lg shadow-md">
          <h3 className="text-lg font-semibold">New Customers</h3>
          <p className="text-3xl font-bold text-purple-500">25</p>
        </div>
        <div className="bg-red-100 p-4 rounded-lg shadow-md">
          <h3 className="text-lg font-semibold">Returns</h3>
          <p className="text-3xl font-bold text-red-500">10</p>
        </div>
      </div>

      {/* Sales Charts */}
      <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
        {/* Line Chart */}
        <div className="bg-white p-6 rounded-lg shadow-md">
          <h2 className="text-xl font-semibold mb-4">Total Purchases Trend</h2>
          <canvas id="lineChart" width="400" height="400"></canvas>
        </div>

        {/* Bar Chart */}
        <div className="bg-white p-6 rounded-lg shadow-md">
          <h2 className="text-xl font-semibold mb-4">Product Purchases Overview</h2>
          <canvas id="barChart" width="400" height="400"></canvas>
        </div>

        {/* Pie Chart */}
        <div className="bg-white p-6 rounded-lg shadow-md">
          <h2 className="text-xl font-semibold mb-4">Product Share</h2>
          <canvas id="pieChart" width="400" height="200"></canvas>
        </div>
      </div>

      {/* Purchase Analytical Report */}
      <div className="bg-white rounded-lg shadow-md overflow-hidden mt-8">
        <h2 className="text-xl font-semibold bg-gray-200 py-2 px-4">Purchase Analytical Report</h2>
        <table className="w-full">
          <thead>
            <tr>
              <th className="px-4 py-2">Product</th>
              <th className="px-4 py-2">Quantity</th>
              <th className="px-4 py-2">Amount</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td className="border px-4 py-2">Product A</td>
              <td className="border px-4 py-2">20</td>
              <td className="border px-4 py-2">$500</td>
            </tr>
            <tr>
              <td className="border px-4 py-2">Product B</td>
              <td className="border px-4 py-2">15</td>
              <td className="border px-4 py-2">$700</td>
            </tr>
            <tr>
              <td className="border px-4 py-2">Product C</td>
              <td className="border px-4 py-2">25</td>
              <td className="border px-4 py-2">$300</td>
            </tr>
          </tbody>
        </table>
      </div>

      {/* Recent Order Details */}
      <div className="bg-white rounded-lg shadow-md overflow-hidden mt-8">
        <h2 className="text-xl font-semibold bg-gray-200 py-2 px-4">Recent Order Details</h2>
        <table className="w-full">
          <thead>
            <tr>
              <th className="px-4 py-2">Order ID</th>
              <th className="px-4 py-2">Customer</th>
              <th className="px-4 py-2">Product</th>
              <th className="px-4 py-2">Amount</th>
              <th className="px-4 py-2">Date</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td className="border px-4 py-2">1</td>
              <td className="border px-4 py-2">John Doe</td>
              <td className="border px-4 py-2">Product A</td>
              <td className="border px-4 py-2">$200</td>
              <td className="border px-4 py-2">2024-04-25</td>
            </tr>
            <tr>
              <td className="border px-4 py-2">2</td>
              <td className="border px-4 py-2">Jane Smith</td>
              <td className="border px-4 py-2">Product B</td>
              <td className="border px-4 py-2">$300</td>
              <td className="border px-4 py-2">2024-04-24</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default PurchaseDashboard;
