import React, { useState, useEffect } from 'react';
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/outline';
import ReactSelect from 'react-select'; // Import ReactSelect
import { ToastContainer } from 'react-toastify';
import { toast } from 'react-toastify'; // Moved up
import 'react-toastify/dist/ReactToastify.css'; // Moved down
const config=require('../../config')

const Product = () => {
  const [productName, setProductName] = useState('');
  const [paymentMode, setPaymentMode] = useState('');
  const [numberInput, setNumberInput] = useState('');
  const [countries, setCountries] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [showEditPopup, setShowEditPopup] = useState(false); // State for controlling the edit popup
  const [editItemId, setEditItemId] = useState(null); // State for storing the ID of the item being edited
  const [editItemName, setEditItemName] = useState(''); // State for storing the new name for editing
  const itemsPerPage = 5;
  const token = localStorage.getItem('token');

  useEffect(() => {
    fetchData(); // Fetch initial data when component mounts
  }, []);

  const fetchData = async () => {
    try {
      const response = await fetch(config.backendDomain+'/product', {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      let data = await response.json();
      // Sort the data by created_date in descending order
      data = data.sort((a, b) => new Date(b.created_date) - new Date(a.created_date));
      setCountries(data);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await fetch(config.backendDomain+'/product', {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ productName, numberInput, paymentMode }),
      });
      const data = await response.json();

      if (response.ok) {
        toast.success(data.message);
        setProductName('');
        setNumberInput('');
        setPaymentMode('');
        fetchData(); // Fetch latest data after successful submission
      } else {
        handleErrorResponse(response.status);
      }
    } catch (error) {
      console.error('Error creating ProductName:', error);
      handleErrorResponse(500); // Internal server error
    }
  };

  const handleEdit = async (id, newName) => {
    try {
      const response = await fetch(config.backendDomain+`/product/${id}`, {
        method: 'PUT',
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ productName: newName }),
      });
      const data = await response.json();

      if (response.ok) {
        toast.success(data.message);
        fetchData(); // Fetch latest data after successful edit
        handleCloseEditPopup();
      } else {
        handleErrorResponse(response.status);
      }
    } catch (error) {
      console.error('Error updating productName:', error);
      handleErrorResponse(500); // Internal server error
    }
  };

  const handleDelete = async (id) => {
    try {
      const response = await fetch(config.backendDomain+`/product/${id}`, {
        method: 'DELETE',
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const data = await response.json();

      if (response.ok) {
        toast.success(data.message);
        fetchData(); // Fetch latest data after successful deletion
      } else {
        handleErrorResponse(response.status);
      }
    } catch (error) {
      console.error('Error deleting productName:', error);
      handleErrorResponse(500); // Internal server error
    }
  };

  const handleErrorResponse = (status, message) => {
    if (status === 403) {
      showAlert('You are not authorized.');
    } else if (status === 400) {
      showAlert(message || 'productName with the same name already exists');
    } else {
      showAlert('An error occurred.');
    }
  };

  const showAlert = (msg) => {
    toast.info(msg);
  };

  const handleOpenEditPopup = (id, newName) => {
    setEditItemId(id);
    setEditItemName(newName);
    setShowEditPopup(true);
  };

  const handleCloseEditPopup = () => {
    setEditItemId(null);
    setEditItemName('');
    setShowEditPopup(false);
  };

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = countries.slice(indexOfFirstItem, indexOfLastItem);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  return (
    <div className="relative bg-gray-100 animate__animated animate__fadeIn">
      <ToastContainer className="mt-14" /> 
      <div className="container mx-auto py-8 mt-0">
        <div className="relative bg-blue-50">
          <h2 className="shadow-lg text-xl h-10 font-bold bg-blue-500 text-white mb-6 pl-3 pt-1">Product Gateway</h2>
          <form onSubmit={handleSubmit}>
            <div className="mb-4">
              <input
                type="text"
                id="productName"
                pattern="[a-zA-Z0-9 ]+"
                title="Please enter only letters, numbers, and spaces"
                className="shadow-lg w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                placeholder="Enter Product Name"
                value={productName}
                onChange={(e) => setProductName(e.target.value)}
                required
              />
            </div>
            <div className="mb-4">
                <input
                type="text"
                id="numberInput"
                pattern="[0-9]{3}"
                title="Please enter numbers only with starting 3 digits"
                className="shadow-lg w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                placeholder="Series Starts From "
                value={numberInput}
                onChange={(e) => setNumberInput(e.target.value)}
                required
                />
            </div>
            <div className="mb-4">
            <select
              id="allowInscan"
              className="shadow-lg w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              value={paymentMode}
              onChange={(e) => setPaymentMode(e.target.value)}
              required
            >
              <option value="">Payment Mode</option>
              <option value="Credit">Credit</option>
              <option value="Cash">Cash</option>
              <option value="Topay">ToPay</option>
              <option value="Cash on Delivery">Cash on Delivery</option>
            </select>
          </div>


            <button
              className="bg-blue-500 hover:bg-blue-700 focus:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
              type="submit"
            >
              Create Product
            </button>
          </form>
          <table className="min-w-full divide-y divide-gray-200 mt-6">
            <thead className="bg-gray-200">
              <tr>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Product ID</th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Product Name</th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Starts From</th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Payment Type</th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Created By</th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Created Date</th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Modified Date</th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Actions</th>
              </tr>
            </thead>
            <tbody className="bg-white divide-y divide-gray-200">
              {currentItems.map((product) => (
                <tr key={product.product_id}>
                  <td className="px-6 py-4 whitespace-nowrap text-xs">{product.product_id}</td>
                  <td className="px-6 py-4 whitespace-nowrap text-xs">{product.product_name}</td>
                  <td className="px-6 py-4 whitespace-nowrap text-xs">{product.number_input}</td>
                  <td className="px-6 py-4 whitespace-nowrap text-xs">{product.payment_type}</td>
                  <td className="px-6 py-4 whitespace-nowrap text-xs">{product.user_id}</td>
                  <td className="px-6 py-4 whitespace-nowrap text-xs">{product.created_date}</td>
                  <td className="px-6 py-4 whitespace-nowrap text-xs">{product.modified_date}</td>
                  <td className="px-6 py-4 whitespace-nowrap text-xs">
                    <button
                      className="bg-yellow-500 hover:bg-yellow-700 text-white font-bold py-1 px-2 rounded focus:outline-none focus:shadow-outline mr-2"
                      onClick={() => handleOpenEditPopup(product.product_id, product.product_name)} 
                    >
                      Edit
                    </button>
                    <button
                      className="bg-red-500 hover:bg-red-700 text-white font-bold py-1 px-2 rounded focus:outline-none focus:shadow-outline"
                      onClick={() => handleDelete(product.product_id)}
                    >
                      Delete
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          <div className="flex justify-start items-center mt-4">
            <div>
              {currentPage > 1 && (
                <button
                  className="mr-2 bg-blue-500 hover:bg-blue-600 text-white font-bold py-1 px-2 rounded focus:outline-none focus:shadow-outline"
                  onClick={() => handlePageChange(currentPage - 1)}
                >
                  <ChevronLeftIcon className="h-5 w-5" />
                </button>
              )}
            </div>
            <div className="flex space-x-2">
              {[1, 2, 3, 4].map((page) => (
                <button
                  key={page}
                  className={`bg-blue-500 hover:bg-blue-600 text-white font-bold py-1 px-2 rounded focus:outline-none focus:shadow-outline ${
                    currentPage === page ? 'bg-orange-400' : ''
                  }`}
                  onClick={() => handlePageChange(page)}
                >
                  {page}
                </button>
              ))}
            </div>
            <div>
              {currentPage < Math.ceil(countries.length / itemsPerPage) && (
                <button
                  className="ml-2 bg-blue-500 hover:bg-blue-600 text-white font-bold py-1 px-2 rounded focus:outline-none focus:shadow-outline"
                  onClick={() => handlePageChange(currentPage + 1)}
                >
                  <ChevronRightIcon className="h-5 w-5" />
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
  
      {/* Edit Popup */}
      {showEditPopup && (
        <div className="fixed z-10 inset-0 overflow-y-auto flex items-center justify-center">
          <div className="fixed inset-0 bg-gray-500 opacity-75"></div>
          <div className="relative bg-white w-96 rounded-lg p-8">
            <h2 className="text-lg font-bold mb-4">Edit Product Name</h2>
            <input
              type="text"
              value={editItemName}
              onChange={(e) => setEditItemName(e.target.value)}
              className="shadow-lg w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              placeholder="Enter New Product Name"
            />
            <div className="mt-4 flex justify-end">
              <button
                onClick={() => handleEdit(editItemId, editItemName)}
                className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline mr-2"
              >
                Save Changes
              </button>
              <button
                onClick={handleCloseEditPopup}
                className="bg-gray-400 hover:bg-gray-600 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
export default Product;
