import React, { useState } from "react";
import { Helmet } from "react-helmet";
import { Link } from 'react-router-dom';
import aniGif from '../Assets/ani.gif';
import '../Styles/App.css';
import Testimonials from '../Home/Testimonials';
import Extensions from "../Home/Extensions";
import SocialMedia from "../Home/SocialMedia";
import Reason from "../Home/Reason";
import Hero from "../Home/Hero";
import People from "../Home/People";


function Home() {


  return (
    <div className="bg-gray-100 min-h-screen flex flex-col relative ">
      <nav className="fixed top-0 left-0 right-0 bg-white border-b-2 border-gray-200 shadow-lg z-50 py-4 hidden md:block">
        <div className="flex items-center justify-between">
          <div className="flex items-center">
            <Link to="/">
              <img src="logo.png" alt="Logo.png" className="h-16 ml-16 hidden md:block" onClick={() => window.location.reload()} />
            </Link>

            {/* Company OVERVIEW DROPDOWN */}
            <div className="relative group">
              <button className="bg-white hover:text-blue-600 text-gray-700 font-semibold ml-16 py-2 px-4 rounded focus:outline-none">
                Company Overview
              </button>
              <div className="absolute left-20 mt-2 bg-white border border-gray-200 shadow-lg rounded-lg w-96 invisible group-hover:visible opacity-0 group-hover:opacity-100 transition-all duration-300 ease-in-out">
                <div className="p-4 border-b hover:bg-gray-100 transition-all duration-300 ease-in-out">
                  <Link to="/Aboutus">
                    <div className="text-gray-800 font-semibold hover:text-blue-600 transition duration-300 ease-in-out">
                      Aboutus
                    </div>
                  </Link>
                  <p className="text-gray-600 text-sm mt-2 opacity-0 group-hover:opacity-100 max-h-0 group-hover:max-h-20 overflow-hidden transition-all duration-300 ease-in-out">
                    Learn about our journey, mission, and values that drive our success.
                  </p>
                </div>
                <div className="p-4 border-b hover:bg-gray-100 transition-all duration-300 ease-in-out">
                  <Link to="/Events">
                    <div className="text-gray-800 font-semibold hover:text-blue-600 transition duration-300 ease-in-out">
                      Events
                    </div>
                  </Link>
                  <p className="text-gray-600 text-sm mt-2 opacity-0 group-hover:opacity-100 max-h-0 group-hover:max-h-20 overflow-hidden transition-all duration-300 ease-in-out">
                    Stay updated on upcoming events, workshops, and seminars.
                  </p>
                </div>
                <div className="p-4 border-b hover:bg-gray-100 transition-all duration-300 ease-in-out">
                  <Link to="/Carreers">
                    <div className="text-gray-800 font-semibold hover:text-blue-600 transition duration-300 ease-in-out">
                      Careers
                    </div>
                  </Link>
                  <p className="text-gray-600 text-sm mt-2 opacity-0 group-hover:opacity-100 max-h-0 group-hover:max-h-20 overflow-hidden transition-all duration-300 ease-in-out">
                    Join our team and explore exciting career opportunities with us.
                  </p>
                </div>
                <div className="p-4 hover:bg-gray-100 transition-all duration-300 ease-in-out">
                  <Link to="/FAQ">
                    <div className="text-gray-800 font-semibold hover:text-blue-600 transition duration-300 ease-in-out">
                      FAQ
                    </div>
                  </Link>
                  <p className="text-gray-600 text-sm mt-2 opacity-0 group-hover:opacity-100 max-h-0 group-hover:max-h-20 overflow-hidden transition-all duration-300 ease-in-out">
                    Find answers to frequently asked questions about our services and platform.
                  </p>
                </div>
              </div>
            </div>
   
            {/* PRODUCT DROPDOWN */}
            <div className="relative group">
              <button className="bg-white hover:text-blue-600 text-gray-700 font-semibold ml-4 py-2 px-4 rounded focus:outline-none">
                Products
              </button>
              <div className="absolute left-8 mt-2 bg-white border border-gray-200 shadow-lg rounded-lg w-96 invisible group-hover:visible opacity-0 group-hover:opacity-100 transition-all duration-300 ease-in-out">
                <div className="p-4 border-b hover:bg-gray-100 transition-all duration-300 ease-in-out">
                  <Link to="/CRM">
                    <div className="text-gray-800 font-semibold hover:text-blue-600 transition duration-300 ease-in-out">
                      CRM
                    </div>
                  </Link>
                  <p className="text-gray-600 text-sm mt-2 opacity-0 group-hover:opacity-100 max-h-0 group-hover:max-h-20 overflow-hidden transition-all duration-300 ease-in-out">
                    Manage customer relationships, automate processes, and enhance
                    client satisfaction.
                  </p>
                </div>
                <div className="p-4 border-b hover:bg-gray-100 transition-all duration-300 ease-in-out">
                  <Link to="/ShipmentManagement">
                    <div className="text-gray-800 font-semibold hover:text-blue-600 transition duration-300 ease-in-out">
                      Shipment Management
                    </div>
                  </Link>
                  <p className="text-gray-600 text-sm mt-2 opacity-0 group-hover:opacity-100 max-h-0 group-hover:max-h-20 overflow-hidden transition-all duration-300 ease-in-out">
                    Optimize your shipments with real-time tracking and carrier
                    integrations.
                  </p>
                </div>
                <div className="p-4 border-b hover:bg-gray-100 transition-all duration-300 ease-in-out">
                  <Link to="/ERPAccounting">
                    <div className="text-gray-800 font-semibold hover:text-blue-600 transition duration-300 ease-in-out">
                      ERP Accounting
                    </div>
                  </Link>
                  <p className="text-gray-600 text-sm mt-2 opacity-0 group-hover:opacity-100 max-h-0 group-hover:max-h-20 overflow-hidden transition-all duration-300 ease-in-out">
                    Streamline accounting processes with real-time financial
                    insights.
                  </p>
                </div>
                <div className="p-4 hover:bg-gray-100 transition-all duration-300 ease-in-out">
                  <Link to="/WarehouseManagement">
                    <div className="text-gray-800 font-semibold hover:text-blue-600 transition duration-300 ease-in-out">
                      Warehouse Management
                    </div>
                  </Link>
                  <p className="text-gray-600 text-sm mt-2 opacity-0 group-hover:opacity-100 max-h-0 group-hover:max-h-20 overflow-hidden transition-all duration-300 ease-in-out">
                    Enhance inventory accuracy and reduce operating costs with
                    robust warehouse tools.
                  </p>
                </div>
              </div>
            </div>

            {/* PRICING DROPDOWN */}

            <div className="relative group">
              <button className="bg-white hover:text-blue-600 text-gray-700 font-semibold ml-4 py-2 px-4 rounded focus:outline-none">
                Pricing
              </button>
              <div className="absolute left-8 mt-2 bg-white border border-gray-200 shadow-lg rounded-lg w-[780px] invisible group-hover:visible opacity-0 group-hover:opacity-100 transition-all duration-300 ease-in-out">
                <div className="p-4 border-b hover:bg-gray-100 transition-all duration-300 ease-in-out">
                  <div className="flex justify-between items-center">
                  <Link to="/SubscriptionPage">
                    <div className="text-gray-800 hover:text-blue-600 font-semibold text-lg">
                      Shipment Management</div></Link>
                    <div className="text-gray-600 text-sm">Starting at $19.99/month</div>
                  </div>
                  <p className="text-gray-600 text-sm mt-2 opacity-0 group-hover:opacity-100 max-h-0 group-hover:max-h-20 overflow-hidden transition-all duration-300 ease-in-out">
                    Get technical assistance, troubleshoot issues, and resolve your queries efficiently. 
                  </p>
                </div>
                <div className="p-4 border-b hover:bg-gray-100 transition-all duration-300 ease-in-out">
                  <div className="flex justify-between items-center">
                  <Link to="/">
                    <div className="text-gray-800 hover:text-blue-600  font-semibold text-lg">Warehouse Management</div></Link>
                    <div className="text-gray-600 text-sm">Starting at $19.99/month</div>
                  </div>
                  <p className="text-gray-600 text-sm mt-2 opacity-0 group-hover:opacity-100 max-h-0 group-hover:max-h-20 overflow-hidden transition-all duration-300 ease-in-out">
                    Reach out to our sales team for inquiries, pricing details, and product demos.
                  </p>
                </div>
                
                {/* Pricing and Payment Partner Information */}
                <div className="p-4 border-b hover:bg-gray-100 transition-all duration-300 ease-in-out">
                <Link to="/">
                  <div className="text-gray-800 hover:text-blue-600 font-semibold text-lg">Pricing Details & Payment Partners</div></Link>
                  <p className="text-gray-600 text-sm mt-2">
                    Our pricing is flexible and designed to suit businesses of all sizes. We offer monthly and annual subscription plans with the best value for your needs. 
                    Payment options are supported through trusted partners such as PayPal, Stripe, and Credit Cards.
                  </p>
                </div>

                {/* Product Pricing Table */}
                <div className="p-4 border-b hover:bg-gray-100 transition-all duration-300 ease-in-out">
                  <div className="overflow-x-auto">
                    <table className="min-w-full table-auto border-collapse border border-gray-200">
                      <thead>
                        <tr className="bg-gray-100">
                          <th className="border border-gray-300 px-4 py-2 text-left">Product</th>
                          <th className="border border-gray-300 px-4 py-2 text-left">Description</th>
                          <th className="border border-gray-300 px-4 py-2 text-left">Pricing</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td className="border border-gray-300 px-4 py-2">Shipment Management</td>
                          <td className="border border-gray-300 px-4 py-2">Track and manage shipments efficiently with real-time updates.</td>
                          <td className="border border-gray-300 px-4 py-2">$20/month</td>
                        </tr>
                        <tr>
                          <td className="border border-gray-300 px-4 py-2">Warehouse Management</td>
                          <td className="border border-gray-300 px-4 py-2">Manage inventory, order fulfillment, and warehouse operations seamlessly.</td>
                          <td className="border border-gray-300 px-4 py-2">$20/month</td>
                        </tr>
                        <tr>
                          <td className="border border-gray-300 px-4 py-2">Logistics Service Bundle</td>
                          <td className="border border-gray-300 px-4 py-2">Combine both shipment and warehouse services for better savings.</td>
                          <td className="border border-gray-300 px-4 py-2">$30/month</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>

            {/* CONTACT DROPDOWN */}

            <div className="relative group">
              <button className="bg-white hover:text-blue-600 text-gray-700 font-semibold ml-4 py-2 px-4 rounded focus:outline-none">
                Contact
              </button>
              <div className="absolute left-8 mt-2 bg-white border border-gray-200 shadow-lg rounded-lg w-96 invisible group-hover:visible opacity-0 group-hover:opacity-100 transition-all duration-300 ease-in-out">
                <div className="p-4 border-b hover:bg-gray-100 transition-all duration-300 ease-in-out">
                  <Link to="/ContactForm">
                    <div className="text-gray-800 font-semibold hover:text-blue-600 transition duration-300 ease-in-out">
                      Contact Support
                    </div>
                  </Link>
                  <p className="text-gray-600 text-sm mt-2 opacity-0 group-hover:opacity-100 max-h-0 group-hover:max-h-20 overflow-hidden transition-all duration-300 ease-in-out">
                    Get technical assistance, troubleshoot issues, and resolve your queries efficiently.
                  </p>
                </div>
                <div className="p-4 border-b hover:bg-gray-100 transition-all duration-300 ease-in-out">
                  <Link to="/ContactForm">
                    <div className="text-gray-800 font-semibold hover:text-blue-600 transition duration-300 ease-in-out">
                      Contact Sales
                    </div>
                  </Link>
                  <p className="text-gray-600 text-sm mt-2 opacity-0 group-hover:opacity-100 max-h-0 group-hover:max-h-20 overflow-hidden transition-all duration-300 ease-in-out">
                    Reach out to our sales team for inquiries, pricing details, and product demos.
                  </p>
                </div>
              </div>
            </div>
          </div>

          {/* AUTHENTICATION     */}
          <div className="flex space-x-4 mr-8 z-50">
            <button className="bg-blue-600 hover:bg-red-600 text-white font-semibold py-2 px-4 rounded focus:outline-none hidden md:block">
              <Link to="/signin">Login</Link>
            </button>
            <button className="bg-green-600 hover:bg-red-600 text-white font-semibold py-2 px-4 rounded focus:outline-none hidden md:block">
              <Link to="/SignupPage">Get Started</Link>
            </button>
            </div>
          </div>
        </nav>

      {/* Logo and Login/Get Started Buttons (Mobile View) */}
      <div className="flex flex-col items-center justify-center mt-8 px-4 py-2 md:hidden">
        <img src="logo.png" alt="Logo.png" className="h-54 rounded-lg shadow-lg" />

        <div className="flex flex-col md:flex-row justify-center mt-16 md:justify-start w-full space-y-4 md:space-x-4">
          <button className="bg-blue-500 hover:bg-blue-600 text-white font-semibold py-2 px-4 rounded focus:outline-none">
            <Link to="/signin">Login</Link>
          </button>
          <button className="bg-blue-500 hover:bg-blue-600 text-white font-semibold py-2 px-4 rounded focus:outline-none">
            <Link to="/SignupPage">Get Started</Link>
          </button>
        </div>
        <div className="max-w-md mx-auto ">
          <input
            type="email"
            placeholder="Enter your email"
            className="w-full h-12 text-gray-500 border-gray-300 rounded-md py-2 px-4 focus:outline-none shadow-lg focus:border-blue-500 mt-16 transition duration-300 ease-in-out hover:border-blue-600 focus:ring-2 focus:ring-blue-500"
          />
          <button className="w-full h-12 bg-red-500 hover:bg-blue-600 text-white font-semibold py-2 px-4 rounded focus:outline-none mt-6 shadow-lg transition duration-300 ease-in-out transform hover:scale-105">
            <Link to="/SignupPage">Create Free Account</Link>
          </button>
          <footer className="bg-gradient-to-r from-blue-800 to-green-300 p-4 mt-48 text-white text-center rounded focus:outline-none">
            &copy; 2024 My SaaS App. All rights reserved.
          </footer>
        </div>
      </div>

      <div className="relative flex-grow div-color rounded-lg shadow-lg mt-24">
  <Helmet>
    <title>Welcome to NavDoot | Seamless Transport, Courier and Logistics Management</title>
    <meta name="description" content="NavDoot: Streamline your courier and logistics with our innovative SaaS platform. Real-time insights, integration, and efficiency for your business growth." />
    <meta name="keywords" content="NavDoot, supply chain, SaaS platform, logistics, Courier, supply chain management, real-time insights" />
    <meta name="author" content="NavDoot Team" />
    <meta property="og:title" content="Welcome to NavDoot" />
    <meta property="og:description" content="Your gateway to logistics, courier and seamless supply chain management!" />
    <meta property="og:image" content="path-to-image-for-social-sharing" />
    <meta property="og:url" content="https://navdoot.com" />
    <meta name="twitter:card" content="summary_large_image" />
  </Helmet>

  <img src={aniGif} alt="Logistics GIF" className="absolute left-0 top-1/2 w-1/2 transform -translate-y-1/2 hidden md:block" />
  <div className="absolute right-0 top-1/9 w-1/2 p-8 text-gray-800 text-center">
    <h1 className="text-5xl font-bold mt-16 mb-4 animate__animated animate__fadeInDown hidden md:block">
      <span className="text-4xl text-gray-800">Streamline logistics with </span>
      <span className="text-gradient bg-gradient-to-r from-blue-800 to-green-500 bg-clip-text text-transparent">  NavDoot!</span>
    </h1>

    <h4 className="text-2xl text-orange-700 mt-12 font-bold mt-2 animate__animated animate__fadeIn animate__delay-1s opacity-100 hidden md:block">
  Your Gateway of Courier and Logistics management!
</h4>
 
    <p className="text-1xl text-right mt-8 mb-16 mr-16 animate__animated animate__fadeInUp hidden md:block">
      Our innovative SaaS platform streamlines your operations, empowering you to focus on what matters most: growing your business. Experience seamless integration, real-time insights, and unparalleled efficiency with NavDoot.
    </p>
    <div className="max-w-md mx-auto">
      <input
        type="email"
        placeholder="Enter your email"
        className="w-full h-12 text-gray-500 border-gray-300 rounded-md py-2 px-4 focus:outline-none shadow-lg focus:border-blue-500 mb-4 transition duration-300 ease-in-out hover:border-blue-600 focus:ring-2 focus:ring-blue-500 hidden md:block"
      />
      <button className="w-full h-12 bg-red-600 hover:bg-blue-600 text-white font-semibold py-2 px-4 rounded focus:outline-none shadow-lg transition duration-300 ease-in-out transform hover:scale-105 hidden md:block">
        <Link to="/SignupPage">Create Free Account</Link>
      </button>
      <nav className="max-w-screen border-b-2 border-gray-200 shadow-lg z-50 py-4 mt-32 testimonial-div hidden md:block div-color">
        <Testimonials />
        <div className="max-w-screen border-b-2 border-gray-200 shadow-lg">
          <Hero />
        </div>
        <div className="bg-gradient-to-r from-blue-800 to-green-300 max-w-screen border-b-2 border-gray-200 shadow-lg">
          <Reason />
        </div>
        <div className="max-w-screen border-b-2 border-gray-200 shadow-lg">
          <People />
        </div>
        <Extensions />
        <SocialMedia />
        <footer className="h-16 bg-gradient-to-r from-blue-800 to-green-300 p-4 mt-16 text-white text-center">
          &copy; NavDoot. All rights reserved.
        </footer>
      </nav>
    </div>
  </div>
      </div>
      </div>
  );
};

export default Home;
