import React from "react";
import { Link } from "react-router-dom";

function Events() {
  const events = [
    {
      title: "Global Supply Chain Conference 2025",
      date: "March 15, 2025",
      location: "New York, USA",
      description:
        "Join industry leaders to explore the latest trends and technologies in supply chain management.",
      id: "global-supply-chain",
    },
    {
      title: "NavDoot Innovation Day",
      date: "Feb 22, 2024",
      location: "Bhilai, India",
      description:
        "A showcase of NavDoot's latest products and services, with hands-on demonstrations and expert panels.",
      id: "innovation-day",
    },
    {
      title: "Sustainability in Logistics Webinar",
      date: "Nov 24, 2024",
      location: "New Delhi, India",
      description:
        "Discover how to make your logistics operations more sustainable and environmentally friendly.",
      id: "sustainability-webinar",
    },
  ];

  return (
    <div className="bg-gray-50">
      {/* Fixed Navbar */}
      <nav className="sticky top-0 left-0 right-0 bg-white border-b-2 border-gray-200 shadow-lg z-50">
        <div className="container mx-auto flex justify-between items-center px-8 py-4">
          <Link to="/" className="text-3xl font-bold text-blue-800">
          <img src="logo.png" alt="Logo.png" className="h-16 ml-8 " />
          </Link>
          <div className="hidden md:flex space-x-6">
            <Link
              to="/signin"
              className="bg-blue-600 hover:bg-red-600 text-white font-semibold py-2 px-4 rounded transition duration-300"
            >
              Login
            </Link>
            <Link
              to="/SignupPage"
              className="bg-green-600 hover:bg-red-600 text-white font-semibold py-2 px-4 rounded transition duration-300"
            >
              Get Started
            </Link>
          </div>
        </div>
      </nav>

      {/* Page Header */}
      <header className="pt-24 pb-12 text-center bg-gradient-to-r from-blue-800 to-green-300 text-white">
        <h1 className="text-5xl font-bold">Upcoming Events</h1>
        <p className="text-lg mt-4">Join us at our exciting events worldwide!</p>
      </header>

      {/* Event List */}
      <div className="container mx-auto px-4 py-12">
        <div className="space-y-12">
          {events.map((event, index) => (
            <div
              key={index}
              id={event.id}
              className="bg-white rounded-lg shadow-lg overflow-hidden hover:shadow-xl transition-shadow duration-300"
            >
              {/* Event Header */}
              <div className="flex items-center justify-between p-6 bg-blue-100">
                <h2 className="text-2xl font-bold text-blue-800">{event.title}</h2>
                <span className="text-sm text-gray-600">{event.date}</span>
              </div>

              {/* Event Body */}
              <div className="p-6">
                <p className="text-gray-600 mb-3">
                  <strong>Location:</strong> {event.location}
                </p>
                <p className="text-gray-700 mb-4">{event.description}</p>

                {/* Collapsible Section */}
                <button
                  type="button"
                  className="bg-blue-600 text-white px-4 py-2 rounded-lg shadow hover:bg-blue-700 transition-all duration-300"
                  data-bs-toggle="collapse"
                  data-bs-target={`#collapse-${event.id}`}
                  aria-expanded="false"
                  aria-controls={`collapse-${event.id}`}
                >
                  Learn More
                </button>
                <div
                  id={`collapse-${event.id}`}
                  className="collapse mt-4 border-t-2 pt-4 text-gray-700"
                >
                  <p>
                    <strong>Event Details:</strong> Additional information about this event will be available soon.
                  </p>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>

      {/* Call-to-Action Section */}
      <div className="mt-12 bg-gradient-to-r from-blue-500 to-blue-700 text-white p-8 rounded-lg shadow-md text-center">
        <h2 className="text-3xl font-bold mb-4">Stay Updated on Our Events</h2>
        <p className="text-lg mb-6">
          Never miss an opportunity to connect and learn with NavDoot. Subscribe
          to our newsletter for the latest event updates.
        </p>
        <button className="bg-white text-blue-700 px-6 py-3 rounded-full font-semibold shadow hover:bg-gray-200 transition-all duration-300">
          Subscribe Now
        </button>
      </div>

      {/* ScrollSpy Section */}
      <div className="mt-[20px]fixed right-0 top-1/4 transform -translate-y-1/4 bg-white shadow-lg p-4 rounded-lg z-50">
        <h3 className="font-semibold text-blue-800">Quick Links</h3>
        <ul className="space-y-4 mt-4">
          {events.map((event) => (
            <li key={event.id}>
              <a
                href={`#${event.id}`}
                className="text-blue-600 hover:text-blue-800 transition duration-300"
              >
                {event.title}
              </a>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
}

export default Events;
