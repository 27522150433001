import React, { useState } from 'react';
import { ToastContainer } from 'react-toastify';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const LogoUpload = () => {
  const [companyLogo, setCompanyLogo] = useState('');
  const [loading, setLoading] = useState(false);

  const config = require('../config');

  // Handle the logo file upload
  const handleUploadLogo = async (event) => {
    const file = event.target.files[0];
    if (!file) return;

    const formData = new FormData();
    formData.append('companyLogo', file);

    setLoading(true);

    try {
      const response = await fetch(config.backendDomain + '/uploadcompanylogo', {
        method: 'POST',
        body: formData,
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      });

      if (!response.ok) {
        throw new Error('Failed to upload company logo');
      }

      const data = await response.json();
      setCompanyLogo(data.companyLogo);  // Update logo state
      toast.success('Company logo uploaded successfully');
    } catch (error) {
      console.error(error.message);
      toast.error('Error uploading company logo');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="relative flex items-center">
      <ToastContainer className="mt-14" />
      
      <div className="mt-32 w-full max-w-md mx-auto bg-white p-6 rounded-lg shadow-lg">
        <h2 className="text-2xl font-semibold text-gray-800 mb-4">Upload Company Logo</h2>

        <label className="block text-sm text-gray-700 mb-4">Choose your company logo:</label>
        <input
          type="file"
          accept="image/*"
          onChange={handleUploadLogo}
          className="block w-full p-2 border border-gray-300 rounded-md mb-4"
        />

        {companyLogo && (
          <div className="text-center mb-4">
            <h3 className="text-sm text-gray-600 mb-2">Uploaded Logo:</h3>
            <img
              src={companyLogo}
              alt="Company Logo"
              className="w-32 h-32 object-contain mx-auto"
            />
          </div>
        )}

        <button
          disabled={loading}
          className={`w-full px-4 py-2 text-white rounded ${loading ? 'bg-gray-400' : 'bg-green-500 hover:bg-green-600'}`}
        >
          {loading ? 'Uploading...' : 'Upload Logo'}
        </button>
      </div>
    </div>
  );
};

export default LogoUpload;
