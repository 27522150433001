import React, { useState, useEffect } from "react";
import ReactSelect from "react-select";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { ToastContainer } from "react-toastify";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
const config = require("../../config");
const Createmanifest = () => {
  const [filteredcustomer, setFilteredcustomer] = useState([]);
  const [bookingEntry, setBookingEntry] = useState([]);
  const [selectedBookingEntry, setSelectedBookingEntry] = useState([]);
  const [branches, setBranches] = useState([]);
  const [manifestName, setManifestName] = useState("");
  const [manifestDate, setManifestDate] = useState(null);
  const [selectedFromBranch, setSelectedFromBranch] = useState();
  const [selectedToBranch, setSelectedToBranch] = useState();
  const [selectedTransportMode, setSelectedTransportMode] = useState();
  const token = localStorage.getItem("token");
  const Transportmodes = [
    { value: "road", label: "Road" },
    { value: "train", label: "Train" },
    { value: "Air", label: "Air" },
  ];
  const [filteredManifest,setFilteredManifest]=useState([])
  

  useEffect(() => {
    // fetchcustomer();
    fetchManifest();
    fetchBranches();
  }, []);
  const table = {
    "WayBill Id": "waybill_number",
    Origin: "origincity_name",
    Destination: "destcity_name",
    "Actual Weight": "actual_weight",
    Mode: "transportmode_name",
    Pcs: "packets",
    Weight: "actual_weight",

    "Booking Date": "booking_date",
    "Booking Amount": "booking_amount",
  };

  function setBookingValue(data) {
    setBookingEntry(data);
    let initalValue = 0;
    setFilteredManifest(data)
    // setSelectedBookingEntry(data.map((d) => d.waybill_id));
    // for(let i=0;i<data.length;i++){
    //   initalValue+=data[i].booking_amount
    // }
    // setTotalSum(initalValue)
  }

  const fetchManifest = async () => {
    try {
      const response = await fetch(config.backendDomain + "/manifest", {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const data = await response.json();
      const sortedData = data.sort(
        (a, b) => new Date(b.created_date) - new Date(a.created_date)
      );

      // Generate the next manifest name
      if (sortedData.length > 0) {
        const lastManifest = sortedData[0];
        const lastManifestNumber = parseInt(
          lastManifest.manifest_name.replace("MF", ""),
          10
        );
        const nextManifestNumber = lastManifestNumber + 1;
        const nextManifestName = `MF${nextManifestNumber}`;
        setManifestName(nextManifestName);
      } else {
        setManifestName("MF1");
      }
    } catch (error) {
      console.error("Error fetching manifests:", error);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      // toBranch=selectedToBranch;
      // fromBranch=selectedFromBranch
      // console.log( toBranch,selectedToBranch,
      //   fromBranch,selectedFromBranch)

      const response = await fetch(config.backendDomain + "/manifest_process", {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          startDate: manifestDate,
          from_branch: selectedFromBranch.value,

        }),
      });
      const data = await response.json();

      if (response.ok) {
        toast.success(data.message);
        setBookingValue(data.data);
      } else {
        handleErrorResponse(response.status, data.message);
      }
    } catch (error) {
      console.error("Error creating Manifest:", error);
      handleErrorResponse(500, "An error occurred.");
    }
  };

  const handleFilter=async(e)=>{
    // console.log('ee',bookingEntry,typeof e,bookingEntry.filter((waybill)=>{return waybill.waybill_number.includes(e)}))
    // if(e=='')
    setFilteredManifest(  bookingEntry.filter((waybill)=>{ return waybill.waybill_number.includes(e)}))
    // console.log(filteredManifest)
  }

  const handleErrorResponse = (status, message) => {
    if (status === 403) {
      showAlert("You are not authorized.");
    } else if (status === 400) {
      showAlert(message || "Manifest with the same name already exists");
    } else {
      showAlert("An error occurred.");
    }
  };

  const showAlert = (msg) => {
    toast.info(msg);
  };

  const TableRow = React.memo(
    ({ count, checkboxes, onCheckboxChange, rowIndex }) => {
      return (
        <tr>
          <td style={{ "text-align": "center" }}>
            <input
              className="px-6 py-4 whitespace-nowrap"
              type="checkbox"
              checked={selectedBookingEntry.includes(rowIndex)}
              onChange={() => onCheckboxChange(rowIndex)}
            />
          </td>
          <td
            style={{ "text-align": "center" }}
            className="px-6 py-4 whitespace-nowrap"
          >
            {count}
          </td>
          {Object.values(table).map((value, colIndex) => (
            <td className="px-6 py-4 whitespace-nowrap" key={colIndex}>
              {checkboxes[value]}
            </td>
          ))}
        </tr>
      );
    }
  );

  const handleCheckboxChange = (id) => {
    console.log("s", selectedBookingEntry);
    setSelectedBookingEntry((prevSelectedRows) => {
      if (prevSelectedRows.includes(id)) {
        // Deselect the row if it was already selected
        return prevSelectedRows.filter((rowId) => rowId !== id);
      } else {
        // Select the row if it wasn't already selected
        return [...prevSelectedRows, id];
      }
    });
    console.log("s", selectedBookingEntry);
  };
  async function createmanifest() {
    try {
      const response = await fetch(config.backendDomain + "/manifest", {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          bookingIds: selectedBookingEntry,
          toBranch: selectedToBranch,
          fromBranch: selectedFromBranch,
          selectedTransportMode,
          manifestDate,
          manifestName,
        }),
      });
      const data = await response.json();

      if (response.ok) {
        setBookingEntry([]);
        fetchManifest();
        toast.success(data.message);
      } else {
        handleErrorResponse(response.status, data.message);
      }
    } catch (error) {
      console.error("Error creating Manifest:", error);
      handleErrorResponse(500, "An error occurred.");
    }
  }
  const fetchBranches = async () => {
    try {
      const response = await fetch(config.backendDomain + "/parentbranch", {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const data = await response.json();
      setBranches(data);
    } catch (error) {
      console.error("Error fetching branches:", error);
    }
  };

  const ShowManifest = () => {
    const [manifestList, setManifestList] = useState({});
    useEffect(() => {
      getManifest();
   }, [])
    const table = {
      "manifestNumber": "manifest_name",
      // "Customer/Client": "customer_name",
      
      "From":"from_branch_name",
      "To":"to_branch_name",
      "Transport Mode":"transportmode_name",
      "Manifest Date":"manifest_date",
      "created at": "created_at",
    
    };
    const billTable = {
      "WayBill Id": "waybill_id",
      Origin: "origincity_name",
      Destination: "destcity_name",
      "Actual Weight": "actual_weight",
      Mode: "transportmode_name",
      Pcs: "packets",
      Weight: "actual_weight",

      "Booking Date": "booking_date",
      "Booking Amount": "booking_amount",
    };
    const getManifest = async () => {
      try {
        const response = await fetch(config.backendDomain + "/manifest", {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        const data = await response.json();
        const groupManifest = {};
        // setcustomer(data);
        for (let i = 0; i < data.length; i++) {
          const currEle = data[i];
          if (groupManifest[currEle.manifest_id]) {
            groupManifest[currEle.manifest_id].push(currEle);
          } else {
            groupManifest[currEle.manifest_id] = [currEle];
          }
        }
         console.log(groupManifest)
        setManifestList(groupManifest);
      } catch (error) {
        console.error("Error fetching Customer:", error);
      }
    };

    const ManifestComponent = ({ manifest, isOpen, onClose }) => {
      console.log(manifest);
      const styles = {
        overlay: {
          position: "fixed",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          backgroundColor: "rgba(0, 0, 0, 0.5)",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          marginLeft: "80px",
        },
        modal: {
          backgroundColor: "white",
          padding: "20px",
          borderRadius: "4px",
          width: "70vw",
          position: "relative",
        },
        closeButton: {
          position: "absolute",
          top: "10px",
          right: "10px",
          border: "none",
          background: "none",
          fontSize: "16px",
          cursor: "pointer",
        },
      };
      if (!isOpen) return null;

      return (
        <div style={styles.overlay}>
          <div style={styles.modal}>
            <button style={styles.closeButton} onClick={onClose}>
              X
            </button>

            <div
              style={{
                padding: "20px",
                margin: "auto",
                border: "1px solid #ccc",
              }}
            >
              <h1 style={{ marginLeft: "30vw", fontSize: "larger" }}>
                manifest
              </h1>
              <p>
                <strong>manifest Number:</strong>{" "}
                {manifest[0].id ? manifest[0].id : ""}
              </p>
              <p>
                <strong>Date:</strong>{" "}
                {manifest[0].created_at ? manifest[0].created_at : ""}
              </p>
              <br />

              <h3 style={{ fontSize: "large" }}>Customer Information</h3>
              <p>
                <strong>Name:</strong>{" "}
                {manifest[0].customer_name ? manifest[0].customer_name : ""}
              </p>
              <p>
                <strong>Address:</strong>{" "}
                {manifest[0].customer_address ? manifest[0].customer_address : ""}
              </p>

              <h3 style={{ marginLeft: "29vw", fontSize: "large" }}>
                manifest Details
              </h3>
              <hr></hr>
              <table
                border="1"
                style={{ width: "100%", borderCollapse: "collapse" }}
              >
                <colgroup>
                  <col style={{ width: "5%" }} />
                  <col style={{ width: "5%" }} />
                  <col style={{ width: "5%" }} />
                  <col style={{ width: "5%" }} />
                  <col style={{ width: "5%" }} />
                  <col style={{ width: "5%" }} />
                  <col style={{ width: "5%" }} />
                  <col style={{ width: "5%" }} />
                  <col style={{ width: "5%" }} />
                  <col style={{ width: "5%" }} />
                </colgroup>
                <thead>
                  <tr>
                    <th>Sr. no.</th>
                    {Object.keys(billTable).map((e) => (
                      <th>{e}</th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {manifest.map((item, index) => (
                    <tr key={index}>
                      <td style={{ "text-align": "center" }}>{index + 1}</td>
                      {Object.values(billTable).map((e) => (
                        <td style={{ "text-align": "center" }}>{item[e]}</td>
                      ))}
                    </tr>
                  ))}
                </tbody>

                <tfoot>
                  {/* <tr>
                    <td colSpan="9" style={{ textAlign: "right" }}>
                      <strong>Subtotal:</strong>{" "}
                    </td>
                    <td>Rs. {manifest[0].total_amount}</td>
                  </tr> */}

                  {/* <tr>
                    <td colSpan="9" style={{ textAlign: "right" }}>
                      <strong>Total:</strong>
                    </td>
                    <td>Rs. {manifest[0].total_amount}</td>
                  </tr> */}
                </tfoot>
              </table>
            </div>
          </div>
        </div>
      );
    };

    const TableRow = React.memo(
      ({ count, checkboxes, onCheckboxChange, rowIndex }) => {
        const [isPopupOpen, setPopupOpen] = useState(false);

        const openPopup = () => setPopupOpen(true);
        const closePopup = () => setPopupOpen(false);

        return (
          <>
            <tr>
              <td className="px-6 py-4 whitespace-nowrap">{count}.</td>
              {Object.values(table).map((value, colIndex) => (
                <td
                  style={{ cursor: "pointer" }}
                  className="px-6 py-4 whitespace-nowrap"
                  key={colIndex}
                  onClick={openPopup}
                >
                  {checkboxes[0][value]}
                </td>
              ))}
            </tr>

            <ManifestComponent
              manifest={checkboxes}
              isOpen={isPopupOpen}
              onClose={closePopup}
            />
          </>
        );
      }
    );

    return (
      <div className="relative bg-blue-50 animate__animated animate__fadeIn">
        <ToastContainer className="mt-14" />
        <table className="min-w-full divide-y divide-gray-200 mt-4">
          <thead className="bg-gray-200">
            <tr>
              <th className="px-6 py-3 text-left  font-medium text-gray-500 uppercase tracking-wider">
                Sr. no.
              </th>
              {Object.keys(table).map((e) => (
                <th className="px-6 py-3 text-left  font-medium text-gray-500 uppercase tracking-wider">
                  {e}
                </th>
              ))}
            </tr>
          </thead>
          {Object.keys(manifestList).length > 0 &&
            Object.keys(manifestList).map((row, index) => (
              <TableRow count={index + 1} checkboxes={manifestList[row]} />
            ))}
        </table>
        <hr></hr>
      </div>
    );
  };

  return (
    <>
    
    <div className="relative bg-blue-50 animate__animated animate__fadeIn">
      <ToastContainer className="mt-14" />
      <div className="relative bg-blue-50">
        <h2 className="shadow-lg text-xl h-10 font-bold bg-blue-500 text-white mb-6 pl-3 pt-1">
          Create Manifest Gateway
        </h2>
      </div>
      <form onSubmit={handleSubmit} className="grid grid-cols-3 gap-2">
        <div className="mb-2">
          <input
            type="text"
            id="manifestName"
            className="shadow-lg w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            placeholder="Enter Manifest Number"
            value={manifestName}
            onChange={(e) => setManifestName(e.target.value)}
            readOnly
            required
          />
        </div>
        <div className="mb-2">
          <DatePicker
            selected={manifestDate}
            onChange={(date) => setManifestDate(date)}
            dateFormat="dd/MM/yyyy"
            placeholderText="Manifest Date"
            className="shadow-lg w-[410px]  py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            required
          />
        </div>

        <div className="mb-2">
          <ReactSelect
            id="parentBranchId"
            className=" shadow-lg text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            options={branches.map((branch) => ({
              value: branch.branch_id,
              label: branch.branch_name,
            }))}
            value={selectedFromBranch}
            onChange={setSelectedFromBranch}
            placeholder="From Branch"
            required
          />
        </div>
        <div className="mb-2">
          <ReactSelect
            id="parentBranchId"
            className=" shadow-lg text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            options={branches.map((branch) => ({
              value: branch.branch_id,
              label: branch.branch_name,
            }))}
            value={selectedToBranch}
            onChange={setSelectedToBranch}
            placeholder="To Branch"
            required
          />
        </div>
        <div className="mb-2">
          <ReactSelect
            id="transportmodeId"
            className=" shadow-lg text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            options={Transportmodes.map((transportmode) => ({
              value: transportmode.value,
              label: transportmode.label,
            }))}
            value={selectedTransportMode}
            onChange={setSelectedTransportMode}
            placeholder="Select Dispatch Mode"
          />
        </div>

        <div className="mb-2">
          <button
            className="bg-blue-500 hover:bg-blue-700 focus:bg-blue-700 text-white font-bold py-2 px-4 h-9 pt-1.5 w-40 rounded focus:outline-none focus:shadow-outline"
            type="submit"
          >
            Add Manifest
          </button>
        </div>
       
      </form>
      {/* <div className="col-span-3 mb-2">
          <button
            className="bg-blue-500 hover:bg-blue-700 focus:bg-blue-700 text-white font-bold py-2 px-4 h-10 pt-1.5 w-40 rounded focus:outline-none focus:shadow-outline"
            onClick={() => setShowManifestList(!showmanifestList)}>
            {!showmanifestList?'Show Manifest':"Hide Manifest"}
          </button>
          {showmanifestList&&<ShowManifest/>}

        </div> */}
        
        <div className="col-span-2 mb-2">
          <input 
          className="w-2w py-2 px-3 mt-8 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
          style={{
            "float": "left"}
          }
            placeholder="Search by Waybill"
            onChange={(e)=>{handleFilter(e.target.value)}}
          >
          </input>
           
          

        </div>
      <table className="min-w-full divide-y divide-gray-200 mt-4">
        <thead className="bg-gray-200">
          <tr>
            <th className="px-6 py-3 text-left  text-xs text-gray-500 uppercase tracking-wider"></th>
            <th className="px-6 py-3 text-left  text-xs text-gray-500 uppercase tracking-wider">
            
              Sr. No.
            </th>
            {Object.keys(table).map((e) => (
              <th className="px-6 py-3 text-left  text-xs text-gray-500 uppercase tracking-wider">
                {e}
              </th>
            ))}
          </tr>
        </thead>
        {filteredManifest &&
          filteredManifest.map((row, index) => (
            <TableRow
              key={index}
              count={index + 1}
              rowIndex={row.waybill_id}
              checkboxes={row}
              onCheckboxChange={handleCheckboxChange}
            />
          ))}
        <br />
      </table>
     
    </div>
     {selectedBookingEntry.length > 0 && (
      <button
        className="bg-blue-500 hover:bg-blue-700 focus:bg-blue-700 text-white font-bold py-2 px-4 h-10 pt-1.5 w-40 rounded focus:outline-none focus:shadow-outline"
        onClick={createmanifest}
        type="button"
      >
        Create Manifest
      </button>
    )}
    </>
  );
};

export default Createmanifest;
