import React from "react";
import { Link } from 'react-router-dom';

function Careers() {
  const jobs = [
    {
      title: "Frontend Developer",
      location: "Raipur, India",
      type: "Full-Time",
      description:
        "Join our team to create dynamic, user-friendly web interfaces using React and Tailwind CSS.",
    },
    {
      title: "Backend Developer",
      location: "Raipur, India",
      type: "Full-Time",
      description:
        "Work with our backend systems to build scalable and secure APIs using Node.js.",
    },
    {
      title: "Product Manager",
      location: "Raipur, India",
      type: "Full-Time",
      description:
        "Lead product development, coordinate cross-functional teams, and ensure timely delivery of high-quality features.",
    },
    {
      title: "Marketing Specialist",
      location: "Delhi, India",
      type: "Part-Time",
      description:
        "Drive marketing campaigns and help position NavDoot as a leader in supply chain solutions.",
    },
  ];

  return (
    <div className="container mx-auto p-8 bg-gradient-to-r from-blue-800 to-green-300">
      <nav className="fixed top-0 left-0 right-0 bg-white border-b-2 border-gray-200 shadow-lg z-50 py-4 hidden md:block">
        <div className="container mx-auto flex justify-between items-center px-8">
          <Link to="/">
            <img src="logo.png" alt="Logo.png" className="h-16 ml-8 " />
          </Link>
          <div className="hidden md:flex space-x-4">
            <button className="bg-blue-600 hover:bg-red-600 text-white font-semibold py-2 px-4 rounded focus:outline-none">
              <Link to="/signin">Login</Link>
            </button>
            <button className="bg-green-600 hover:bg-red-600 text-white font-semibold py-2 px-4 rounded focus:outline-none">
              <Link to="/SignupPage">Get Started</Link>
            </button>
          </div>
        </div>
      </nav>
      {/* Page Header */}
      <h1 className="text-5xl mt-24 font-bold text-center text-white mb-12">
        Join Our Team
      </h1>

      {/* About Careers Section */}
      <div className="text-center mb-12">
        <p className="text-lg text-white mb-4">
          At <span className="font-semibold text-white">NavDoot</span>, we
          are shaping the future of supply chain management. We believe in
          fostering an inclusive, innovative, and growth-oriented work
          environment. Join us to make an impact and grow your career.
        </p>
        <p className="text-lg text-white">
          Whether you're a seasoned professional or a recent graduate, we offer
          opportunities for you to thrive and contribute meaningfully.
        </p>
      </div>

      {/* Job Listings Section */}
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-8">
        {jobs.map((job, index) => (
          <div
            key={index}
            className="bg-white rounded-lg shadow-lg hover:shadow-xl transition-shadow duration-300 p-6"
          >
            <h2 className="text-2xl font-bold text-blue-800 mb-2">{job.title}</h2>
            <p className="text-gray-600 mb-1">
              <span className="font-semibold">Location:</span> {job.location}
            </p>
            <p className="text-gray-600 mb-1">
              <span className="font-semibold">Type:</span> {job.type}
            </p>
            <p className="text-gray-700 mb-4">{job.description}</p>
            <button className="bg-blue-600 text-white px-4 py-2 rounded-lg shadow hover:bg-blue-700 transition-all duration-300">
              Apply Now
            </button>
          </div>
        ))}
      </div>

      {/* Call-to-Action Section */}
      <div className="mt-12 bg-gradient-to-r from-blue-500 to-blue-700 text-white p-8 rounded-lg shadow-md text-center">
        <h2 className="text-3xl font-bold mb-4">Be Part of the NavDoot Team</h2>
        <p className="text-lg mb-6">
          Want to make a difference in the world of supply chain management?
          Explore our open positions and find the role that suits you best.
        </p>
        <button className="bg-white text-blue-700 px-6 py-3 rounded-full font-semibold shadow hover:bg-gray-200 transition-all duration-300">
          Explore Opportunities
        </button>
      </div>

      {/* Footer Section */}
      <div className="text-center mt-12">
        <p className="text-gray-600">
          © {new Date().getFullYear()} NavDoot. All Rights Reserved.
        </p>
      </div>
    </div>
  );
}

export default Careers;
