import React from "react";
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Home from '../Home/Home';
import ContactForm from '../Home/ContactForm';
import Aboutus from '../Home/Aboutus';
import Events from '../Home/Events';
import Carreers from '../Home/Carreers';
import FAQ from '../Home/FAQ';
import CRM from '../Home/CRM';
import ERPAccounting from '../Home/ERPAccounting';
import ShipmentManagement from '../Home/ShipmentManagement';
import WarehouseManagement from '../Home/WarehouseManagement';
import Dashboard from '../Dashboard/Dashboard';
import Vendors from '../Dashboard/Masters/Vendor';
import Products from '../Dashboard/Products';
import Signin from '../Authentication/signin';
import SignupPage from '../Authentication/SignupPage';
import Loader from '../Dashboard/User/Loader';
import SubscriptionPage from '../Subscription/SubscriptionPage';
import VerificationPage from '../Authentication/VerificationPage';
import TeamVerificationPage from '../Team/TeamVerificationPage';


function App() {
  
  return (
    <Router>
      <div>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/ContactForm" element={<ContactForm />} />
          <Route path="/Aboutus" element={<Aboutus />} />
          <Route path="/Events" element={<Events />} />
          <Route path="/Carreers" element={<Carreers />} />
          <Route path="/FAQ" element={<FAQ />} />
          <Route path="/CRM" element={<CRM />} />
          <Route path="/ERPAccounting" element={<ERPAccounting />} />
          <Route path="/ShipmentManagement" element={<ShipmentManagement />} />
          <Route path="/WarehouseManagement" element={<WarehouseManagement />} />
          <Route path="/Signin" element={<Signin />} />
          <Route path="/Loader" element={<Loader />} />
          <Route path="/SignupPage" element={<SignupPage />} />
          <Route path="/SubscriptionPage" element= {<SubscriptionPage /> }/>
          <Route path="/Verify/:token" element={<VerificationPage/>} />  
          <Route path="/teamVerify/:inviteToken" element={<TeamVerificationPage/>} /> 
          <Route path="/Dashboard" element={<Dashboard/>}/>
          <Route path="/Products" element={<Products/>}/>
          <Route path="/Vendors" element={<Vendors/>}/>

          
          
        </Routes>
      </div>
    </Router>
  );
}

  export default App;
