import React from "react";
import { Link } from 'react-router-dom';

const Extensions = () => {
    return (
        <div className="footer-links grid grid-cols-5 gap-6 text-left ml-2 mt-32 ">
             <div className="footer-links text-left testimon">
             <h1 class="text-4xl text-gray-900 text-center mt-2">Ready to unleash your potential?</h1>
                <h2 class="text-lg font-semibold text-center text-gray-700 mt-2">Let's dive in!</h2>
                <button className="mx-auto w-max h-12 mt-8 bg-red-600 hover:bg-blue-600 text-white font-semibold py-2 px-4 rounded focus:outline-none shadow-lg transition duration-300 ease-in-out transform hover:scale-105 hidden md:block">
              <Link to="/signin">ACCESS YOUR APPS</Link>
            </button>
             </div>
             <div className="col-start-1 col-end-2 mt-8 ml-8 ">

            <h1 className=" text-2xl  block px-2 py-2 rounded text "> Apps and Extensions </h1>
           
            <a href="#" className="block px-2 py-2 rounded text hover:text-blue-600 text-sm font-sans">
              Mobile Apps
            </a>
            <a href="#" className="block px-2 py-2 rounded hover:text-blue-600 text-sm font-sans">
            Google Workspace Integration
            </a>
            <a href="#" className="block px-2 py-2 rounded hover:text-blue-600 text-sm font-sans">
            Microsoft 365 Integration
            </a>
            <a href="#" className="block px-2 py-2 rounded hover:text-blue-600 text-sm font-sans">
            Apps for Apple Watch
            </a>
            <a href="#" className="block px-2 py-2 rounded hover:text-blue-600 text-sm font-sans">
            Product Integrations
            </a>
            <a href="#" className="block px-2 py-2 rounded hover:text-blue-600 text-sm font-sans">
            Marketplace
            </a>
            <a href="#" className="block px-2 py-2 rounded hover:text-blue-600 text-sm font-sans">
            Browser Extensions
            </a>
          </div>
          <div className="col-start-2 col-end-3 ml-12 mt-8 ml-16">
          <h1 className=" text-2xl  block px-2 py-2 rounded text"> Learn</h1>
         
            <a href="#" className="block px-2 py-2 rounded hover:text-blue-600 text-sm font-sans">
            Training & Certification
            </a>
            <a href="#" className="block px-2 py-2 rounded hover:text-blue-600 text-sm font-sans">
            Academy
            </a>
            <a href="#" className="block px-2 py-2 rounded hover:text-blue-600 text-sm font-sans">
              Blog
            </a>
            <a href="#" className="block px-2 py-2 rounded hover:text-blue-600 text-sm font-sans">
            Perspectives
            </a>
            <a href="#" className="block px-2 py-2 rounded hover:text-blue-600 text-sm font-sans">
            Knowledge Base
            </a>
            <a href="#" className="block px-2 py-2 rounded hover:text-blue-600 text-sm font-sans">
            Zia
            </a>
            <a href="#" className="block px-2 py-2 rounded hover:text-blue-600 text-sm font-sans">
            The Long Game
            </a>
            <a href="#" className="block px-2 py-2 rounded hover:text-blue-600 text-sm font-sans">
            Newsletter
            </a>
          </div>
          <div className="col-start-3 col-end-4 ml-12 mt-8 ml-8">
          <h1 className=" text-2xl  block px-2 py-2 rounded text"> Community </h1>
          
            <a href="#" className="block px-2 py-2 rounded hover:text-blue-600 text-sm font-sans">
            User Community
            </a> 
            <a href="#" className="block px-2 py-2 rounded hover:text-blue-600 text-sm font-sans">
            Customer Stories
            </a>
            <a href="#" className="block px-2 py-2 rounded hover:text-blue-600 text-sm font-sans">
              Support
            </a>
            <a href="#" className="block px-2 py-2 rounded hover:text-blue-600 text-sm font-sans">
            Influence
            </a>
            <a href="#" className="block px-2 py-2 rounded hover:text-blue-600 text-sm font-sans">
            Partner with Us
            </a>
            <a href="#" className="block px-2 py-2 rounded hover:text-blue-600 text-sm font-sans">
            Navdoot for Startups
            </a>
            <a href="#" className="block px-2 py-2 rounded hover:text-blue-600 text-sm font-sans">
            Affiliate Program
            </a>
            <a href="#" className="block px-2 py-2 rounded hover:text-blue-600 text-sm font-sans">
            Humans of Navdoot
            </a>
          </div>
          <div className="col-start-4 col-end-4 mt-8 ml-8">
          <h1 className=" text-2xl  block px-2 py-2 rounded text"> Company </h1>
         
            <a href="#" className="block px-2 py-2 rounded hover:text-blue-600 text-sm font-sans">
            About Us
            </a>
            <a href="#" className="block px-2 py-2 rounded hover:text-blue-600 text-sm font-sans">
            Press
            </a>
            <a href="#" className="block px-2 py-2 rounded hover:text-blue-600 text-sm font-sans">
            Events
            </a>
            <a href="#" className="block px-2 py-2 rounded hover:text-blue-600 text-sm font-sans">
            Newsroom
            </a>
            <a href="#" className="block px-2 py-2 rounded hover:text-blue-600 text-sm font-sans">
            Branding Assets
            </a>
            <a href="#" className="block px-2 py-2 rounded hover:text-blue-600 text-sm font-sans">
            Navdoot Schools
            </a>
            <a href="#" className="block px-2 py-2 rounded hover:text-blue-600 text-sm font-sans">
            Service Status
            </a>
            <a href="#" className="block px-2 py-2 rounded hover:text-blue-600 text-sm font-sans">
            Careers
            </a>
           </div>
           <div className="col-start-5 col-end-5 mt-8 ml-8">
           <h1 className=" text-2xl  block px-2 py-2 rounded text"> Contact Sales </h1>
           
            <a href="#" className="block px-2 py-2 rounded hover:text-blue-600 text-sm font-sans">
            Phone
            </a>
            <a href="#" className="block px-2 py-2 rounded hover:text-blue-600 text-sm font-sans">
            +91 78279 34515
            </a>
            <a href="#" className="block px-2 py-2 rounded hover:text-blue-600 text-sm font-sans">
            +91 78279 34515
            </a>
            <a href="#" className="block px-2 py-2 rounded hover:text-blue-600 text-sm font-sans">
            Email
            </a>
            <a href="#" className="block px-2 py-2 rounded hover:text-blue-600 text-sm font-sans">
            info@navdoot.com
            </a>
            <a href="#" className="block px-2 py-2 rounded hover:text-blue-600 text-sm font-sans">
            Support
            </a>
            <a href="#" className="block px-2 py-2 rounded hover:text-blue-600 text-sm font-sans">
            Talk to Concierge
           </a>
           </div>
        </div>
      );
    };

export default Extensions;