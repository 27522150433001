import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import dashboardImage from '../Assets/Expert.png';

const ContactForm = () => {
  const [formData, setFormData] = useState({
    fullName: '',
    companyName: '',
    email: '',
    contactNumber: '',
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Implement your form submission logic here, e.g., sendFormData(formData);
    console.log(formData);
  };

  return (
    <div className="bg-gray-100 min-h-screen flex flex-col">
      <nav className="fixed top-0 left-0 right-0 h-16 bg-white border-b-2 border-gray-200 shadow-lg z-50">
        <div className="container mx-auto flex justify-between items-center px-8">
          <Link to="/">
            <img src="logo.png" alt="Logo.png" className="h-16 ml-8 " />
          </Link>
          <div className="hidden md:flex space-x-4">
            <button className="bg-blue-600 hover:bg-red-600 text-white font-semibold py-2 px-4 rounded focus:outline-none">
              <Link to="/signin">Login</Link>
            </button>
            <button className="bg-green-600 hover:bg-red-600 text-white font-semibold py-2 px-4 rounded focus:outline-none">
              <Link to="/SignupPage">Get Started</Link>
            </button>
          </div>
        </div>
      </nav>

      <div className="container mx-auto flex flex-col md:flex-row items-center justify-between px-2 py-32">
        <div className="md:w-1/2 flex justify-center">
          <img
            src={dashboardImage}
            alt="People"
            className="w-full md:w-3/4 mt-8 md:mt-0 animate__animated animate__fadeIn hover:shadow-lg transition duration-300 ease-in-out transform hover:scale-105"
          />
        </div>
        <div className="md:w-1/2 mr-8">
          <h1 className="text-4xl text-gray-600 mt-0 text-center">
            Contact Form
            <span className="text-blue-800"> NavDoot</span>
            <h2 className="text-sm mt-6 text-gray-400 mb-4">Our Team is happy to answer your questions, fill out the form and we'll be in touch as soon as possible. Thank you for contacting us!</h2>
          </h1>
          <form onSubmit={handleSubmit} className="rounded-lg shadow-lg bg-white px-8 py-6 w-full">
            <input
              type="text"
              name="fullName"
              value={formData.fullName}
              onChange={handleChange}
              placeholder="Enter Full Name"
              className="w-full py-2 px-4 mb-6 border border-gray-300 rounded focus:outline-none focus:shadow-outline"
              required
            />
            <input
              type="text"
              name="companyName"
              value={formData.companyName}
              onChange={handleChange}
              placeholder="Enter Company Name"
              className="w-full py-2 px-4 mb-4 border border-gray-300 rounded focus:outline-none focus:shadow-outline"
              required
            />
            <input
              type="tel"
              name="contactNumber"
              value={formData.contactNumber}
              onChange={handleChange}
              placeholder="Country"
              className="w-full py-2 px-4 mb-4 border border-gray-300 rounded focus:outline-none focus:shadow-outline"
              required
            />
            <input
              type="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              placeholder="Email Id"
              className="w-full py-2 px-4 mb-4 border border-gray-300 rounded focus:outline-none focus:shadow-outline"
              required
            />
            <input
              type="tel"
              name="contactNumber"
              value={formData.contactNumber}
              onChange={handleChange}
              placeholder="Enter Contact Number"
              className="w-full py-2 px-4 mb-4 border border-gray-300 rounded focus:outline-none focus:shadow-outline"
              required
            />
            <button
              type="submit"
              className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
            >
              Submit
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default ContactForm;
