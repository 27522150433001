import React, { useState, useEffect } from "react";
import axios from "axios";
import ReactSelect from "react-select";
import { ToastContainer } from "react-toastify";
import { toast } from "react-toastify";
const config = require("../../config");
const TableView = (props) => {
  const token = localStorage.getItem("token");
  // let api_list=JSON.parse(props.apilist)
  // console.log(api_list)  

  const headers = {
    Authorization: `Bearer ${token}`,
  };
  const [rolePermission, setRolePermission] = useState(undefined);
  const [selectedApi, setSelectedApi] = useState(JSON.parse(props.apilist));
  console.log(selectedApi)

  useEffect(() => {
    console.log("child render ");

    // apiCall({
    //   url: config.backendDomain + "/apilist",
    //   method: "GET",
    //   headers: headers,
    //   setter: setRolePermission,
    // });
   
    // apiCall(() => {});
  }, []);

  return (
    <>
      <tbody className="bg-white divide-y divide-gray-200">
        
        {rolePermission &&
          Object.keys(rolePermission).map((e, i) => (
            <tr key={i}>
              <td className="px-6 py-4 whitespace-nowrap ">
                {e}
              </td>
              <td className="px-6 py-4 whitespace-nowrap ">
                <input
                  type="checkbox"
                  checked={rolePermission[e]["GET"]}
                  onChange={(v) => {
                    
                    rolePermission[e]["GET"] = v.target.checked;
                  }
                  }
                  disabled={rolePermission[e]["GET"]==undefined}
                 
                />
                 
                <p>hdl{JSON.stringify(rolePermission[e])}+ {typeof rolePermission[e]['GET']}</p>
              </td>
              <td className="px-6 py-4 whitespace-nowrap ">
                <input
                  type="checkbox"
                  checked={rolePermission[e]["POST"]}
                  onChange={(v) => {
                    rolePermission[e]["POST"]= v.target.checked;
                    

                  }}
                  disabled={!rolePermission[e]["POST"]==undefined}
                  />
                  
              </td>
              <td className="px-6 py-4 whitespace-nowrap ">
                <input
                  type="checkbox"
                  checked={rolePermission[e]["PUT"]}
                  onChange={(v) => {
                    rolePermission[e]["PUT"] = v.target.checked;
                  }}
                  disabled={!rolePermission[e]["PUT"]==undefined}
                />
              </td>
              <td className="px-6 py-4 whitespace-nowrap ">
                <input
                  type="checkbox"
                  checked={rolePermission[e]["DELETE"]}
                  onChange={(v) => {
                    rolePermission[e]["DELETE"] = v.target.checked;
                  }}
                  disabled={!rolePermission[e]["DELETE"]==undefined}
                />
              </td>
            </tr>
          ))}
        {/* {currentItems.map((rolePermission) => (
            <tr key={rolePermission.rolepermission_id}>
              <td className="px-6 py-4 whitespace-nowrap ">
                {rolePermission.rolepermission_id}
              </td>
              <td className="px-6 py-4 whitespace-nowrap ">
                {rolePermission.rolepermission_name}
              </td>
              <td className="px-6 py-4 whitespace-nowrap ">
                {rolePermission.user_id}
              </td>
              <td className="px-6 py-4 whitespace-nowrap ">
                {rolePermission.created_date}
              </td>
              <td className="px-6 py-4 whitespace-nowrap ">
                {rolePermission.modified_date}
              </td>
              <td className="px-6 py-4 whitespace-nowrap ">
                <button
                  className="bg-yellow-500 hover:bg-yellow-700 text-white font-bold py-1 px-2 rounded focus:outline-none focus:shadow-outline mr-2"
                  onClick={() =>
                    handleOpenEditPopup(
                      rolePermission.rolepermission_id,
                      rolePermission.rolepermission_name
                    )
                  }
                >
                  Edit
                </button>
                <button
                  className="bg-red-500 hover:bg-red-700 text-white font-bold py-1 px-2 rounded focus:outline-none focus:shadow-outline"
                  onClick={() => handleDelete(rolePermission.rolepermission_id)}
                >
                  Delete
                </button>
              </td>
            </tr>
          ))} */}
      </tbody>

      {/* {JSON.stringify(rolePermission)+' '} */}
    </>
  );
};

const TableRow= React.memo(({ name, checkboxes, onCheckboxChange, rowIndex }) => {
  return (
    <tr>
      <td>{name}</td>
      {['GET','POST','PUT','DELETE'].map((method, colIndex) => (
        <td className="px-6 py-2 whitespace-nowrap" key={colIndex}>
          <input 
            className="px-6 py-4 whitespace-nowrap"
            type="checkbox" 
            checked={checkboxes[method]?checkboxes[method]:false} 
            onChange={(v) => onCheckboxChange(name,method,v.target.checked)} 
            disabled={checkboxes[method]==undefined}
          />
          {/* <p>hellos{JSON.stringify(checkboxes)+checkboxes[method]}</p> */}
        </td>
      ))}
    </tr>
  );
});




const apiCall = (Obj) => {
  const url = Obj.url,
    payload = Obj.payload,
    headers = Obj.headers,
    method = Obj.method,
    setter = Obj.setter;
  console.log("api called");
  if (method.toLowerCase() == "get") {
    axios
      .get(url, { headers: headers })
      .then((data) => {
        console.log(data);
        setter(data.data.data);
      })
      .catch((err) => {
        console.error(err);
      });
  } else {
    axios
      .post(url, payload, { headers: headers })
      .then((data) => {
        if(setter){

          setter(data.data.data);
        }
        toast.success('Updated Successfully');  
        return
      })
      .catch((err) => {
        console.log(err);
        return 
      });
  }
};

const RolePermission = () => {
  const token = localStorage.getItem("token");

  const headers = {
    Authorization: `Bearer ${token}`,
  };
  const [roleSelected, setRoleSelected] = useState(null);
  const [roleList, setRoleList] = useState([]);
  const [rolePermission,setRolePermission]=useState(null)
  const [mergedPermission,setMergedPermission]=useState(null)
  const [submitted,setSubmitted]=useState(1)

  useEffect(() => {
    console.log("parent render ");

    apiCall({
      url: config.backendDomain + "/roles",
      method: "GET",
      headers: headers,
      setter: setRoleList,
    });
    axios
    .get(config.backendDomain + "/apilist",  { headers: headers })
    .then((data) => {
       //console.log(data.data.data)
       let value=data.data.data
      //  console.log(roleList[roleSelected]?.api_list,value,{...value,...roleList})
      //let parselist=roleList[roleSelected]?JSON.parse(roleList[roleSelected]?.api_list):{}

      setRolePermission(value);
    })
    .catch((err) => {
      console.log(err);
    });
  }, [submitted]);

  function handleChange(e) {
    e.preventDefault();
    console.log("form submitted.");
    console.log(roleSelected)
    if(roleSelected){

      apiCall({url:config.backendDomain+'/apispermission',
          method: 'PUT',
          headers:headers
          ,setter:undefined,
          payload:{api_list:mergedPermission,role_id:roleSelected.value}
        });
        setSubmitted((e)=>{
          return e+1
        })
        // setRoleList([])
        setRoleSelected(null)
        console.log(submitted)
    }


  }
  const handleCheckboxChange = (apiName, method,checked) => {
    setMergedPermission((prevRows) => {
      const newRows = {...prevRows};
      newRows[apiName][method] = checked
      return newRows;
    });
    console.log(rolePermission)
  };

  return (
    <>
    <div>

       <ToastContainer className="mt-14" />
      <form onSubmit={handleChange}>

        <div className="mb-4">
          <ReactSelect
            id="roleId"
            className="shadow-lg w-[1245px] ml-0"
            options={roleList.map((option) => ({
              value: option.role_id,
              label: option.role_name,
              apilist:option.api_list
            }))}
            value={roleSelected}
            onChange={(e)=>{console.log(e.value);setRoleSelected(e);setMergedPermission((value)=>{
              let parselist=e.apilist?JSON.parse(e.apilist):{}
              // console.log(value,parselist,{...value,...parselist})
              return {...rolePermission,...parselist}


              
            })}}
            placeholder="Select Role"
          />
        </div>
        <button
          className="bg-blue-500 hover:bg-blue-700 focus:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
          type="submit"
        >
          Create Permissions
        </button>
        <table className="min-w-full divide-y divide-gray-200 mt-4">
          <thead className="bg-gray-200">
            <tr>
              <th className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Api Name
              </th>
              <th className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Read
              </th>
              <th className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Create
              </th>
              <th className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Modify
              </th>
              <th className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Delete
              </th>
            </tr>
          </thead>
          {roleSelected && Object.keys(mergedPermission).map((row,index)=>(
            <TableRow 
            key={index} 
            rowIndex={index} 
            name={row} 
            checkboxes={mergedPermission[row]} 
            onCheckboxChange={handleCheckboxChange} 
          />
          )) }
        </table>
      </form>
    </div>
    </>
  );
};

export default RolePermission;
