import React, { useState, useEffect } from 'react';
import ReactSelect from 'react-select';
const config=require('../../config')
const CountryData = () => {
  const [Areas, setAreas] = useState([]);

  useEffect(() => {
    fetchAreas();
  }, []);

  const fetchAreas = async () => {
    try {
      const response = await fetch(config.backendDomain+'/Area', {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      });
      const data = await response.json();
      setAreas(data);
    } catch (error) {
      console.error('Error fetching Areas:', error);
    }
  };

  return (
    <div className="container mx-auto py-8 mt-0">
      <div className="relative bg-blue-50">
        <h2 className="shadow-lg text-xl h-10 font-bold bg-blue-500 text-white mb-6 pl-3 pt-1">Serviceable Locations</h2>
        <table className="min-w-full divide-y divide-gray-200 mt-6">
          <thead className="bg-gray-200">
            <tr>
              <th className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">ID</th>
              <th className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Area </th>
              <th className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">postalcode </th>
              <th className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">city </th>
              <th className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">State </th>
              <th className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Region </th>
              <th className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Zone </th>
              <th className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Country </th>
              <th className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Created Date</th>
              <th className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Modified Date</th>
            </tr>
          </thead>
          <tbody className="bg-white divide-y divide-gray-200">
            {Areas.map((area) => (
              <tr key={area.area_id}>
                <td className="px-4 py-4 whitespace-nowrap text-xs">{area.area_id}</td>
                <td className="px-4 py-4 whitespace-nowrap text-xs">{area.area_name}</td>
                <td className="px-4 py-4 whitespace-nowrap text-xs">{area.postalcode_name}</td>
                <td className="px-4 py-4 whitespace-nowrap text-xs">{area.city_name}</td>
                <td className="px-4 py-4 whitespace-nowrap text-xs">{area.state_name}</td>
                <td className="px-4 py-4 whitespace-nowrap text-xs">{area.region_name}</td>
                <td className="px-4 py-4 whitespace-nowrap text-xs">{area.zone_name}</td>
                <td className="px-4 py-4 whitespace-nowrap text-xs">{area.country_name}</td>
                <td className="px-4 py-4 whitespace-nowrap text-xs">{area.created_date}</td>
                <td className="px-4 py-4 whitespace-nowrap text-xs">{area.modified_date}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default CountryData;
