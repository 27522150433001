import React from 'react';
import { Link } from 'react-router-dom';
import { Button } from '@material-ui/core';
import dashboardImage from '../Assets/People.png';

const People = () => {
  return (
    <div className="bg-gray-100 items-center justify-center hidden md:block">
      <div className="container mx-auto flex flex-col md:flex-row items-center justify-between px-8 py-8 md:py-24">
        <div className="md:w-1/2 flex justify-center">
          <img src={dashboardImage} alt="People" className="w-full md:w-3/4 rounded-lg shadow-lg animate__animated animate__fadeIn hover:shadow-lg transition duration-300 ease-in-out transform hover:scale-105" />
        </div>
        <div className="md:w-1/2">
          <h1 className="text-4xl text-gray-600 font-bold mb-6 md:mb-8">Simplified 
          <span className="text-gradient bg-gradient-to-r from-blue-800 to-green-500 bg-clip-text text-transparent"> Returns Management</span> </h1>
          <p className="text-lg text-gray-700 mb-6 md:mb-8">Stay in control of your return shipments. Have them picked up timely & keep them ready nearby for your upcoming orders.</p>
          <Button variant="contained" color="primary" component={Link} to="/ContactForm">Talk to an Expert</Button>
        </div>
      </div>
    </div>
  );
};

export default People;
