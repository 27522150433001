import React, { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

function TeamVerificationPage() {
  const navigate = useNavigate();
  const { inviteToken } = useParams();
  console.log('inviteToken from TeamVerificationPage.js : ', inviteToken);
  //const [email, setEmail] = useState('');

  const showAlert = (msg) => {
    const alertContainer = document.createElement('div');
    alertContainer.className = 'fixed top-4 right-4 z-50';

    const alertBox = document.createElement('div');
    alertBox.className = 'bg-blue-500 text-white p-6 rounded shadow-md relative overflow-hidden';
    alertBox.style.animation = 'slideInRight 0.3s ease-in-out forwards'; // Use slide-in animation from the right

    const messageElement = document.createElement('div');
    messageElement.innerHTML = msg;

    alertBox.appendChild(messageElement);

    // Add loader animation
    const loader = document.createElement('div');
    loader.className = 'loader-line';
    loader.style.animation = 'loader 2.5s linear forwards'; // Set animation duration equal to fade-out time
    alertBox.appendChild(loader);

    alertContainer.appendChild(alertBox);

    document.body.appendChild(alertContainer);

    // Remove the alert after a certain duration
    setTimeout(() => {
        alertBox.style.animation = 'fadeOut 0.3s ease-in-out forwards';
        setTimeout(() => {
            alertContainer.remove();
        }, 500);
    }, 5000);
};



  const checkEmailExistence = async () => {
    try {
      const response = await fetch("http://localhost:5000/teamtoken", {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify({ inviteToken }) 
      });
      
      const data = await response.json();

      if (data.message) {
        showAlert(data.message);
        await new Promise(resolve => setTimeout(resolve, 3000));
        navigate('/signin');
      }
    } catch (error) {
      console.error('Error:', error);
      showAlert('Error verifying email. Please try again.');
    }
  };

  return (
    <div className="flex justify-center items-center h-screen bg-gray-100">
      <div className="max-w-md w-full bg-white shadow-lg rounded-lg p-6">
        <h2 className="text-2xl font-bold mb-4">Accept Invitation</h2>
        <p className="text-gray-700 mb-6">
        Click the button to join our team on Navdoot Logistics Saas platform. Click the button below to accept the invitation:
        </p>
        <button 
          className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline" 
          onClick={checkEmailExistence}
        >
          Verify Email
        </button>
      </div>
    </div>
  );
};

export default TeamVerificationPage;
