import React, { useState, useEffect } from 'react';
import ReactSelect from 'react-select'; 
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import 'tailwindcss/tailwind.css';
import { ToastContainer } from 'react-toastify';
import { toast } from 'react-toastify'; // Moved up
import 'react-toastify/dist/ReactToastify.css'; // Moved down

const Drs = () => {
  const [DrsName, setDrsName] = useState('');
  const [waybills, setWaybills] = useState([]);
  const [filteredWaybills, setFilteredWaybills] = useState([]);
  const [selectedWaybills, setSelectedWaybills] = useState([]);
  const [enteredWaybill, setEnteredWaybill] = useState('');
  const [waybillDetails, setWaybillDetails] = useState(null);
  const [deliveryboys, setDeliveryBoys] = useState([]);
  const [filteredDeliveryboys, setFilteredDeliveryboys] = useState([]);
  const [deliveryBoyId, setDeliveryBoyId] = useState('');  
  const [deliveryBoyName, setDeliveryBoyName] = useState(''); 
  const [branches, setBranches] = useState([]);
  const [filteredBranches, setFilteredBranches] = useState([]);
  const [parentBranchId, setParentBranchId] = useState('');  
  const [parentBranch, setParentBranch] = useState('');
  const [bookingDate, setBookingDate] = useState(null);
  const [key, setKey] = useState(0);
  const token = localStorage.getItem('token');
  const config=require('../../config')

  useEffect(() => {
    fetchDeliveryBoys();
    fetchWaybills();
    fetchBranches();    
    fetchDrs(); 
  }, []);

  const fetchDeliveryBoys = async () => {
    try {
      const response = await fetch(config.backendDomain+'/deliveryboy', {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const data = await response.json();
      setDeliveryBoys(data);
      setFilteredDeliveryboys(data);
    } catch (error) {
      console.error('Error fetching Deliveryboys:', error);
    }
  };

  const handleDeliveryBoySearch = (searchTerm) => {
    const filtered = deliveryboys.filter((deliveryboy) =>
      deliveryboy.deliveryboy_name.toLowerCase().startsWith(searchTerm.toLowerCase())
    );
    console.log('Filtered DeliveryBoy:', filtered);
    setFilteredDeliveryboys(filtered);
  };

  const fetchWaybills = async () => {
    try {
      const response = await fetch(config.backendDomain+'/bookingsearch', {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const data = await response.json();
      setWaybills(data);
      setFilteredWaybills(data);
    } catch (error) {
      console.error('Error fetching Waybills:', error);
    }
  };

  const handleWaybillSearch = (searchTerm) => {
    const filtered = waybills.filter((waybill) =>
      waybill.waybill_number.toLowerCase().startsWith(searchTerm.toLowerCase())
    );
    console.log('Filtered waybill:', filtered);
    setFilteredWaybills(filtered);
  };

  const fetchBranches = async () => {
    try {
      const response = await fetch(config.backendDomain+'/parentbranch', {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const data = await response.json();
      setBranches(data);
      setFilteredBranches(data);
    } catch (error) {
      console.error('Error fetching branches:', error);
    }
  };

  const fetchDrs = async () => {
    try {
      const response = await fetch(config.backendDomain+'/drs', {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const data = await response.json();
      const sortedData = data.sort((a, b) => new Date(b.created_date) - new Date(a.created_date));
  
      // Generate the next drs name
      if (sortedData.length > 0) {
        const lastDrs = sortedData[0];
        const lastDrsNumber = parseInt(lastDrs.drs_name.replace('DR', ''), 10);
        const nextDrsNumber = lastDrsNumber + 1;
        const nextDrsName = `DR${nextDrsNumber}`;
        setDrsName(nextDrsName);
      } else {
        setDrsName('DR1');
      }
    } catch (error) {
      console.error('Error fetching Drs:', error);
    }
  };
  

  const handleWaybillChange = (selectedOption) => {
    const selectedWaybill = waybills.find((waybill) => waybill.waybill_id === selectedOption.value);
    if (selectedWaybill) {
      setEnteredWaybill(selectedOption.label);
      setWaybillDetails(selectedWaybill);
    }
  };

  const addWaybill = () => {
    if (waybillDetails) {
      // Check if the waybill is already added
      if (selectedWaybills.some(waybill => waybill.waybill_id === waybillDetails.waybill_id)) {
        showAlert("This waybill is already added.");
      } else {
        setSelectedWaybills((prevWaybills) => [...prevWaybills, waybillDetails]);
        setEnteredWaybill('');
        setWaybillDetails(null);
      }
    }
  };
  

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await fetch(config.backendDomain+'/drs', {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({DrsName, bookingDate, parentBranchId, parentBranch, deliveryBoyId, deliveryBoyName, waybills: selectedWaybills}), 
      });
      const data = await response.json();

      if (response.ok) {
        toast.success(data.message);
        setDrsName('');
        setSelectedWaybills([]);
        setParentBranchId('');
        setParentBranch('');
        setBookingDate('');
        setDeliveryBoyId('');
        setDeliveryBoyName('');      
        fetchDrs();                       
        // setKey((prevKey) => prevKey + 2);
        
      } else {
        handleErrorResponse(response.status, data.message);
      }
    } catch (error) {
      console.error('Error creating Drs:', error);
      handleErrorResponse(500, 'An error occurred.');
    }
  };

  const handleErrorResponse = (status, message) => {
    if (status === 403) {
      showAlert('You are not authorized.');
    } else if (status === 400) {
      showAlert(message || 'Waybill with the same name already exists');
    } else {
      showAlert('An error occurred.');
    }
  };

  const showAlert = (msg) => {
    toast.info(msg);
  };
  

  const handleRemoveWaybill = (waybillIdToRemove) => {
    setSelectedWaybills(selectedWaybills.filter(waybill => waybill.waybill_id !== waybillIdToRemove));
  };

  const handleEditWaybill = (waybillIdToEdit) => {
    const waybillToEdit = selectedWaybills.find(waybill => waybill.waybill_id === waybillIdToEdit);
    if (waybillToEdit) {
      setEnteredWaybill(waybillToEdit.waybill_number);
      setWaybillDetails(waybillToEdit);
      handleRemoveWaybill(waybillIdToEdit);
    }
  };

  return (
    <div key={key} className="relative bg-gray-100 animate__animated animate__fadeIn">
       <ToastContainer className="mt-14" /> 
      <div className="container mx-auto py-8 mt-0">
        <div className="relative bg-blue-50">
          <h2 className="shadow-lg text-xl h-10 font-bold bg-blue-500 text-white mb-6 pl-3 pt-1">Deliver Run Sheet</h2>
          <form onSubmit={handleSubmit} className="grid grid-cols-3 gap-4">

          <div className="mb-2">
              <input
                type="text"
                id="DrsName"
                className="shadow-lg w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                placeholder="Enter Drs Number"
                value={DrsName}
                onChange={(e) => setDrsName(e.target.value)}
                readOnly
                required
              />
            </div>

            <div className="mb-2">
              <DatePicker
                selected={bookingDate}
                onChange={(date) => setBookingDate(date)}
                dateFormat="MM/dd/yyyy"
                placeholderText="Select Drs Date"
                className="shadow-lg w-[404px]  py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                required
              />
            </div>            

            <div className="col-span-1 mb-4">
              <ReactSelect
                id="parentBranchId"
                options={filteredBranches.map((branch) => ({
                  value: branch.branch_id,
                  label: branch.branch_name,
                }))}
                value={filteredBranches.find((branch) => branch.branch_name === parentBranchId)}
                onChange={(selectedOption) => {
                  const selectedBranch = branches.find((branch) => branch.branch_id === selectedOption.value);
                  if (selectedBranch) {
                    setParentBranchId(selectedOption.value);
                    setParentBranch(selectedOption.label);
                  }
                }}
                placeholder="Select Branch"
              />
            </div>

            <div className="mb-2">
              <ReactSelect
                id="deliveryboyId"
                options={filteredDeliveryboys.map((deliveryboy) => ({
                  value: deliveryboy.deliveryboy_id,
                  label: deliveryboy.deliveryboy_name,
                }))}
                value={filteredDeliveryboys.find((deliveryboy) => deliveryboy.deliveryboy_name === deliveryBoyId)}
                onChange={(selectedOption) => {
                  const selectedDeliveryBoy = deliveryboys.find((deliveryboy) => deliveryboy.deliveryboy_id === selectedOption.value);
                  if (selectedDeliveryBoy) {
                    setDeliveryBoyId(selectedOption.value);
                    setDeliveryBoyName(selectedOption.label);
                  }
                }}
                placeholder="Select Delivery Boy"
              />
            </div> 

            <div className="mb-2">
              <ReactSelect
                id="waybillId"
                options={filteredWaybills.map((waybill) => ({
                  value: waybill.waybill_id,
                  label: waybill.waybill_number,
                }))}
                value={filteredWaybills.find((waybill) => waybill.waybill_number === enteredWaybill)}
                onChange={handleWaybillChange}
                placeholder="Select WayBills"
              />
            </div> 

            <div className="mb-2">
              <button
                type="button"
                className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 h-10 pt-1.5 w-40 ml-2 rounded focus:outline-none focus:shadow-outline"
                onClick={addWaybill}
              >
                Add Waybill
              </button>
            <button
              className="bg-blue-500 ml-2 hover:bg-blue-700 focus:bg-blue-700 text-white font-bold py-2 px-4 h-10 pt-1.5 w-40 rounded focus:outline-none focus:shadow-outline"
              type="submit"
            >
              Create Drs
            </button>
            </div>
          </form>

          {/* Display selected waybills in a table */}
          <div className="mt-4">
            <h6 className="shadow-lg text-xs h-8 font-bold bg-blue-100 text-gray-400 mb-6 pl-3 pt-2">SELECTED WAYBILLS:</h6>
            <table className="min-w-full divide-y divide-gray-200 mt-6">
            <thead className="bg-gray-200">
                <tr className="bg-gray-200 text-gray-600 uppercase text-sm leading-normal">
                  <th className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Waybill Number</th>
                  <th className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Booking Date</th>
                  <th className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Consignor</th>
                  <th className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Packets</th>
                  <th className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Act.Weight</th>
                  <th className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Cha.Weight</th>
                  <th className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Destination</th>
                  <th className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Actions</th>
                </tr>
              </thead>
              <tbody className="text-gray-600 text-sm font-light">
                {selectedWaybills.map((waybill) => (
                  <tr key={waybill.waybill_id} className="border-b border-gray-200 hover:bg-gray-100">
                    <td className="px-4 py-4 whitespace-nowrap text-xs">{waybill.waybill_number}</td>
                    <td className="px-4 py-4 whitespace-nowrap text-xs">{waybill.booking_date}</td>
                    <td className="px-4 py-4 whitespace-nowrap text-xs">{waybill.consignor_name}</td>
                    <td className="px-4 py-4 whitespace-nowrap text-xs">{waybill.packets}</td>
                    <td className="px-4 py-4 whitespace-nowrap text-xs">{waybill.actual_weight}</td>
                    <td className="px-4 py-4 whitespace-nowrap text-xs">{waybill.volumetric_weight}</td>
                    <td className="px-4 py-4 whitespace-nowrap text-xs">{waybill.destcity_name}</td>
                    <td className="px-4 py-4 whitespace-nowrap text-xs">
                      <button
                        className="bg-yellow-500 hover:bg-yellow-700 text-white font-bold py-1 px-2 rounded mr-2"
                        onClick={() => handleEditWaybill(waybill.waybill_id)}
                      >
                        Edit
                      </button>
                      <button
                        className="bg-red-500 hover:bg-red-700 text-white font-bold py-1 px-2 rounded"
                        onClick={() => handleRemoveWaybill(waybill.waybill_id)}
                      >
                        Delete
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Drs;
