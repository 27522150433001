import React, { useEffect, useRef } from 'react';
import Chart from 'chart.js/auto'; // Import Chart.js

const SalesDashboard = () => {
  // Sample data for the charts
  const lineChartData = {
    labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July'],
    datasets: [
      {
        label: 'Sales Trend',
        data: [100, 50, 200, 200, 300, 350, 400],
        fill: false,
        borderColor: 'rgb(75, 192, 192)',
        tension: 0.1,
      },
    ],
  };

  const barChartData = {
    labels: ['Express', 'Train', 'Air', 'Surface', 'International'],
    datasets: [
      {
        label: 'Product Sales',
        data: [500, 700, 300, 400, 600],
        backgroundColor: ['rgb(255, 99, 132)', 'rgb(54, 162, 235)', 'rgb(255, 205, 86)', 'rgb(75, 192, 192)', 'rgb(153, 102, 255)'],
        borderWidth: 1,
      },
    ],
  };

   // Sample data for sales analytical reports
  const salesReports = [
    { id: 1, region: 'North', sales: 1200, profit: 500 },
    { id: 2, region: 'South', sales: 1500, profit: 700 },
    { id: 3, region: 'East', sales: 1000, profit: 300 },
    { id: 4, region: 'West', sales: 1800, profit: 800 },
  ];


  // Sample data for recent orders and sales analytics
  const recentOrders = [
    { id: 1, customer: 'John Doe', product: 'Product A', amount: 120, date: '2022-04-20' },
    { id: 2, customer: 'Jane Smith', product: 'Product B', amount: 180, date: '2022-04-21' },
    { id: 3, customer: 'Alice Johnson', product: 'Product C', amount: 150, date: '2022-04-22' },
    { id: 4, customer: 'Bob Williams', product: 'Product D', amount: 200, date: '2022-04-23' },
    { id: 5, customer: 'Eve Brown', product: 'Product E', amount: 250, date: '2022-04-24' },
  ];

  // Refs to store chart instances
  const lineChartRef = useRef(null);
  const barChartRef = useRef(null);

  useEffect(() => {
    if (lineChartRef.current) {
      // Destroy the previous chart instance if it exists
      lineChartRef.current.destroy();
    }
    if (barChartRef.current) {
      // Destroy the previous chart instance if it exists
      barChartRef.current.destroy();
    }

    // Initialize new chart instances
    const lineCtx = document.getElementById('lineChart').getContext('2d');
    const barCtx = document.getElementById('barChart').getContext('2d');

    lineChartRef.current = new Chart(lineCtx, {
      type: 'line',
      data: lineChartData,
    });

    barChartRef.current = new Chart(barCtx, {
      type: 'bar',
      data: barChartData,
      options: {
        scales: {
          y: {
            beginAtZero: true,
          },
        },
      },
    });
  }, [lineChartData, barChartData]);

  return (
    <div className="container mx-auto px-4 py-8">
      {/* Four cards with professional background */}
      <div className="grid grid-cols-1 md:grid-cols-4 gap-4 mb-6">
        <div className="bg-blue-100 p-4 rounded-lg shadow-md">
          <h3 className="text-lg font-semibold text-blue-700">Total Sales</h3>
          <p className="text-3xl font-bold text-blue-900">$4500</p>
        </div>
        <div className="bg-green-100 p-4 rounded-lg shadow-md">
          <h3 className="text-lg font-semibold text-green-700">Today's Sales</h3>
          <p className="text-3xl font-bold text-green-900">$1200</p>
        </div>
        <div className="bg-purple-100 p-4 rounded-lg shadow-md">
          <h3 className="text-lg font-semibold text-purple-700">Total Booking</h3>
          <p className="text-3xl font-bold text-purple-900">25</p>
        </div>
        <div className="bg-red-100 p-4 rounded-lg shadow-md">
          <h3 className="text-lg font-semibold text-red-700">Today's Booking</h3>
          <p className="text-3xl font-bold text-red-900">10</p>
        </div>
      </div>

      {/* Sales Charts */}
      <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
        {/* Line Chart */}
        <div className="bg-white p-6 rounded-lg shadow-md">
          <h2 className="text-xl font-semibold mb-4">Sales Trend</h2>
          <canvas id="lineChart" width="400" height="200"></canvas>
        </div>

        {/* Bar Chart */}
        <div className="bg-white p-6 rounded-lg shadow-md">
          <h2 className="text-xl font-semibold mb-4">Service Trend</h2>
          <canvas id="barChart" width="400" height="200"></canvas>
        </div>
      </div>


      {/* Recent Orders Table */}
      <div className="bg-white rounded-lg shadow-md overflow-hidden mb-8">
        <h2 className="text-xl font-semibold bg-gray-200 py-2 px-4">Recent Bookings</h2>
        <table className="w-full">
          <thead>
            <tr>
              <th className="px-4 py-2">AWB No</th>
              <th className="px-4 py-2">Customer</th>
              <th className="px-4 py-2">Product</th>
              <th className="px-4 py-2">Amount</th>
              <th className="px-4 py-2">Date</th>
            </tr>
          </thead>
          <tbody>
            {recentOrders.map(order => (
              <tr key={order.id}>
                <td className="border px-4 py-2">{order.id}</td>
                <td className="border px-4 py-2">{order.customer}</td>
                <td className="border px-4 py-2">{order.product}</td>
                <td className="border px-4 py-2">{order.amount}</td>
                <td className="border px-4 py-2">{order.date}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      {/* Sales Analytical Reports Table */}
      <div className="bg-white rounded-lg shadow-md overflow-hidden mb-8">
        <h2 className="text-xl font-semibold bg-gray-200 py-2 px-4">Sales Analytical Reports</h2>
        <table className="w-full">
          <thead>
            <tr>
              <th className="px-4 py-2">Region</th>
              <th className="px-4 py-2">Sales</th>
              <th className="px-4 py-2">Profit</th>
            </tr>
          </thead>
          <tbody>
            {salesReports.map(report => (
              <tr key={report.id}>
                <td className="border px-4 py-2">{report.region}</td>
                <td className="border px-4 py-2">{report.sales}</td>
                <td className="border px-4 py-2">{report.profit}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      
    </div>
  );
};

export default SalesDashboard;
