import React from "react";
import { Link } from "react-router-dom";

function WarehouseManagement() {
  return (
    <div className="bg-gray-50 min-h-screen">
      {/* Navigation */}
      <nav className="sticky top-0 bg-white shadow-lg z-50 border-b-2 border-gray-200">
        <div className="container mx-auto flex items-center justify-between px-6 py-4">
          <h1 className="text-2xl font-bold text-blue-800">
            <Link to="/">
              <img src="logo.png" alt="Logo.png" className="h-16 ml-8 " />
            </Link>
          </h1>
          <div className="space-x-4">
            <a href="#overview" className="text-gray-700 hover:text-blue-600">
              Overview
            </a>
            <a href="#features" className="text-gray-700 hover:text-blue-600">
              Features
            </a>
            <a href="#benefits" className="text-gray-700 hover:text-blue-600">
              Benefits
            </a>
            <a href="#pricing" className="text-gray-700 hover:text-blue-600">
              Pricing
            </a>
          </div>
        </div>
      </nav>

      {/* Hero Section */}
      <header className="relative bg-gradient-to-r from-blue-800 to-green-300 h-96 text-white">
        <div className="absolute inset-0 flex flex-col justify-center items-center text-center">
          <h1 className="text-5xl font-extrabold animate__animated animate__fadeInDown">
            Optimize Your Warehouse with NavDoot Warehouse Management
          </h1>
          <p className="mt-4 text-lg">
            Automate inventory management, streamline operations, and improve storage efficiency with our platform.
          </p>
          <button className="mt-6 bg-orange-500 hover:bg-blue-600 text-white font-bold py-3 px-6 rounded-lg transition-transform transform hover:scale-105">
            Get Started Now
          </button>
        </div>
      </header>

      {/* Content Section */}
      <div className="container mx-auto px-6 py-12 space-y-16">
        {/* Overview */}
        <section id="overview" className="text-center">
          <h2 className="text-3xl font-bold text-blue-800">Overview</h2>
          <p className="mt-4 text-gray-600">
            NavDoot Warehouse Management platform helps you optimize storage, track inventory in real-time, and automate your warehouse operations.
          </p>
        </section>

        {/* Features */}
        <section id="features" className="space-y-8">
          <h2 className="text-3xl font-bold text-blue-800 text-center">Features</h2>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
            <div className="bg-white shadow-lg rounded-lg p-6 hover:shadow-2xl transition duration-300">
              <h3 className="text-xl font-semibold text-blue-600">Inventory Management</h3>
              <p className="mt-2 text-gray-600">
                Track and manage your inventory in real-time, ensuring no stockouts or overstocking.
              </p>
            </div>
            <div className="bg-white shadow-lg rounded-lg p-6 hover:shadow-2xl transition duration-300">
              <h3 className="text-xl font-semibold text-orange-600">Warehouse Automation</h3>
              <p className="mt-2 text-gray-600">
                Automate order picking, sorting, and packaging to reduce human errors and increase efficiency.
              </p>
            </div>
            <div className="bg-white shadow-lg rounded-lg p-6 hover:shadow-2xl transition duration-300">
              <h3 className="text-xl font-semibold text-blue-600">Order Fulfillment</h3>
              <p className="mt-2 text-gray-600">
                Seamlessly process orders and ensure quick and accurate deliveries.
              </p>
            </div>
          </div>
        </section>

        {/* Benefits */}
        <section id="benefits" className="text-center">
          <h2 className="text-3xl font-bold text-blue-800">Benefits</h2>
          <p className="mt-4 text-gray-600">
            Improve operational efficiency, reduce errors, and optimize space with our Warehouse Management solution.
          </p>
          <ul className="mt-6 space-y-4">
            <li className="text-lg text-orange-600">
              <strong>Increase Efficiency:</strong> Automate tasks and reduce manual labor.
            </li>
            <li className="text-lg text-blue-600">
              <strong>Improve Accuracy:</strong> Real-time tracking minimizes errors and improves fulfillment.
            </li>
            <li className="text-lg text-orange-600">
              <strong>Optimize Space:</strong> Maximize storage capacity with intelligent organization.
            </li>
          </ul>
        </section>

        {/* Pricing */}
        <section id="pricing" className="text-center">
          <h2 className="text-3xl font-bold text-blue-800">Pricing</h2>
          <p className="mt-4 text-gray-600">
            Select the plan that best fits your warehouse needs and scale effortlessly as your business grows.
          </p>
          <div className="flex flex-wrap justify-center gap-8 mt-8">
            <div className="bg-white shadow-lg rounded-lg p-6 w-80 hover:shadow-2xl transition duration-300">
              <h3 className="text-xl font-semibold text-blue-600">Basic</h3>
              <p className="mt-2 text-gray-600">$19/month</p>
              <ul className="mt-4 space-y-2 text-gray-600">
                <li>5 Warehouses</li>
                <li>Basic Features</li>
                <li>Email Support</li>
              </ul>
              <button className="mt-6 bg-blue-600 text-white font-bold py-2 px-4 rounded hover:bg-orange-500">
                Select Plan
              </button>
            </div>
            <div className="bg-blue-800 text-white shadow-lg rounded-lg p-6 w-80 hover:shadow-2xl transition duration-300">
              <h3 className="text-xl font-semibold">Pro</h3>
              <p className="mt-2">$49/month</p>
              <ul className="mt-4 space-y-2">
                <li>50 Warehouses</li>
                <li>Advanced Features</li>
                <li>Priority Support</li>
              </ul>
              <button className="mt-6 bg-orange-500 text-white font-bold py-2 px-4 rounded hover:bg-blue-600">
                Select Plan
              </button>
            </div>
          </div>
        </section>
      </div>

      {/* Footer */}
      <footer className="bg-gradient-to-r from-blue-800 to-green-300 text-white py-6 text-center">
        &copy; 2024 NavDoot Warehouse Management. All rights reserved.
      </footer>
    </div>
  );
}

export default WarehouseManagement;
