import React, { useState, useEffect } from 'react';
import ReactSelect from 'react-select'; // Import ReactSelect
import { ToastContainer } from 'react-toastify';
import { toast } from 'react-toastify'; // Moved up
import 'react-toastify/dist/ReactToastify.css'; // Moved down

const Tarrif = () => {
  const [customerId, setcustomerId] = useState('');
  const [customer, setcustomer] = useState([]);
  const [filteredcustomer, setFilteredcustomer] = useState([]);
  const [customerName, setCustomerName] = useState('');
  const [selectedOriginTypeOption, setSelectedOriginTypeOption] = useState(null);
  const [selectedOriginOption, setSelectedOriginOption] = useState(null);
  const [selectedDestinationTypeOption, setSelectedDestinationTypeOption] = useState(null);
  const [selectedDestinationOption, setSelectedDestinationOption] = useState(null);
  const [postalcodeId, setpostalcodeId] = useState('');
  const [postalcodes, setpostalCodes] = useState([]);
  const [filteredpostalcodes, setFilteredpostalcodes] = useState([]);
  const [postalcodeName, setPostalcodeName] = useState('');
  const [cityId, setcityId] = useState('');
  const [cities, setcities] = useState([]);
  const [filteredcities, setFilteredcities] = useState([]);
  const [cityName, setCityName] = useState('');  
  const [stateId, setstateId] = useState('');
  const [states, setstates] = useState([]);
  const [filteredstates, setFilteredstates] = useState([]);
  const [stateName, setStateName] = useState('');
  const [regionId, setregionId] = useState('');
  const [regions, setregions] = useState([]);
  const [filteredregions, setFilteredregions] = useState([]);
  const [regionName, setRegionName] = useState('');
  const [zoneId, setzoneId] = useState('');
  const [zones, setzones] = useState([]);
  const [filteredzones, setFilteredzones] = useState([]);
  const [zoneName, setZoneName] = useState('');
  const [countryId, setcountryId] = useState('');
  const [countrys, setcountrys] = useState([]);
  const [filteredcountrys, setFilteredcountrys] = useState([]);
  const [countryName, setCountryName] = useState('');
  const [fromWeight, setFromWeight] = useState('');  
  const [toWeight, setToWeight] = useState('');  
  const [Rate, setRate] = useState('');  
  const [Oda, setOda] = useState('');  
  const token = localStorage.getItem('token');
  useEffect(() => {
    fetchcustomer();
    fetchpostalcodes();
    fetchcities();
    fetchstates();
    fetchregions();
    fetchzones();
    fetchcountrys();
  }, []);

  const fetchcustomer = async () => {
    try {
      const response = await fetch('http://localhost:5000/customer', {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const data = await response.json();
      setcustomer(data);
      setFilteredcustomer(data);      
    } catch (error) { 
      console.error('Error fetching Customer:', error);
    }
  };

  const handlecustomerSearch = (searchTerm) => {
    const filtered = cities.filter((customer) =>
      customer.customer_name.toLowerCase().startsWith(searchTerm.toLowerCase())
    );
    setFilteredcustomer(filtered);
  };

  const fetchpostalcodes = async () => {
    try {
      const response = await fetch('http://localhost:5000/postalcode', {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const data = await response.json();
      setpostalCodes(data);
      setFilteredpostalcodes(data);      
    } catch (error) { 
      console.error('Error fetching postalcodes:', error);
    }
  };

  const handlepostalcodeSearch = (searchTerm) => {
    const filtered = postalcodes.filter((postalcode) =>
      postalcode.postalcode_name.toLowerCase().startsWith(searchTerm.toLowerCase())
    );
    setFilteredpostalcodes(filtered);
  };

  const fetchcities = async () => {
    try {
      const response = await fetch('http://localhost:5000/city', {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const data = await response.json();
      setcities(data);
      setFilteredcities(data);      
    } catch (error) { 
      console.error('Error fetching City:', error);
    }
  };

  const handlecitySearch = (searchTerm) => {
    const filtered = cities.filter((city) =>
      city.city_name.toLowerCase().startsWith(searchTerm.toLowerCase())
    );
    setFilteredcities(filtered);
  };

  const fetchstates = async () => {
    try {
      const response = await fetch('http://localhost:5000/state', {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const data = await response.json();
      setstates(data);
      setFilteredstates(data);      
    } catch (error) { 
      console.error('Error fetching State:', error);
    }
  };

  const handlestateSearch = (searchTerm) => {
    const filtered = states.filter((state) =>
      state.state_name.toLowerCase().startsWith(searchTerm.toLowerCase())
    );
    setFilteredstates(filtered);
  };

  const fetchregions = async () => {
    try {
      const response = await fetch('http://localhost:5000/region', {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const data = await response.json();
      setregions(data);
      setFilteredregions(data);      
    } catch (error) { 
      console.error('Error fetching Region:', error);
    }
  };

  const handleregionSearch = (searchTerm) => {
    const filtered = cities.filter((region) =>
      region.region_name.toLowerCase().startsWith(searchTerm.toLowerCase())
    );
    setFilteredregions(filtered);
  };

  const fetchzones = async () => {
    try {
      const response = await fetch('http://localhost:5000/zone', {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const data = await response.json();
      setzones(data);
      setFilteredzones(data);      
    } catch (error) { 
      console.error('Error fetching Zone:', error);
    }
  };

  const handlezoneSearch = (searchTerm) => {
    const filtered = cities.filter((zone) =>
      zone.zone_name.toLowerCase().startsWith(searchTerm.toLowerCase())
    );
    setFilteredcities(filtered);
  };

  const fetchcountrys = async () => {
    try {
      const response = await fetch('http://localhost:5000/country', {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const data = await response.json();
      setcountrys(data);
      setFilteredcountrys(data);      
    } catch (error) { 
      console.error('Error fetching City:', error);
    }
  };

  const handlecountrySearch = (searchTerm) => {
    const filtered = countrys.filter((country) =>
      country.country_name.toLowerCase().startsWith(searchTerm.toLowerCase())
    );
    setFilteredcountrys(filtered);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch('http://localhost:5000/tarrifs', {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ customerId, customerName, selectedOriginTypeOption, selectedOriginOption,
          selectedDestinationTypeOption, selectedDestinationOption, fromWeight, toWeight, Rate, Oda }), 
      });
      const data = await response.json();

      if (response.ok) {
        toast.success(data.message);
        setcustomerId('');
        setCustomerName('');
        setSelectedOriginTypeOption('');
        setSelectedOriginOption('');
        setSelectedOriginTypeOption('');
        setSelectedDestinationOption('');
        setFromWeight('');
        setToWeight('')
        setRate('');
        setOda('');
        fetchcustomer('');              
        fetchpostalcodes('');      
        fetchcities('');   
        fetchstates('');   
        fetchregions('');   
        fetchzones('');   
        fetchcountrys('')                       
      } else {
        handleErrorResponse(response.status, data.message);
      }
    } catch (error) {
      console.error('Error creating Tarrif:', error);
      handleErrorResponse(500, 'An error occurred.'); // Internal server error
    }
  };


  const handleErrorResponse = (status, message) => {
    if (status === 403) {
      showAlert('You are not authorized.');
    } else if (status === 400) {
      showAlert(message || 'Tarrif with the same name already exists');
    } else {
      showAlert('An error occurred.');
    }
  };

  const showAlert = (msg) => {
    toast.info(msg);
  };

  const getOptionsByType = (type) => {
    switch (type) {
      case 'PostalCode':
        return filteredpostalcodes.map((postalcode) => ({
          value: postalcode.postalcode_id,
          label: postalcode.postalcode_name,
        }));
      case 'City':
        return filteredcities.map((city) => ({
          value: city.city_id,
          label: city.city_name,
        }));
      case 'State':
        return filteredstates.map((state) => ({
          value: state.state_id,
          label: state.state_name,
        }));
      case 'Region':
        return filteredregions.map((region) => ({
          value: region.region_id,
          label: region.region_name,
        }));
      case 'Zone':
        return filteredzones.map((zone) => ({
          value: zone.zone_id,
          label: zone.zone_name,
        }));
      case 'Country':
        return filteredcountrys.map((country) => ({
          value: country.country_id,
          label: country.country_name,
        }));
      default:
        return [];
    }
  };


  return (
    <div className="relative bg-blue-50 animate__animated animate__fadeIn">
       <ToastContainer className="mt-14" /> 
      <div className="relative bg-blue-50">
          <h2 className="shadow-lg text-xl h-10 font-bold bg-blue-500 text-white mb-6 pl-3 pt-1">Price Tarrif Gateway</h2></div>
          <form onSubmit={handleSubmit} className="grid grid-cols-6 gap-2">
          <div className="col-span-5 mb-2 w-[1247px]">
            <ReactSelect
                id="customerId"
                options={filteredcustomer.map((customer) => ({
                  value: customer.customer_id,
                  label: customer.customer_name,
                }))}
                
                value={filteredcustomer.find((customer) => customer.customer_name === customerId)}
                onChange={(selectedOption) => {
                  const selectedcustomer = customer.find((customer) => customer.customer_id === selectedOption.value);
                  if (selectedcustomer) {
                    setcustomerId(selectedOption.value);
                    setCustomerName(selectedOption.label);
                  }
                }}
                placeholder="Select Customer / Clients"
              />
              </div>

          <div className="col-span-6 mb-2">
          <ReactSelect
            id="originType"
            className=" shadow-lg text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            options={[
              { value: 'PostalCode', label: 'PostalCode' },
              { value: 'City', label: 'City' },
              { value: 'State', label: 'State' },
              { value: 'Region', label: 'Region' },
              { value: 'Zone', label: 'Zone' },
              { value: 'Country', label: 'Country' },
            ]}
            value={selectedOriginTypeOption}
            onChange={(option) => setSelectedOriginTypeOption(option)}
            placeholder="Select Origin Type"
          />
        </div>

        <div className="col-span-6 mb-2">
          <ReactSelect
            id="dynamicSelect"
            className=" shadow-lg text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            options={getOptionsByType(selectedOriginTypeOption?.value)}
            value={
              selectedOriginOption?.value === 'PostalCode'
                ? filteredpostalcodes.find((postalcode) => postalcode.postalcode_id === postalcodeId)
                : selectedOriginOption?.value === 'City'
                ? filteredcities.find((city) => city.city_id === cityId)
                : selectedOriginOption?.value === 'State'
                ? filteredstates.find((state) => state.state_id === stateId)
                : selectedOriginOption?.value === 'Region'
                ? filteredregions.find((region) => region.region_id === regionId)
                : selectedOriginOption?.value === 'Zone'
                ? filteredzones.find((zone) => zone.zone_id === zoneId)
                : selectedOriginOption?.value === 'Country'
                ? filteredcountrys.find((country) => country.country_id === countryId)
                : null
            }
            onChange={(selectedOriginOption) => {
              switch (selectedOriginOption.value) {
                case 'PostalCode':
                  setpostalcodeId(selectedOriginOption.value);
                  setPostalcodeName(selectedOriginOption.label);
                  break;
                case 'City':
                  setcityId(selectedOriginOption.value);
                  setCityName(selectedOriginOption.label);
                  break;
                case 'State':
                  setstateId(selectedOriginOption.value);
                  setStateName(selectedOriginOption.label);
                  break;
                case 'Region':
                  setregionId(selectedOriginOption.value);
                  setRegionName(selectedOriginOption.label);
                  break;
                case 'Zone':
                  setzoneId(selectedOriginOption.value);
                  setZoneName(selectedOriginOption.label);
                  break;
                case 'Country':
                  setcountryId(selectedOriginOption.value);
                  setCountryName(selectedOriginOption.label);
                  break;
                default:
                  break;
              }
            }}
            placeholder="Select Origin"
          />
        </div>       

        <div className="col-span-6 mb-2">
          <ReactSelect
            id="destinationType"
            className=" shadow-lg text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            options={[
              { value: 'PostalCode', label: 'PostalCode' },
              { value: 'City', label: 'City' },
              { value: 'State', label: 'State' },
              { value: 'Region', label: 'Region' },
              { value: 'Zone', label: 'Zone' },
              { value: 'Country', label: 'Country' },
            ]}
            value={selectedDestinationTypeOption}
            onChange={(option) => setSelectedDestinationTypeOption(option)}
            placeholder="Select Destination Type"
          />
        </div>

        <div className="col-span-6 mb-2">
          <ReactSelect
            id="dynamicSelect"
            className=" shadow-lg text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            options={getOptionsByType(selectedDestinationTypeOption?.value)}
            value={
              selectedDestinationOption?.value === 'PostalCode'
                ? filteredpostalcodes.find((postalcode) => postalcode.postalcode_id === postalcodeId)
                : selectedDestinationOption?.value === 'City'
                ? filteredcities.find((city) => city.city_id === cityId)
                : selectedDestinationOption?.value === 'State'
                ? filteredstates.find((state) => state.state_id === stateId)
                : selectedDestinationOption?.value === 'Region'
                ? filteredregions.find((region) => region.region_id === regionId)
                : selectedDestinationOption?.value === 'Zone'
                ? filteredzones.find((zone) => zone.zone_id === zoneId)
                : selectedDestinationOption?.value === 'Country'
                ? filteredcountrys.find((country) => country.country_id === countryId)
                : null
            }
            onChange={(selectedDestinationOption) => {
              switch (selectedDestinationOption.value) {
                case 'PostalCode':
                  setpostalcodeId(selectedDestinationOption.value);
                  setPostalcodeName(selectedDestinationOption.label);
                  break;
                case 'City':
                  setcityId(selectedDestinationOption.value);
                  setCityName(selectedDestinationOption.label);
                  break;
                case 'State':
                  setstateId(selectedDestinationOption.value);
                  setStateName(selectedDestinationOption.label);
                  break;
                case 'Region':
                  setregionId(selectedDestinationOption.value);
                  setRegionName(selectedDestinationOption.label);
                  break;
                case 'Zone':
                  setzoneId(selectedDestinationOption.value);
                  setZoneName(selectedDestinationOption.label);
                  break;
                case 'Country':
                  setcountryId(selectedDestinationOption.value);
                  setCountryName(selectedDestinationOption.label);
                  break;
                default:
                  break;
              }
            }}
            placeholder="Select Destination"
          />
        </div>   

         <div className="">
            <input
              type="number"
              id="kg"
              pattern="[0-999]{1000}" 
              title="Please enter valid weight in numbers"
              className="shadow-lg py-2 px-3 bg-yellow-50 text-gray-800 leading-tight focus:outline-none focus:shadow-outline"
              placeholder="From Weight"
              value={fromWeight}
              onChange={(e) => setFromWeight(e.target.value)}
              required
            />
          </div> 

          <div className="mb-2 ml-2">
            <input
              type="number"
              id="kg"
              pattern="[0-999]{1000}" 
              title="Please enter valid weight in numbers"
              className="shadow-lg py-2 px-3 bg-yellow-50 text-gray-800 leading-tight focus:outline-none focus:shadow-outline"
              placeholder="To Weight"
              value={toWeight}
              onChange={(e) => setToWeight(e.target.value)}
              required
            />
          </div> 

          <div className="mb-2 ml-4">
            <input
              type="number"
              id="kg"
              pattern="[0-999]{1000}" 
              title="Please enter valid Rate in Rupees"
              className="shadow-lg py-2 px-3 bg-yellow-50 text-gray-800 leading-tight focus:outline-none focus:shadow-outline"
              placeholder="Rate Per Kg"
              value={Rate}
              onChange={(e) => setRate(e.target.value)}
              required
            />
          </div> 

          <div className="mb-2 ml-6">
            <input
              type="number"
              id="kg"
              pattern="[0-999]{1000}" 
              title="Please enter valid Rate in Rupees"
              className="shadow-lg py-2 px-3 bg-yellow-50 text-gray-800 leading-tight focus:outline-none focus:shadow-outline"
              placeholder="ODA Charges"
              value={Oda}
              onChange={(e) => setOda(e.target.value)}
              required
            />
          </div>
          <div className="col-span-2 mb-2 ml-8">
            <button
              className="bg-blue-500 ml-2 hover:bg-blue-700 focus:bg-blue-700 text-white font-bold py-2 px-4 h-10 pt-1.5 w-40 rounded focus:outline-none focus:shadow-outline"
              type="submit"
            >
              Submit
            </button>
            </div>
          </form>    
        </div>
  );
};

export default Tarrif;
