import { ReactComponent as FacebookIcon } from '../Assets/facebook.svg';
import { ReactComponent as InstagramIcon } from '../Assets/instagram.svg';
import { ReactComponent as TwitterIcon } from '../Assets/twitter.svg';
import { ReactComponent as YouTubeIcon } from '../Assets/youtube.svg';

const SocialMedia = () => {
  return (
    <div className="flex items-center space-x-4 ml-12 ">
      <FacebookIcon className="h-8 w-8 text-blue-600" />
      <YouTubeIcon className="h-8 w-8 text-red-600" />
      <TwitterIcon className="h-16 w-8 text-blue-400" />
      <InstagramIcon className="h-8 w-8 text-pink-600" />
      
    </div>
  );
};

export default SocialMedia;
