import { initializeApp } from "firebase/app";
import {getAuth, GoogleAuthProvider} from "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyCarbR3y9RzLekPPdOJ-7nxPjgX24esVdc",
  authDomain: "navdoot-3c3c1.firebaseapp.com",
  projectId: "navdoot-3c3c1",
  storageBucket: "navdoot-3c3c1.appspot.com",
  messagingSenderId: "23134204619",
  appId: "1:23134204619:web:2882eb2acce1dae9f0614e",
  measurementId: "G-JWZRMCET41"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const auth = getAuth (app)
const provider = new GoogleAuthProvider();
export {auth,provider}