import React, { useState } from "react";
import { Link } from 'react-router-dom';

function FAQ() {
  const faqs = [
    {
      question: "What is NavDoot?",
      answer: "NavDoot is a cloud-based SaaS platform designed to streamline supply chain management, offering real-time insights and seamless integrations.",
    },
    {
      question: "How does NavDoot help my business?",
      answer: "NavDoot helps businesses improve efficiency, reduce costs, and enhance visibility in supply chain operations through automated processes and data-driven insights.",
    },
    {
      question: "Is NavDoot easy to integrate?",
      answer: "Yes! NavDoot is designed to integrate seamlessly with existing systems, allowing for a smooth transition and minimal disruption to your operations.",
    },
    {
      question: "Does NavDoot offer real-time tracking?",
      answer: "Yes, NavDoot provides real-time tracking to ensure visibility of your shipments at every stage of the supply chain.",
    },
    {
      question: "What industries can benefit from NavDoot?",
      answer: "NavDoot caters to a wide range of industries, including retail, manufacturing, healthcare, and logistics providers.",
    },
    {
      question: "Is training required to use NavDoot?",
      answer: "NavDoot's user-friendly interface minimizes the need for extensive training. However, support and tutorials are available if needed.",
    },
    {
      question: "Can I customize NavDoot to my needs?",
      answer: "Absolutely! NavDoot offers customization options to suit your specific business requirements.",
    },
    {
      question: "What are the pricing options for NavDoot?",
      answer: "NavDoot offers flexible pricing plans based on the size of your business and the features you need.",
    },
    {
      question: "Is NavDoot secure?",
      answer: "Yes, NavDoot employs advanced security measures, including encryption and regular audits, to protect your data.",
    },
    {
      question: "Does NavDoot support mobile devices?",
      answer: "Yes, NavDoot is fully responsive and can be accessed on mobile devices through a web browser or a dedicated app.",
    },
    // Add 10 more FAQs as needed...
  ];

  const [openIndex, setOpenIndex] = useState(null);

  const toggleFAQ = (index) => {
    setOpenIndex(openIndex === index ? null : index);
  };

  return (
    <div className="container mx-auto p-8">
         <nav className="fixed top-0 left-0 right-0 bg-white border-b-2 border-gray-200 shadow-lg z-50 py-4 hidden md:block">
        <div className="container mx-auto flex justify-between items-center px-8">
        <Link to="/">
        <img src="logo.png" alt="Logo.png" className="h-16 ml-8 " />
        </Link>
          <div className="hidden md:flex space-x-4">
            <button className="bg-blue-600 hover:bg-red-600 text-white font-semibold py-2 px-4 rounded focus:outline-none">
              <Link to="/signin">Login</Link>
            </button>
            <button className="bg-green-600 hover:bg-red-600 text-white font-semibold py-2 px-4 rounded focus:outline-none">
              <Link to="/SignupPage">Get Started</Link>
            </button>
          </div>
        </div>
      </nav>
      <h1 className="text-4xl mt-24 font-bold text-center text-gray-800 mb-8">
        Frequently Asked Questions
      </h1>
      <div className="space-y-4">
        {faqs.map((faq, index) => (
          <div
            key={index}
            className="border border-gray-300 rounded-lg shadow-md transition-transform transform hover:scale-[1.02] duration-100"
          >
            <button
              className={`w-full text-left px-4 py-2 text-xl font-semibold focus:outline-none transition-colors duration-200 ${
                openIndex === index ? "bg-blue-100 text-blue-800" : "bg-white text-gray-800 hover:bg-blue-50"
              }`}
              onClick={() => toggleFAQ(index)}
            >
              {faq.question}
            </button>
            <div
              className={`overflow-hidden transition-max-height duration-300 ease-in-out ${
                openIndex === index ? "max-h-[500px]" : "max-h-0"
              }`}
            >
              <div className="px-4 py-2 text-gray-700 text-lg">
                {faq.answer}
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default FAQ;
